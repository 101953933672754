import {
    makeStore as makeStoreGeneric,
    interceptClient as interceptClientGeneric,
} from '@shared/store/auth';
import config from '@/config';

const AUTH_BASE_PATH = '/user';

const authAPI = {
    baseURL: config.apiUrl,
    basePath: AUTH_BASE_PATH,
    endpoints: {
        tokenRefresh: `${AUTH_BASE_PATH}/renew`,
        emailSubmit: `${AUTH_BASE_PATH}/login`,
        signout: `${AUTH_BASE_PATH}/logout`,
    },
};

export const makeStore = ({ client }) => {
    return makeStoreGeneric({ client, api: authAPI });
};

export const interceptClient = ({ client, store }) => {
    return interceptClientGeneric({ client, store, api: authAPI });
};
