<template>
    <div class="post-faq">
        <h2 class="post-faq__headline">
            Если остались вопросы
        </h2>
        <div class="post-faq__tiles">
            <div class="post-faq__tile">
                <tile-button
                    link
                    :to="`${oiLegalBaseURL}/instruction/help-yourself`"
                    :data-gtm="JSON.stringify({itemLocation: gtmLocation})"
                >
                    <template #icon>
                        <base-icon><icon-file /></base-icon>
                    </template>
                    Инструкция по защите в суде
                </tile-button>
            </div>
            <div class="post-faq__tile">
                <tile-button
                    link
                    to="https://t.me/OvdInfoBot"
                    :data-gtm="JSON.stringify({itemLocation: gtmLocation})"
                >
                    <template #icon>
                        <base-icon><icon-telegram /></base-icon>
                    </template>
                    Телеграм-бот
                </tile-button>
            </div>
            <div class="post-faq__tile">
                <tile-button
                    link
                    to="mailto:legal@ovdinfo.org"
                    :data-gtm="JSON.stringify({itemLocation: gtmLocation})"
                >
                    <template #icon>
                        <base-icon><icon-mail /></base-icon>
                    </template>
                    legal@ovdinfo.org
                </tile-button>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import config from '@/config';
import TileButton from '@/components/button/TileButton.vue';
import BaseIcon from '@/components/BaseIcon.vue';
import IconFile from '@/icons/IconFile.vue';
import IconTelegram from '@/icons/IconTelegram.vue';
import IconMail from '@/icons/IconMail.vue';

export default Vue.extend({
    name: 'PostFaq',
    components: {
        TileButton,
        BaseIcon,
        IconFile,
        IconTelegram,
        IconMail,
    },

    data() {
        return {
            oiLegalBaseURL: config.oiLegalBaseURL,
            gtmLocation: 'Если остались вопросы',
        };
    },
});
</script>

<style lang="scss">
@use "@/styles/variables/colors";
@use "@/styles/mixins/breakpoint" as *;

.post-faq__headline {
    margin-bottom: 24px;
    font-size: 24px;
    line-height: 1.35;
    letter-spacing: -0.03em;
}

.post-faq__tiles {
    display: flex;

    @include breakpoint(sm) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 8px;
    }
}

.post-faq__tile {
    &:not(:last-child) {
        margin-right: 16px;

        @include breakpoint(sm) {
            margin-right: 0;
        }
    }
}
</style>
