<template>
    <v-calendar-date-picker
        v-model="valueProxy"
        mode="date"
        v-bind="datePickerSettings"
        :max-date="maxDate"
        :min-date="minDate"
    >
        <template #default="{ inputValue, inputEvents, togglePopover }">
            <div class="date-time-picker__input-wrapper">
                <input
                    ref="inputNode"
                    class="
                        form-field__control date-time-picker__input
                        date-time-picker__input--date
                    "
                    type="text"
                    autocomplete="off"
                    inputmode="numeric"
                    :class="!isValid
                        ? `
                            form-field__control--invalid
                            date-time-picker__input--invalid
                        ` : ''
                    "
                    :value="isUserTyping ? rawInputValue : inputValue"
                    :placeholder="placeholder"
                    :disabled="disabled"
                    :readonly="readonly"
                    @input="onChange($event, inputEvents.input)"
                    @change="onChange($event, inputEvents.change)"
                />
                <button
                    type="button"
                    class="date-time-picker__toggle-popover"
                    @click="togglePopover"
                >
                    <img src="@/assets/calendar.svg" />
                </button>
            </div>
            <div
                v-if="!isValid"
                class="form-field__helper-wrapper"
            >
                <form-input-helper :is-error="true">
                    {{ errors[0] }}
                </form-input-helper>
            </div>
        </template>
    </v-calendar-date-picker>
</template>

<script lang="ts">
import Vue from 'vue';
import { DatePicker as VCalendarDatePicker } from 'v-calendar';
import FormInputHelper from '@/components/FormInputHelper.vue';
import { handleDateTime } from './helpers';
import { makeDateTimeUnitMixin } from './mixins';

/**
 * Проблема: с клавиатуры можно ввести дату, выходящую за диапазон `minDate`–`maxDate`,
 * в этом случае v-calendar не обновляет модель (соответственно, ее значение не попадает в стор),
 * а из-за использования `rawInputValue` этого не видно в интерфейсе
 * @todo Применять настоящую валидацию (по схеме) на допустимый диапазон дат.
 */
export default Vue.extend({
    components: {
        VCalendarDatePicker,
        FormInputHelper,
    },

    model: { event: 'change' },

    mixins: [
        makeDateTimeUnitMixin({
            inputMaskSettings: {
                date: true,
                datePattern: ['d', 'm', 'Y'],
                delimiter: '.',
            },
        }),
    ],

    props: {
        maxDate: {
            type: Date,
            required: true,
        },
        minDate: {
            type: Date,
            required: true,
        },
    },

    computed: {
        valueProxy: {
            get() {
                /*
                пока дата не задана явно (пользователем или родительским компонентом),
                возможное имеющееся значение модели не должно отображаться в интерфейсе
                */
                return this.isValueSet ? this.value : null;
            },
            /**
             * @param {Number} value — одно из:
             * - установленная/обновленная пользователем дата и установленное ранее время
             * - установленная пользователем дата и *текущее время*,
             * если компонента даты не была задана ранее (стандартное поведение v-calendar,
             * когда значение модели не задано — см. геттер выше)
             * - NaN, если дата сброшена в интерфейсе
             */
            set(value) {
                let datetime = value;
                /* случай, когда значение компоненты даты устанавливается впервые */
                if (!this.isValueSet && !Number.isNaN(datetime)) {
                    if (!this.value) {
                        /* сбрасываем компоненту времени на начало суток */
                        datetime = handleDateTime((dt) => dt.startOf('day'))(datetime);
                    } else {
                        /* берем компоненту времени из ранее установленного значения */
                        datetime = handleDateTime((dt, dtPrev) => {
                            return dt.set({
                                hour: dtPrev.hour,
                                minute: dtPrev.minute,
                                second: dtPrev.second,
                                millisecond: dtPrev.millisecond,
                            });
                        })(datetime, this.value);
                    }
                }

                /*
                если изменить значение модели синхронно, v-calendar почему-то игнорирует обновление
                */
                this.$nextTick(() => {
                    const datetimeNormalized = datetime || null;
                    if (!this.isValueSet || datetimeNormalized !== this.value) {
                        this.$emit('change', datetimeNormalized);
                    }
                });
            },
        },
    },
});
</script>
