<template>
    <section class="espch">
        <div class="container">
            <div class="espch-coumns">
                <div class="espch-columns__left">
                    <img
                        class="espch-mail-img"
                        alt="ОВД-Инфо Генераптор динозаврик обжаловать в ЕСПЧ, КПЧ картинка"
                        src="./generaptor-espch.png"
                    />
                </div>
                <div class="espch-columns__right">
                    <ui-h2>
                        У&nbsp;тебя есть возможность обратиться в&nbsp;международную инстанцию.
                    </ui-h2>
                    <ui-paragraph>
                        Если ты&nbsp;хочешь пойти дальше&nbsp;&mdash; пиши на&nbsp;почту:
                    </ui-paragraph>
                    <div class="espch-mailto-wrapper">
                        <!-- TODO: иконка и копирование в буфер? -->
                        <base-button
                            type="link"
                            to="mailto:espch@ovdinfo.org"
                            view="light"
                            full-width
                            :data-gtm="JSON.stringify({
                                itemLocation: 'Обратиться в международную инстанцию',
                            })"
                        >
                            espch@ovdinfo.org
                        </base-button>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script lang="ts">
import Vue from 'vue';

import UiH2 from '../UiH2.vue';
import UiParagraph from '../UiParagraph.vue';


export default Vue.extend({
    components: {
        UiH2,
        UiParagraph,
    },
});
</script>

<style lang="scss" scoped>
@use "@/styles/mixins/breakpoint" as bp;
@use "@/styles/variables/colors";

.espch {
    background: colors.$grey-1;

    @include bp.mobile-md {
        padding: 40px 0;
    }

    @include bp.desktop-md {
        padding: 80px 0;
    }
}

.espch-coumns {
    display: flex;
    gap: 40px;
    align-items: center;

    @include bp.mobile-md {
        flex-direction: column;
    }

    @include bp.desktop-md {
        width: 650px;
        margin: auto;
        flex-direction: row;
    }
}

.espch-columns__left {
    width: 174px;
}

.espch-mail-img {
    width: 100%;
}

.espch-columns__right {
    display: flex;
    flex-direction: column;
    gap: 16px;

    @include bp.desktop-md {
        width: 440px;
    }
}

.espch-mailto-wrapper {
    @include bp.desktop-md {
        align-self: flex-start;
    }

    &::v-deep a {
        color: colors.$secondary-grey;
    }
}
</style>
