<template>
    <fragment>
        <slot
            :is-valid="ignoreErrors ? true : isValid"
            :errors="ignoreErrors ? [] : errors"
        />
    </fragment>
</template>

<script>
import Vue from 'vue';
import makeWithVModelMixin from '@shared/mixins/with-v-model';

/**
 * Компонент, который оборачивает компонент-элемент формы
 * и передаюет ему статус валидации соответствующего поля, полученный от `ValidationProvider`
 */
export default Vue.extend({
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'Validator',

    mixins: [
        makeWithVModelMixin({
            prop: 'validatedValue',
            event: 'validatedInput',
        }),
    ],

    inject: ['validation'],

    props: {
        /* v-model */
        validatedValue: {
            required: true,
        },
        /* ключ в валидируемом объекте с данными, которому соответствует значение `v-model` */
        keyName: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            ignoreErrors: false,
        };
    },

    computed: {
        /**
         * см. `ValidationProvider~getErrorsForKey`
         * @returns {ajv.ErrorObject[]}
         */
        errors() {
            return this.validation.getErrorsForKey(this.keyName);
        },

        /**
         * см. `ValidationProvider~isKeyValid`
         * @returns {Boolean}
         */
        isValid() {
            return this.validation.isKeyValid(this.keyName);
        },
    },

    watch: {
        errors() {
            /* изменение массива с ошибками указывает на то, что провайдер провалидировал форму */
            this.ignoreErrors = false;
        },

        validatedValue: {
            handler(/* value */) {
                /* временно скрываем ошибки валидации, если поле в процессе редактирования */
                this.ignoreErrors = true;
            },
            immediate: true,
        },
    },
});
</script>
