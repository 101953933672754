<template>
    <button
        v-if="type === 'button'"
        type="button"
        class="button-link"
        :class="{ 'button-link--arrow': arrow }"
        :disabled="disabled"
        v-on="$listeners"
        @click="trackClick($event, gtmClickEvent || 'buttonClick')"
    >
        <span>
            <slot></slot>
        </span>
        <span
            v-if="arrow"
            class="button-link__arrow"
        >
            <base-icon>
                <icon-arrow-right />
            </base-icon>
        </span>
    </button>
    <a
        v-else-if="type === 'link'"
        class="button-link"
        :class="{
            'button-link--disabled': disabled,
            'button-link--arrow': arrow,
        }"
        :href="to"
        target="_blank"
        @click="trackClick($event, gtmClickEvent || 'externalLinkClick')"
    >
        <span>
            <slot></slot>
        </span>
        <span
            v-if="arrow"
            class="button-link__arrow"
        >
            <base-icon>
                <icon-arrow-right />
            </base-icon>
        </span>
    </a>
    <router-link
        v-else-if="type === 'route'"
        :to="to"
        class="button-link"
        :class="{
            'button-link--disabled': disabled,
            'button-link--arrow': arrow,
        }"
        @click.native="trackClick($event, gtmClickEvent || 'internalLinkClick')"
    >
        <span>
            <slot></slot>
        </span>
        <span
            v-if="arrow"
            class="button-link__arrow"
        >
            <base-icon>
                <icon-arrow-right />
            </base-icon>
        </span>
    </router-link>
</template>

<script lang="ts">
import Vue from 'vue';
import clickTrackingMixin from '@/mixins/click-tracking';
import BaseIcon from '@/components/BaseIcon.vue';
import IconArrowRight from '@/icons/IconArrowRight.vue';

export default Vue.extend({
    components: { BaseIcon, IconArrowRight },

    mixins: [clickTrackingMixin],

    props: {
        type: {
            validator: (value) => ['button', 'link', 'route'].includes(value),
            default: 'button',
        },
        to: {
            type: String,
            required: false,
            default: '/',
        },
        arrow: {
            type: Boolean,
            required: false,
            default: false,
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false,
        },
        gtmClickEvent: {
            type: String,
            default: '',
        },
    },
});
</script>

<style lang="scss" scoped>
@use "@/styles/variables/typography";
@use "@/styles/variables/colors";

.button-link {
    --color: #{colors.$accent};

    position: relative;
    display: inline-flex;
    box-sizing: border-box;
    padding: 0;
    max-width: 100%;
    background: transparent;
    border: 0;
    border-bottom: 1px dashed var(--color);
    color: var(--color);
    font-family: typography.$font-family;
    font-size: 16px;
    line-height: 1.25;
    white-space: nowrap;
    text-decoration: none;
    text-overflow: ellipsis;
    cursor: pointer;
    flex-shrink: 0;
    appearance: none;

    &:hover {
        --color: #{colors.$accent-light};
    }

    &:active {
        --color: #{colors.$accent-dark};
    }

    @media (hover: none) {
        &:hover {
            --color: #{colors.$accent};
        }
    }
}

.button-link:disabled,
.button-link--disabled {
    --color: #{colors.$secondary-grey-light};

    pointer-events: none;
}

.button-link__arrow {
    display: flex;
    width: 24px;
    height: 24px;
    margin-left: 10px;
}

.button-link--arrow {
    align-items: center;
    padding: 0 10px;
    line-height: 44px;
    border-bottom: 0;
    border-radius: 4px;
    position: relative;
    background-color: colors.$generaptor-brand-bg;
    transition: background-color 0.15s ease;

    &:hover {
        background-color: colors.$generaptor-brand-bg-alpha;
    }

    &:active {
        background-color: colors.$generaptor-brand-bg;
    }

    @media (hover: none) {
        &:hover {
            background-color: colors.$generaptor-brand-bg;
        }
    }
}
</style>
