<template>
    <mail-to
        v-bind="$attrs"
        email="legal@ovdinfo.org"
    />
</template>

<script>
import Vue from 'vue';
import MailTo from './MailTo.vue';

export default Vue.extend({
    components: {
        MailTo,
    },
});
</script>
