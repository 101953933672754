<template>
    <div
        class="user-avatar"
        v-on="$listeners"
    >
        <img
            :src="src"
            alt="Ваш аватар"
        />
    </div>
</template>

<script>
import Vue from 'vue';
import defaultAvatarPic from '@/images/avatar-default.png';

export default Vue.extend({
    props: {
        src: {
            type: String,
            default: defaultAvatarPic,
        },
    },
});
</script>

<style lang="scss" scoped>
@use "@/styles/variables/colors";

.user-avatar {
    max-width: 60px;
    max-height: 60px;
    overflow: hidden;
    border-radius: 50%;
    background: colors.$white;
    border: 1px solid colors.$generaptor-brand;

    img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}
</style>
