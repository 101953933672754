<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        :width="width"
        :height="height"
        :viewBox="`0 0 ${vbWidth} ${vbHeight}`"
        :aria-labelledby="iconName"
        role="presentation"
    >
        <title
            :id="iconName"
            lang="ru"
        >{{ iconName }}</title>
        <slot />
    </svg>
</template>

<script lang="ts">
import Vue from 'vue';

export default Vue.extend({
    props: {
        iconName: {
            type: String,
            default: '',
        },
        width: {
            type: [Number, String],
            default: 24,
        },
        height: {
            type: [Number, String],
            default: 24,
        },
        vbWidth: {
            type: [Number, String],
            default: 24,
        },
        vbHeight: {
            type: [Number, String],
            default: 24,
        },
    },
});
</script>
