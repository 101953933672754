<template>
    <validation-observer
        ref="validationObserver"
        slim
    >
        <form
            class="signin-form"
            @submit.prevent="onSubmit"
        >
            <div class="signin-form__logo">
                <svg
                    width="166"
                    height="31"
                    alt="Логотип ОВД-Инфо"
                >
                    <use href="@/images/oi-logo.svg#logo-black" />
                </svg>
            </div>

            <!--
                Для чего здесь `key`:
                кнопка с обработчиком `startOver` на экране ошибки возвращает форму
                на начальный экран; при отсутствии `key` Vue переиспользует элементы, в частности,
                переделывает кнопку `startOver` в кнопку `submit`, а так как на кнопке `startOver`
                в это время происходит клик, он продолжает всплывать уже на кнопке `submit`,
                что приводит к повторному сабмиту формы (автоматическому!) 🤯
                см. https://github.com/vuejs/vue/issues/10928
            -->
            <div
                v-if="formStep === 'EMAIL_CAPTURE'"
                key="EMAIL_CAPTURE"
                class="signin-form__screen signin-form__screen--input"
            >
                <p class="signin-form__cta">
                    Войдите в&nbsp;личный кабинет, чтобы иметь доступ
                    ко&nbsp;всем своим документам.
                </p>
                <p class="signin-form__cta-details">
                    Введите email, и мы пришлем ссылку для&nbsp;входа
                </p>

                <text-field
                    v-model="email"
                    :has-focus="true"
                    type="email"
                    :required="true"
                    label="Ваш адрес электронной почты"
                    placeholder="Введите email"
                    :is-textarea="false"
                    class="signin-form__email-input"
                />

                <base-button
                    type="submit"
                    :pending="submissionStatus === 'PENDING'"
                    :full-width="true"
                    class="signin-form__submit"
                >
                    Войти
                </base-button>

                <link-button
                    type="button"
                    class="signin-form__cancel"
                    @click="cancel"
                >
                    Пропустить
                </link-button>
            </div>

            <div
                v-else-if="formStep === 'SUCCESS'"
                key="SUCCESS"
                class="signin-form__screen signin-form__screen--success"
            >
                <status-pic
                    status="success"
                    :desktop-wide-layout="false"
                    class="signin-form__status-pic"
                />

                <p class="signin-form__cta">
                    Проверьте почту.
                </p>
                <p class="signin-form__cta-details">
                    Мы отправили ссылку для&nbsp;входа в&nbsp;личный кабинет
                    на&nbsp;ваш почтовый ящик.
                </p>

                <p class="signin-form__footer">
                    Не получили письмо? Дайте нам знать
                </p>
            </div>

            <div
                v-else-if="formStep === 'FAILURE'"
                key="FAILURE"
                class="signin-form__screen signin-form__screen--failure"
            >
                <status-pic
                    status="failure"
                    :desktop-wide-layout="false"
                    class="signin-form__status-pic"
                />

                <p class="signin-form__error">
                    Нам не удалось отправить ссылку на&nbsp;ваш email
                </p>

                <base-button
                    type="button"
                    view="secondary"
                    :full-width="true"
                    class="signin-form__retry"
                    @click="startOver"
                >
                    Попробовать ещё раз
                </base-button>
            </div>
        </form>
    </validation-observer>
</template>

<script>
import Vue from 'vue';
import { createNamespacedHelpers } from 'vuex';
import TextField from '@/components/text-field/VeeTextField.vue';
import LinkButton from '@/components/button/LinkButton.vue';
import StatusPic from '@/components/forms/submission-result/StatusPic';

const { mapActions } = createNamespacedHelpers('auth');
const { mapState: mapFormState } = createNamespacedHelpers('appeal');

export default Vue.extend({
    components: {
        TextField,
        LinkButton,
        StatusPic,
    },

    data() {
        return {
            email: null,
            submissionStatus: 'EMAIL_CAPTURE',
        };
    },

    computed: {
        ...mapFormState({ formFields: 'fields' }),

        formStep() {
            if (this.submissionStatus === 'EMAIL_CAPTURE' || this.submissionStatus === 'PENDING') {
                return 'EMAIL_CAPTURE';
            }
            return this.submissionStatus;
        },
    },

    created() {
        /* если заполнена форма апелляции, берем почту оттуда */
        if (this.formFields['заявитель_электронная_почта']) {
            this.email = this.formFields['заявитель_электронная_почта'];
        }
    },

    methods: {
        ...mapActions(['signin']),

        async onSubmit() {
            const { validationObserver } = this.$refs;
            const isValid = await validationObserver.validate();
            if (!isValid) {
                return;
            }

            this.submissionStatus = 'PENDING';
            try {
                await this.signin(this.email);
                this.submissionStatus = 'SUCCESS';
            } catch (error) {
                this.submissionStatus = 'FAILURE';
                throw error;
            }
        },

        cancel() {
            this.$emit('cancel');
        },

        startOver() {
            this.submissionStatus = 'EMAIL_CAPTURE';
        },
    },
});
</script>

<style lang="scss">
@use "@/styles/variables/colors";
@use "@/styles/variables/grid";
@use "@/styles/variables/typography";
@use "@/styles/mixins/breakpoint";

.signin-form {
    margin: 0 auto;
}

.signin-form__logo {
    @include breakpoint.mobile {
        display: none;
    }

    svg {
        display: block;
    }
}

.signin-form__screen {
    display: flex;
    flex-direction: column;
    margin-top: grid.$space-3;
}

.signin-form__cta,
.signin-form__error {
    font-size: typography.$font-size-bigger;
    font-weight: 500;
    line-height: 1.2;
    color: colors.$grey-7;
}

.signin-form__error {
    color: colors.$error;
}

.signin-form__cta-details {
    color: colors.$grey-5;

    .signin-form__cta + & {
        margin-top: grid.$space-1;
    }
}

.signin-form__email-input {
    position: relative;

    .signin-form__cta + &,
    .signin-form__cta-details + & {
        margin-top: grid.$space-3;
    }

    .form-field__label {
        display: none;
    }

    .form-field__helper-wrapper {
        position: absolute;
        padding-top: 0;
    }

    .helper__text--error {
        margin-top: 0;
    }

    .form-field__icon-invalid-wrapper {
        display: none;
    }
}

.signin-form__submit {
    .signin-form__email-input + & {
        margin-top: grid.$space-4;
    }
}

.signin-form__cancel {
    align-self: center;
    margin-top: grid.$space-3 + grid.$space-05;
    border-bottom-style: solid;
}

.signin-form__footer {
    margin-top: grid.$space-5 + grid.$space-05;
    font-size: typography.$font-size-small;
    color: colors.$secondary-grey-light;
}

.signin-form__retry {
    .signin-form__error + & {
        margin-top: grid.$space-3;

        @include breakpoint.desktop {
            margin-top: grid.$space-5 + grid.$space-05;
        }
    }
}

.signin-form__screen--input {
    @include breakpoint.desktop {
        margin-top: grid.$space-5 + grid.$space-05;
    }
}

.signin-form__screen--success {
    .signin-form__status-pic {
        margin-top: grid.$space-2;
        margin-bottom: grid.$space-3;

        @include breakpoint.desktop {
            margin-top: grid.$space-4;
        }
    }

    .signin-form__footer {
        display: none;    // temp
        align-self: center;
    }
}

.signin-form__screen--failure {
    .signin-form__status-pic {
        margin-bottom: grid.$space-5 + grid.$space-05;

        @include breakpoint.desktop {
            margin-bottom: grid.$space-3;
        }
    }
}

.modal .signin-form {
    @include breakpoint.desktop {
        max-width: 345px;
    }
}
</style>
