import axios from 'axios';

/**
 * Класс-обертка для ошибок стора, чтобы компонентам, использующим стор,
 * при обработке ошибок не приходилось лезть во внутренности Axios exception-а
 * (главным образом для них, но и другие не будет лишним обернуть)
 */
export class StoreError extends Error {
    static create(message, cause) {
        if (cause instanceof StoreError) {
            return cause;
        }
        const error = new StoreError(message, { cause });
        error.code = axios.isAxiosError(cause) ? `NETWORK_${cause.code}` : 'OTHER';
        return error;
    }
}
