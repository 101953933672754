/**
 * @overview
 * Как устроена аутентификация/авторизация:
 * https://coda.io/d/_d1tntNqDbcf/_sutyW
 */

import axios from 'axios';
import decodeJWT from 'jwt-decode';
import { StoreError } from '@shared/utils/store-error';


const HTTP_UNAUTHORIZED = 401;

const TOKEN_STATUS = {
    INITIAL: null,
    LOADING: 'TOKEN_LOADING',
    FAILURE: 'TOKEN_FAILURE',
};

/**
 * @param {String} options.baseURL - origin (протокол + домен + порт) бэкенда
 * @returns {Function} каррированная функция
 */
export const makeExternalURLChecker = ({ baseURL }) => {
    /**
     * Проверяет, является ли `url` внутренним адресом приложения (фронтенда либо бэкенда)
     * @param {URL} url
     */
    return (url) => url.origin !== baseURL && url.origin !== window.location.origin;
};

/**
 * @param {String} options.baseURL - origin (протокол + домен + порт) бэкенда
 * @param {String} options.basePath - последовательность сегментов, общая для всех auth endpoints
 * @returns {Function} каррированная функция
 */
export const makeAuthURLChecker = ({ baseURL, basePath }) => {
    /**
     * Проверяет, является ли `url` адресом, связанным с аутентификацией/авторизацией
     * @param {URL} url
     */
    return (url) => (
        url.origin === baseURL
        && (
            url.pathname === basePath
            || url.pathname.indexOf(`${basePath}/`) === 0
        )
    );
};


/**
 * Создает модуль Vuex store
 * @param {Object} client - объект с интерфейсом как у Axios client
 * @param {String} api.baseURL - origin (протокол + домен + порт) бэкенда
 * @param {String} api.basePath - последовательность сегментов, общая для всех `endpoints`
 * @param {Object} api.endpoints - набор auth endpoints (каждый должен начинаться с `basePath`)
 * @param {String} api.endpoints.tokenRefresh - для получения новой пары токенов
 * @param {String} api.endpoints.signout - для разлогинивания
 * @param {String} [api.endpoints.emailSubmit]
 * @returns {Object} объект, пригодный для использования в качестве модуля Vuex store
 */
export const makeStore = ({ client, api }) => {
    /** @type {(Promise|null)} */
    let pendingRequest = null;

    return {
        state: {
            /*
            токен не должен быть доступен никому, кроме самого приложения,
            поэтому может храниться только в памяти!
            */
            accessToken: TOKEN_STATUS.INITIAL,
            isFillingForm: false,
        },

        getters: {
            user(state, getters) {
                if (!getters.isLoggedIn) {
                    return null;
                }
                const data = decodeJWT(state.accessToken);
                return {
                    email: data.user_id,
                };
            },

            isLoggedIn(state) {
                return (
                    state.accessToken !== TOKEN_STATUS.INITIAL
                    && state.accessToken !== TOKEN_STATUS.LOADING
                    && state.accessToken !== TOKEN_STATUS.FAILURE
                );
            },

            isPending(state) {
                return state.accessToken === TOKEN_STATUS.LOADING;
            },
        },

        /* eslint-disable no-param-reassign */
        mutations: {
            token(state, token) {
                state.accessToken = token || TOKEN_STATUS.FAILURE;
            },

            isFillingForm(state, bool) {
                state.isFillingForm = bool;
            },
        },
        /* eslint-enable no-param-reassign */

        actions: {
            /**
             * Запрашивает новую пару токенов, сохраняет access token в сторе
             * @returns {Boolean} успешно ли получены токены
             * @throws {StoreError}
             */
            async refreshTokens({ commit }) {
                if (!pendingRequest) {
                    pendingRequest = client.get(
                        api.endpoints.tokenRefresh,
                        { withCredentials: true },
                    );
                    commit('token', TOKEN_STATUS.LOADING);
                }
                try {
                    const { data } = await pendingRequest;
                    commit('token', data.access_token);
                    return true;
                } catch (error) {
                    /*
                    токены не выдаются, если пользователь не аутентифицирован, но это нормально
                    */
                    if (
                        axios.isAxiosError(error)
                        && error.response
                        && error.response.status === HTTP_UNAUTHORIZED
                    ) {
                        commit('token', TOKEN_STATUS.INITIAL);
                        return false;
                    }
                    /* если же произошла какая-то другая ошибка, кидаем её вызывающему коду */
                    commit('token', TOKEN_STATUS.FAILURE);
                    throw StoreError.create('Unable to renew tokens', error);
                } finally {
                    pendingRequest = null;
                }
            },

            /**
             * Отправляет адрес почты на сервер
             * @throws {StoreError}
             */
            async signin(_, email) {
                try {
                    const { data } = await client.post(api.endpoints.emailSubmit, { email });
                    if (data.Result !== 'OK') {
                        throw StoreError.create('Sign in attempt unsuccessful');
                    }
                } catch (error) {
                    throw StoreError.create('Unable to sign in', error);
                }
            },

            /**
             * Отправляет запрос на разлогинивание
             * @throws {StoreError}
             */
            async signout({ commit }) {
                try {
                    const { data } = await client.get(api.endpoints.signout);
                    if (data.Result !== 'OK') {
                        throw StoreError.create('Sign out attempt unsuccessful');
                    }
                    commit('token', TOKEN_STATUS.INITIAL);
                } catch (error) {
                    throw StoreError.create('Unable to sign out', error);
                }
            },

            startFillingForm({ commit }) {
                commit('isFillingForm', true);
            },
            completeFillingForm({ commit }) {
                commit('isFillingForm', false);
            },
        },
    };
};


/**
 * Навешивает на HTTP-клиент перехватчики запросов и ответов, которые выполняют задачи,
 * связанные с аутентификацией/авторизацией.
 * Вызывается в явном виде модулем главного стора
 * @param {Object} client - объект с интерфейсом как у Axios client
 * @param {Object} store - объект с интерфейсом как у Vuex store
 * и тем же набором state/getters/mutations/actions, как модуль,
 * создаваемый функцией `makeStore` выше
 * @param {String} api.baseURL - origin (протокол + домен + порт) бэкенда
 * @param {String} api.basePath - последовательность сегментов, общая для всех auth endpoints
 */
export const interceptClient = ({
    client, api, store: { state, getters, /* commit, */dispatch },
}) => {
    const isExternalURL = makeExternalURLChecker(api);
    const isAuthURL = makeAuthURLChecker(api);

    /**
     * Перехватчик запросов:
     * ко всем запросам к бэкенду Генераптора добавляет заголовок `Authorization`,
     * значением которого является access token, взятый из стора
     */
    client.interceptors.request.use(
        (config) => {
            if (!getters.isLoggedIn) {
                return config;
            }

            const newConfig = {
                headers: {},
                ...config,
            };

            newConfig.headers.Authorization = `Bearer ${state.accessToken}`;
            return newConfig;
        },

        (error) => Promise.reject(error),

        {
            synchronous: true,
            runWhen: (config) => !isExternalURL(new URL(config.url, config.baseURL)),
        },
    );

    /**
     * Перехватчик ответов:
     * успешные ответы оставляет без изменений;
     * если ошибка вызвана тем, что истек access token (так интерпретируется ответ с кодом 401),
     * то получает новую пару токенов вызовом специального action стора,
     * а затем повторяет исходный запрос, к которому, в свою очередь,
     * снова пременится request interceptor выше, но уже с новым access token-ом
     */
    client.interceptors.response.use(
        (result) => result,

        async (error) => {
            const responseURL = new URL(error.request.responseURL);
            /*
            можно переделать на `runWhen`, когда/если он заработает с response interceptor-ами,
            см. https://github.com/axios/axios/issues/4792
            */
            if (
                !error.response
                || error.response.status !== HTTP_UNAUTHORIZED
                || isExternalURL(responseURL)
                || isAuthURL(responseURL)
                || error.config.retry
            ) {
                throw error;
            }

            /*
            возможные ошибки в `refreshTokens` прокидываются вызывающему коду,
            try/catch здесь не используется намеренно
            */
            const hasRefreshed = await dispatch('refreshTokens');
            if (!hasRefreshed) {
                throw error;
            }

            const newRequest = {
                ...error.config,
                retry: true,
            };
            return client(newRequest);
        },
    );
};
