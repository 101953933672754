<template>
    <portal>
        <base-modal
            v-bind="$attrs"
            v-on="$listeners"
        >
            <slot></slot>
        </base-modal>
    </portal>
</template>

<script lang="ts">
import Vue from 'vue';
import { Portal } from '@linusborg/vue-simple-portal';
import BaseModal from './BaseModal.vue';

export default Vue.extend({
    components: {
        Portal,
        BaseModal,
    },
});
</script>
