<template>
    <div>
        <!-- Hero -->
        <section class="section section--padding-bottom-3">
            <hero-header @ctaclick="onHeroCtaClick" />
        </section>

        <section>
            <div class="container">
                <h2 class="h1 explainer__headline">
                    Куда можно обратиться за&nbsp;защитой своих прав
                    на&nbsp;свободу слова или свободу мирных собраний?
                </h2>
                <p class="explainer__description">
                    Защищать свои права вы можете в судах Российской Федерации и
                    в международных инстанциях, таких как Европейский суд по правам человека (ЕСПЧ)
                    и Комитет по правам человека в ООН (КПЧ).<br />
                    <strong>Примечание</strong>: В данное время в ЕСПЧ смогут обратиться только те,
                    кого задержали или на кого составили протокол
                    <strong>до 16 сентября 2022 г.</strong>
                </p>
            </div>
        </section>

        <section>
            <div class="container">
                <base-tabs>
                    <base-tabs-item
                        v-for="tab in courtTabs"
                        :key="tab.name"
                        :is-active="isActiveTab(tab.name)"
                        :title="tab.title"
                        :icon="tab.icon"
                        @selector-click="toggleTab(tab.name)"
                    />
                </base-tabs>
            </div>
        </section>

        <!-- How it works? -->
        <section class="section section--padding-bottom-3 section--slider">
            <div class="container">
                <keep-alive>
                    <component :is="stepsComponent" />
                </keep-alive>
            </div>
        </section>

        <!-- Docs -->
        <keep-alive>
            <component
                :is="docsComponent"
                gtm-location="Документы для суда"
            />
        </keep-alive>

        <!-- FAQ -->
        <section class="section section--padding-bottom-2">
            <div class="container">
                <court-faq :questions="questions" />
            </div>
        </section>
        <!-- More questions? -->
        <section
            class="section section--padding-top-1 section--padding-bottom-2 section--colored"
        >
            <div class="container">
                <post-faq />
            </div>
        </section>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import BaseTabs from '@/components/tabs/BaseTabs.vue';
import BaseTabsItem from '@/components/tabs/BaseTabsItem.vue';
import CourtFaq from '../court-faq/CourtFaq.vue';
import PostFaq from '../PostFaq.vue';
import ExplainerHRC from './explainer/ExplainerHRC.vue';
import ExplainerECHR from './explainer/ExplainerECHR.vue';
import HeroHeader from './HeroHeader.vue';
import DocsECHR from './docs/HomeDocsECHR.vue';
import DocsHRC from './docs/HomeDocsHRC.vue';
import echrLogo from '@/assets/echr-logo.png';
import hrcLogo from '@/assets/hrc-logo.png';

import { questions } from '../court-faq-questions';

export default Vue.extend({
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'HomeOld',

    components: {
        ExplainerECHR,
        ExplainerHRC,
        CourtFaq,
        HeroHeader,
        PostFaq,
        DocsECHR,
        DocsHRC,
        BaseTabs,
        BaseTabsItem,
    },

    data() {
        return {
            activeTab: 'HRC', // 'ECHR' 'HRC'
            courtTabs: [
                {
                    title: 'КПЧ',
                    name: 'HRC',
                    icon: hrcLogo,
                },
                {
                    title: 'ЕСПЧ',
                    name: 'ECHR',
                    icon: echrLogo,
                },
            ],
            questions,
        };
    },

    computed: {
        docsComponent() {
            return this.activeTab === 'HRC' ? 'DocsHRC' : 'DocsECHR';
        },
        stepsComponent() {
            return this.activeTab === 'HRC' ? 'ExplainerHRC' : 'ExplainerECHR';
        },
    },

    methods: {
        toggleTab(tab) {
            this.activeTab = tab;
        },
        isActiveTab(tab) {
            return tab === this.activeTab;
        },
        onHeroCtaClick() {
            const docsSection = document.getElementById('docs');
            const HEADER_HEIGHT = 64;

            if (!docsSection) {
                return;
            }
            window.scrollTo({
                top: docsSection.offsetTop - HEADER_HEIGHT,
                behavior: 'smooth',
            });
        },
    },
});
</script>
