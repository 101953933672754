<template>
    <div class="form-field">
        <label
            class="form-field__label"
            :for="id"
            :class="hideLabel && 'u-hidden-visually'"
        >
            {{ label }}
            <span
                v-if="uiRequired"
                class="required"
            >*</span>
        </label>
        <div class="form-field__wrapper">
            <div class="form-field__control-wrapper">
                <vue-select
                    v-model="proxyModel"
                    class="autocomplete"
                    :class="!isValid && 'autocomplete--invalid'"
                    :options="optionsResolved"
                    :reduce="(option) => option.id"
                    :input-id="id"
                    :placeholder="placeholder"
                    :disabled="disabled"
                    :components="{ OpenIndicator, DeselectButton }"
                    :multiple="multiple"
                    :filterable="!hasDynamicOptions"
                    label="name"
                    @search="onSearch"
                    @search:focus="onSearchFocus"
                    @search:blur="onSearchBlur"
                >
                    <template slot="no-options">
                        {{ noOptionsMessage }}
                    </template>
                </vue-select>
                <div
                    v-if="hasHelper || !isValid"
                    class="form-field__helper-wrapper"
                >
                    <form-input-helper
                        :link-url="helperLinkUrl"
                        :link-text="helperLinkText"
                        :is-error="!isValid"
                    >
                        {{ errors[0] || helperText }}
                    </form-input-helper>
                </div>
            </div>
            <div class="form-field__icon-invalid-wrapper">
                <div
                    class="form-field__icon-invalid"
                    :class="!isValid && 'is-visible'"
                >
                    <base-icon icon-name="Внимание">
                        <icon-attention />
                    </base-icon>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import VueSelect from 'vue-select';
import { debounce } from 'lodash';
import BaseIcon from '@/components/BaseIcon.vue';
import FormInputHelper from '@/components/FormInputHelper.vue';
import IconAttention from '@/icons/IconAttention.vue';
import OpenIndicator from './OpenIndicator.vue';
import DeselectButton from './DeselectButton.vue';

import 'vue-select/dist/vue-select.css';

const NoOptionsMessages = {
    NotFound: 'Такого значения нет',
    StartSearch: 'Начните вводить слово',
    Error: 'Во время выполнения поиска произошла ошибка',
};

interface Option {
    name: string;
    id: any;
}

const noneListedOptionDefault = { name: 'Нет нужного варианта', id: '' };

export default Vue.extend({
    components: {
        VueSelect,
        BaseIcon,
        IconAttention,
        FormInputHelper,
        /* eslint-disable vue/no-unused-components */
        OpenIndicator,
        DeselectButton,
        /* eslint-enable vue/no-unused-components */
    },

    model: {
        event: 'change',
    },

    props: {
        label: {
            type: String,
            default: '',
        },
        id: String,
        placeholder: {
            type: String,
            default: '',
        },
        hideLabel: Boolean,
        disabled: Boolean,
        uiRequired: {
            type: Boolean,
            default: false,
        },
        options: {
            type: Array as PropType<Option[]>,
            default: () => [],
        },
        value: {},
        isValid: {
            type: Boolean,
            default: true,
        },
        errors: {
            type: Array,
            default: () => [],
        },
        helperText: {
            type: String,
            default: '',
        },
        helperLinkUrl: {
            type: String,
            default: '',
        },
        helperLinkText: {
            type: String,
            default: '',
        },
        multiple: {
            type: Boolean,
            default: false,
        },
        includeNoneListedOption: {
            type: Boolean,
            default: false,
        },
        noneListedOption: {
            type: Object,
            default: () => noneListedOptionDefault,
        },
        name: {
            type: String,
            default: '',
        },
        getOptions: {
            type: Function,
            default: null,
        },
    },

    data() {
        return {
            OpenIndicator,
            DeselectButton,
            dynamicOptions: [],
            noOptionsMessage: NoOptionsMessages.StartSearch,
            isOpen: false,
            isSearching: false,
        };
    },

    computed: {
        proxyModel: {
            get() {
                return this.value;
            },
            set(value) {
                let normalizedValue = value;
                /*
                если родительская модель — массив, сохраняем ее тип даже в случае `multiple=false`
                */
                if (Array.isArray(this.value) && !Array.isArray(value)) {
                    normalizedValue = value === null ? [] : [value];
                }
                this.$emit('change', normalizedValue);
            },
        },

        hasDynamicOptions() {
            return Boolean(this.getOptions);
        },

        /*
        @todo Так как значением модели является только id опции,
        то в случае `hasDynamicOptions` при изменении модели родительским компонентом
        выбранная опция может не быть правильно отображена в интерфейсе,
        потому что доступные опции не подгружаются, пока пользователь не инициирует поиск
        */
        /*
        @todo? Неправильно работает сочетание `hasDynamicOptions` и `multiple`
        из-за того, что видимые опции сбрасываются при (повторном) открытии дропдауна
        */
        optionsResolved(): Array<Option> {
            const options = this.hasDynamicOptions ? this.dynamicOptions : this.options;
            if (this.hasDynamicOptions && this.isOpen && !this.isSearching) {
                return [];
            }

            return this.includeNoneListedOption
                ? [...options, { ...noneListedOptionDefault, ...this.noneListedOption }]
                : options;
        },

        hasHelper(): boolean {
            return (
                !!this.helperText || (this.helperLinkUrl && this.helperLinkText)
            );
        },
    },

    methods: {
        // eslint-disable-next-line no-unused-vars
        onSearch(query: string, loading: (_: boolean) => {}) {
            if (!this.hasDynamicOptions) {
                return;
            }
            this.search(query, loading, this);
        },

        /* eslint-disable no-param-reassign */
        search: debounce(async (query, loading, vm) => {
            vm.noOptionsMessage = NoOptionsMessages.StartSearch;

            /*
            если производился поиск, то при закрытии дропдауна
            снова триггерится событие `search` с пустым `query`
            */
            if (!query.length) {
                return;
            }

            vm.isSearching = true;
            loading(true);
            try {
                vm.dynamicOptions = await vm.getOptions(query);
                vm.noOptionsMessage = NoOptionsMessages.NotFound;
            } catch (error) {
                vm.dynamicOptions = [];
                vm.noOptionsMessage = NoOptionsMessages.Error;
                vm.isSearching = false;
                console.error(error);
            } finally {
                loading(false);
            }
        }, 350),
        /* eslint-enable no-param-reassign */

        onSearchFocus() {
            this.isOpen = true;
            this.isSearching = false;
        },

        onSearchBlur() {
            this.isOpen = false;
            this.isSearching = false;
        },
    },
});
</script>

<style lang="scss" scoped>
@use "sass:color";
@use "@/styles/variables/typography";
@use "@/styles/variables/colors";
@use "@/styles/form-controls" as *;

.autocomplete::v-deep {
    .vs__dropdown-toggle {
        @extend %form-field-control-styles;

        height: auto;
        min-height: 48px;
    }

    .vs__selected-options {
        padding: 0;
        display: flex;
    }

    &.vs--single .vs__selected-options {
        height: auto;
    }

    .vs__selected {
        height: 42px;
        padding: 8px 16px;
        font-size: 16px;
        background-color: color.change(#e39d72, $alpha: 0.5);
        border: 0;
        border-radius: 9px;
        color: currentcolor;
        margin: 2px;
    }

    &.vs--single .vs__selected {
        height: 100%;
        padding: 0;
        margin: 0;
        background-color: transparent;
    }

    &.vs--single.vs--open .vs__selected {
        display: none;
    }

    .vs__deselect {
        margin-left: 16px;
        color: #ac6133;
    }

    .vs__search {
        margin: 0;
        padding: 0;
        z-index: initial;

        &::placeholder {
            color: colors.$placeholder;
        }
    }

    .vs__actions {
        padding: 0;
    }

    .vs__open-indicator {
        transform: none;
    }

    .vs__dropdown-menu {
        top: 100%;
        z-index: 1;
    }

    .vs__dropdown-option {
        white-space: normal;
        padding: 4px 20px;
    }

    .vs__dropdown-option--highlight {
        background: #e39d72;
    }

    &.autocomplete--invalid .vs__dropdown-toggle {
        @extend %form-field-control-invalid-styles;
    }

    &.vs--open .vs__dropdown-toggle {
        @extend %form-field-control-focus-styles;
    }

    &.vs--disabled .vs__search,
    &.vs--disabled .vs__open-indicator,
    &.vs--disabled .vs__clear {
        background-color: transparent;
    }
}
</style>
