import Vue from 'vue';

export function getDefaultFieldValue({ formDef, fieldName }) {
    const fieldDef = formDef[fieldName];
    if (typeof fieldDef === 'object' && 'default' in fieldDef) {
        return fieldDef.default;
    }
    return undefined;
}

export function lookupCyrillicFieldName({ formDef, fieldName }) {
    return Object.keys(formDef).find((key) => formDef[key].name === fieldName);
}

export function resetField(state, { fieldName }) {
    const formDef = state.states;
    const defaultValue = getDefaultFieldValue({
        formDef,
        fieldName: lookupCyrillicFieldName({ formDef, fieldName }),
    });
    if (defaultValue !== undefined) {
        Vue.set(state.fields, fieldName, defaultValue);
    } else {
        Vue.delete(state.fields, fieldName);
    }
}
