<!-- eslint-disable-next-line vue/valid-template-root -->
<template />

<script>
import Vue from 'vue';

/**
 * Компонент для того, чтобы в Sentry всплывали ошибки, если в схеме появились/изменились поля так,
 * что им не могут быть сопоставлены компоненты-элементы формы
 */
export default Vue.extend({
    props: {
        formType: {
            type: String,
            required: true,
        },
        fieldType: {
            type: String,
            required: true,
        },
    },

    created() {
        throw new Error(`Unsupported field "${this.fieldType}" in form "${this.formType}"`);
    },
});
</script>
