<template>
    <transition name="overlay-transition">
        <div
            v-if="isOpen"
            class="overlay"
        >
            <div class="overlay__body-wrapper">
                <div class="overlay__body">
                    <slot name="close">
                        <button
                            class="overlay__button-close"
                            @click="close"
                        >
                            <base-icon>
                                <use
                                    href="@/assets/x-2.svg#icon"
                                    class="
                                        overlay__button-close-icon
                                        overlay__button-close-icon--desktop
                                    "
                                />
                                <use
                                    href="@/assets/chevron.svg#icon-left"
                                    class="
                                        overlay__button-close-icon
                                        overlay__button-close-icon--mobile
                                    "
                                />
                            </base-icon>
                        </button>
                    </slot>

                    <slot />
                </div>
            </div>

            <div
                class="overlay__backdrop"
                @click="close"
            ></div>
        </div>
    </transition>
</template>

<script>
import Vue from 'vue';
import BaseIcon from '@/components/BaseIcon.vue';

export default Vue.extend({
    components: { BaseIcon },

    props: {
        isOpen: {
            type: Boolean,
            required: true,
        },
    },

    methods: {
        handleKeydown(e) {
            if (this.isOpen && e.key === 'Escape') {
                this.close();
            }
        },

        close() {
            this.$emit('close');
        },
    },
});
</script>

<style lang="scss">
@use "@/styles/mixins/hover" as *;
@use "@/styles/mixins/breakpoint";
@use "@/styles/variables/colors";
@use "@/styles/variables/grid";

.overlay {
    position: relative;

    @include breakpoint.mobile {
        position: fixed;
        z-index: 2147483647;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background: colors.$white;
    }
}

.overlay__button-close {
    margin: 0;
    padding: 0;
    border: none;
    background: transparent;
    color: colors.$grey;
    cursor: pointer;

    svg {
        display: block;
    }

    @include breakpoint.mobile {
        position: sticky;
        top: 0;
        display: block;
        padding: grid.$space-5 grid.$space-2 grid.$space-2;
        border-bottom: 1px solid colors.$grey-1;

        .overlay__button-close-icon--desktop {
            display: none;
        }
    }

    @include breakpoint.desktop {
        position: absolute;
        top: grid.$space-2;
        right: grid.$space-2;
        padding: grid.$space-1;

        .overlay__button-close-icon--mobile {
            display: none;
        }
    }

    @include hover {
        color: colors.$secondary-grey-light;
    }

    &:active {
        color: colors.$secondary-grey;
    }
}

.overlay__backdrop {
    @include breakpoint.mobile {
        display: none;
    }

    @include breakpoint.desktop {
        position: fixed;
        top: 0;
        left: 0;
        z-index: -1;
        width: 100%;
        height: 100%;
    }
}
</style>
