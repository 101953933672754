<template>
    <home-page-new v-if="abBucket == 'new'" />
    <home-page-old v-else-if="abBucket == 'control'" />
</template>

<script lang="ts">
import Vue from 'vue';

import { NONE_VARIANT, NOT_READY } from '@/store/ab-tests';
import HomePageOld from '@/components/page-home/page-old/Index.vue';
import HomePageNew from '@/components/page-home/page-new/Index.vue';

export default Vue.extend({
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'Home',

    components: {
        HomePageOld,
        HomePageNew,
    },

    computed: {
        abBucket() {
            const bucket = this.$store.getters['abTests/getBucket']('landing');
            if (bucket === NOT_READY) {
                return null;
            }
            return bucket === NONE_VARIANT ? 'control' : bucket;
        },
    },

    watch: {
        abBucket: {
            handler(bucket) {
                if (bucket !== null) {
                    this.$store.dispatch('tracking/track', { landingABGroup: bucket });
                }
            },
            immediate: true,
        },
    },
});
</script>
