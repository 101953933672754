<template>
    <base-icon>
        <icon-search />
    </base-icon>
</template>

<script lang="ts">
import Vue from 'vue';
import BaseIcon from '@/components/BaseIcon.vue';
import IconSearch from '@/icons/IconSearch.vue';

export default Vue.extend({
    components: {
        BaseIcon,
        IconSearch,
    },
});
</script>
