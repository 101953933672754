<template>
    <date-picker
        v-bind="$attrs"
        v-model="proxyModel"
        :errors="validationErrors"
    />
</template>

<script lang="ts">
import Vue from 'vue';
import makeWithVModelMixin from '@shared/mixins/with-v-model';
import { errorMessages } from '@shared/validation/constants';
import validationErrorsMixin from '@shared/validation/mixins/ui-errors';
import DatePicker from './DatePicker.vue';

export default Vue.extend({
    components: {
        DatePicker,
    },

    mixins: [
        makeWithVModelMixin({ event: 'change' }),
        validationErrorsMixin,
    ],

    props: {
        value: {
            type: Number,
            // required: true,
        },
        errors: {
            type: Array,
            default: () => [],
        },
    },

    methods: {
        /**
         * @param {ajv.ErrorObject} error
         */
        getValidationErrorMessage(error) {
            if (
                error.keyword === 'required'
                || /^#\/\$defs\/nonNegativeNumber/.test(error.schemaPath)
            ) {
                return {
                    message: errorMessages.required(),
                    type: 'REQUIRED_MISSING',
                };
            }
            return null;
        },
    },
});
</script>
