<template>
    <div
        :class="[
            { 'formular__layout--borderless': borderless },
            { 'formular__layout--clean': clean },
        ]"
        class="formular__layout"
    >
        <div class="formular__subheading">
            {{ headline }}
        </div>
        <div class="formular__fields">
            <slot />
        </div>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';

export default Vue.extend({
    props: {
        headline: {
            type: String,
            default: '',
        },
        borderless: {
            type: Boolean,
            default: false,
        },
        clean: {
            type: Boolean,
            default: false,
        },
    },
});
</script>
