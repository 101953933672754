<template>
    <div
        class="form-field radio-group"
        :class="!isValid && 'radio-group--invalid'"
    >
        <label
            class="form-field__label radio-group__label"
            :class="hideLabel && 'u-hidden-visually'"
        >
            {{ label }}
            <span
                v-if="uiRequired"
                class="required"
            >*</span>
        </label>
        <div
            class="radio-group__group"
            :class="isHorizontal && 'radio-group__group--horizontal'"
        >
            <label
                v-for="option in options"
                :key="option.id"
                class="form-field__selector radio radio-group__radio"
            >
                <radio
                    v-if="option"
                    v-model="proxyModel"
                    :name="name"
                    :value="option.id"
                    :label="option.name"
                    :is-valid="isValid"
                />
            </label>
        </div>
        <div
            v-if="hasHelper"
            class="form-field__helper-wrapper"
        >
            <form-input-helper
                :link-url="helperLinkUrl"
                :link-text="helperLinkText"
            >
                {{ helperText }}
            </form-input-helper>
        </div>
    </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import makeWithVModelMixin from '@shared/mixins/with-v-model';
import FormInputHelper from '@/components/FormInputHelper.vue';
import Radio from '@/components/radio/BaseRadio.vue';

/**
 * Использование с `v-model` в родительском компоненте:
 * на чтение: при совпадении значения модели с `value` (`id` соотвутствующего `option`)
 * какого-либо переключателя из группы он становится выбранным; остальные - невыбранными
 * на запись: при выборе переключателя из группы его `value` (`option.id`) записывается в модель
 */
export default Vue.extend({
    components: {
        FormInputHelper,
        Radio,
    },

    mixins: [
        makeWithVModelMixin({
            prop: 'checked',
            event: 'change',
        }),
    ],

    props: {
        name: {
            type: String,
            required: true,
        },
        checked: {},
        options: {
            type: Array as PropType<Option[]>,
            required: true,
        },
        label: {
            type: String,
            default: '',
        },
        hideLabel: {
            type: Boolean,
            default: false,
        },
        isHorizontal: {
            type: Boolean,
            default: false,
        },
        isValid: {
            type: Boolean,
            default: true,
        },
        helperText: {
            type: String,
            default: '',
        },
        helperLinkUrl: {
            type: String,
            default: '',
        },
        helperLinkText: {
            type: String,
            default: '',
        },
        uiRequired: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        hasHelper(): boolean {
            return (
                this.helperText || (this.helperLinkUrl && this.helperLinkText)
            );
        },
    },
});
</script>

<style lang="scss" scoped>
@use "@/styles/variables/grid";

.form-field__selector {
    margin-right: grid.$space-1 + grid.$space-05;
}

.radio-group {
    padding-right: grid.$space-4;
}

.radio-group__label {
    margin-bottom: grid.$space-2;
}

.radio-group__radio {
    margin-bottom: grid.$space-1 + grid.$space-05;
}

.radio-group__group {
    display: flex;
    flex-direction: column;

    & > .form-field {
        margin-bottom: grid.$space-2;
    }

    &--horizontal {
        flex-direction: row;

        .radio-group__radio {
            margin-bottom: 0;
        }
    }

    &--horizontal > .form-field {
        margin-bottom: 0;
    }

    &--horizontal > .form-field:not(:last-of-type) {
        margin-right: grid.$space-4;
    }
}
</style>
