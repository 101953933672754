<template>
    <text-field
        v-bind="$attrs"
        v-model="proxyModel"
        :errors="validationErrors"
    />
</template>

<script lang="ts">
import Vue from 'vue';
import makeWithVModelMixin from '@shared/mixins/with-v-model';
import { errorMessages } from '@shared/validation/constants';
import validationErrorsMixin from '@shared/validation/mixins/ui-errors';
import TextField from './BaseTextField.vue';

export default Vue.extend({
    components: {
        TextField,
    },

    mixins: [
        makeWithVModelMixin(),
        validationErrorsMixin,
    ],

    props: {
        value: {
            type: String,
            // required: true,
        },
        errors: {
            type: Array,
            default: () => [],
        },
    },

    methods: {
        /**
         * @param {ajv.ErrorObject} error
         */
        getValidationErrorMessage(error) {
            if (
                error.keyword === 'required'
                || /^#\/\$defs\/nonEmptyString/.test(error.schemaPath)
            ) {
                return errorMessages.required();
            }
            return errorMessages.fallback();
        },
    },
});
</script>
