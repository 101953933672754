<template>
    <div class="formular__result">
        <h1>Что-то пошло не так</h1>

        <div class="formular__teaser rich-text">
            <p>
                Приносим извинения за&nbsp;неудобства&nbsp;— скорее всего, произошли сбои
                в&nbsp;системе, и нам не удалось отправить документы на вашу почту<!--
                    эти HTML-комментарии здесь для того, чтобы перед точкой не было пробела,
                    когда email не рендерится!
             --><template v-if="email">
                    (<code class="formular__result__preformatted">{{ email }}</code>)<!--
             --></template>.
                Если они не придут в&nbsp;течение нескольких минут&nbsp;— проверьте, пожалуйста,
                папку со&nbsp;спамом.
            </p>
            <p>
                Попробуйте отправить данные ещё раз. Если ошибка не&nbsp;исчезает,
                просим сообщить в&nbsp;службу поддержки&nbsp;— <email-us />.
            </p>

            <p>
                <slot name="addendum" />
            </p>
        </div>

        <base-button
            type="button"
            class="formular__result__btn"
            @click="startOver"
        >
            Отправить ещё раз
        </base-button>

        <status-pic
            status="failure"
            class="formular__result--status-pic"
        />
    </div>
</template>

<script>
import Vue from 'vue';
import EmailUs from '@/components/mail-to/EmailOILegal.vue';
import StatusPic from './StatusPic.vue';

export default Vue.extend({
    components: {
        EmailUs,
        StatusPic,
    },

    props: {
        startOver: {
            type: Function,
            required: true,
        },
        email: {
            type: String,
            default: '',
        },
    },
});
</script>

<style lang="scss" scoped>
@use "@/styles/variables/grid";
@use "@/styles/mixins/breakpoint";
@import "./common";

.formular__result--status-pic {
    margin-top: grid.$space-8;

    @include breakpoint.desktop {
        margin-top: grid.$space-4;
    }
}
</style>
