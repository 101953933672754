// TODO: this vocabs should be dynamic

const hardcodedVocabs = {
    genderOptions: [
        { name: 'Женский', id: 'female' },
        { name: 'Мужской', id: 'male' },
    ],
    accusationOptions: [
        { name: 'ст. 19.3 ч.1 КоАП РФ', id: 'ст. 19.3 ч.1 КоАП РФ' },
        { name: 'ст. 20.2 ч.2 КоАП РФ', id: 'ст. 20.2 ч.2 КоАП РФ' },
        { name: 'ст. 20.2 ч.5 КоАП РФ', id: 'ст. 20.2 ч.5 КоАП РФ' },
        {
            name: 'ст. 20.2 ч.6.1 КоАП РФ',
            id: 'ст. 20.2 ч.6.1 КоАП РФ',
        },
        { name: 'ст. 20.2 ч.8 КоАП РФ', id: 'ст. 20.2 ч.8 КоАП РФ' },
        {
            name: 'ст. 20.2.2 ч.1 КоАП РФ',
            id: 'ст. 20.2.2 ч.1 КоАП РФ',
        },
        {
            name: 'ст. 20.6.1 ч.1 КоАП РФ',
            id: 'ст. 20.6.1 ч.1 КоАП РФ',
        },
        {
            name: 'ст. 20.3.3 ч.1 КоАП РФ (пост в соцсетях)',
            id: 'ст. 20.3.3 ч.1 КоАП РФ (пост в соцсетях)',
        },
        {
            name: 'ст. 20.3.3 ч.1 КоАП РФ (участие в акции)',
            id: 'ст. 20.3.3 ч.1 КоАП РФ (участие в акции)',
        },
    ],

    punishmentOptions: [
        { name: 'Штраф', id: 'Штраф' },
        { name: 'Арест', id: 'Арест' },
        { name: 'Обязательные работы', id: 'Обязательные работы' },
    ],

    penaltyReasonOptions: [
        {
            name: 'привлекаюсь к административной ответственности впервые',
            id: 'привлекаюсь к административной ответственности впервые',
        },
        {
            name: 'имею на иждивении несовершеннолетних детей',
            id: 'имею на иждивении несовершеннолетних детей',
        },
        {
            name: 'являюсь инвалидом',
            id: 'являюсь инвалидом группы',
        },
        {
            name: 'не имею постоянной работы',
            id: 'не имею постоянной работы',
        },
        {
            name: 'нахожусь в тяжелом материальном положении',
            id: 'нахожусь в тяжелом материальном положении',
        },
    ],

    disabilityGroupOptions: [
        { name: 'Ⅰ', id: 'I' },
        { name: 'Ⅱ', id: 'II' },
        { name: 'Ⅲ', id: 'III' },
    ],

    videoConferenceTypeOptions: [
        { name: 'Skype', id: 'Skype' },
        { name: 'WhatsApp', id: 'WhatsApp' },
        { name: 'Zoom', id: 'Zoom' },
    ],
};

export default hardcodedVocabs;
