<template>
    <router-link
        v-if="type === 'route'"
        class="button"
        :class="{
            'button--primary': view === 'primary',
            'button--secondary': view === 'secondary',
            'button--light': view === 'light',
            'button--disabled': disabled,
            'button--full-width': fullWidth,
        }"
        :to="to"
        @click.native="trackClick($event, gtmClickEvent || 'internalLinkClick')"
    >
        <span class="button__content">
            <span class="button__icon">
                <slot name="icon"></slot>
            </span>
            <span>
                <slot></slot>
            </span>
        </span>
    </router-link>
    <a
        v-else-if="type === 'link'"
        ref="focusable"
        class="button"
        :class="{
            'button--primary': view === 'primary',
            'button--secondary': view === 'secondary',
            'button--light': view === 'light',
            'button--disabled': disabled,
            'button--full-width': fullWidth,
        }"
        :href="to"
        target="_blank"
        @click="trackClick($event, gtmClickEvent || 'externalLinkClick')"
    >
        <span class="button__content">
            <span class="button__icon">
                <slot name="icon"></slot>
            </span>
            <span>
                <slot></slot>
            </span>
        </span>
    </a>
    <button
        v-else
        ref="focusable"
        :type="type"
        class="button"
        :class="{
            'button--primary': view === 'primary',
            'button--secondary': view === 'secondary',
            'button--light': view === 'light',
            'button--pending': pending,
            'button--full-width': fullWidth,
        }"
        :disabled="disabled"
        v-on="$listeners"
        @click="trackClick($event, gtmClickEvent || 'buttonClick')"
    >
        <div
            v-if="pending"
            class="button__spinner"
        >
            <loading-spinner />
        </div>
        <span
            class="button__content"
            :class="pending && 'button__content--pending'"
        >
            <span class="button__icon">
                <slot name="icon"></slot>
            </span>
            <span>
                <slot></slot>
            </span>
        </span>
    </button>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import focusedElementMixin from '@shared/mixins/focused-element';
import clickTrackingMixin from '@/mixins/click-tracking';
import LoadingSpinner from '@/components/LoadingSpinner.vue';

export default Vue.extend({
    components: {
        LoadingSpinner,
    },

    mixins: [
        focusedElementMixin,
        clickTrackingMixin,
    ],

    props: {
        type: {
            validator: (value) => ['button', 'submit', 'link', 'route'].includes(value),
            // required: true,
            default: 'button',
        },
        view: {
            type: String as PropType<'primary' | 'secondary' | 'light'>,
            required: false,
            default: 'primary',
        },
        pending: {
            type: Boolean,
            default: false,
        },
        to: {
            type: String,
            required: false,
            default: '/',
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false,
        },
        fullWidth: {
            type: Boolean,
            default: false,
        },
        gtmClickEvent: {
            type: String,
            default: '',
        },
    },
});
</script>

<style lang="scss" scoped>
@use "@/styles/mixins/hover" as *;
@use "@/styles/variables/typography";
@use "@/styles/variables/colors";

.button {
    position: relative;
    display: inline-flex;
    overflow: hidden;
    box-sizing: border-box;
    max-width: 100%;
    height: 50px;
    padding: 0 18px;
    background: transparent;
    border: 1px solid transparent;
    border-radius: 4px;
    font-family: typography.$font-family;
    font-size: 18px;
    font-weight: bold;
    line-height: 48px;
    white-space: nowrap;
    text-decoration: none;
    text-overflow: ellipsis;
    cursor: pointer;
    transition: background-color 0.15s ease, border-color 0.15s ease,
        box-shadow 0.15s ease, color 0.15s ease, fill 0.15s ease;
    flex-shrink: 0;
    appearance: none;

    &--primary {
        --text-color: #{colors.$white};
        --bg-color: #{colors.$accent};
        --bg-color-hover: #{colors.$accent-light};
        --bg-color-active: #{colors.$accent-dark};

        color: var(--text-color);
        background-color: var(--bg-color);

        @include hover {
            background-color: var(--bg-color-hover);
        }

        &:active {
            background-color: var(--bg-color-active);
        }
    }

    &--secondary {
        --text-color: #{colors.$accent};
        --text-color-active: #{colors.$white};
        --border-color: #{colors.$accent};

        color: var(--text-color);
        border-color: var(--border-color);

        @include hover {
            color: var(--text-color-active);
            background-color: var(--border-color);
        }

        &:active {
            color: var(--text-color-active);
            background-color: var(--border-color);
        }
    }

    &--light {
        --text-color: #{colors.$text};
        --bg-color: #{colors.$white};

        color: var(--text-color);
        background-color: var(--bg-color);

        @include hover {
            // TODO: стили на ховере
        }

        &:active {
            // TODO: стили на ховере
        }
    }

    &--pending {
        pointer-events: none;
    }

    &--full-width {
        width: 100%;
        justify-content: center;
    }
}

.button:disabled,
.button--disabled {
    pointer-events: none;
}

.button--primary:disabled,
.button--primary.button--disabled {
    background-color: colors.$secondary-grey-light;
}

.button--secondary:disabled,
.button--secondary.button--disabled {
    opacity: 0.5;
}

.button__content {
    display: inline-flex;
    align-items: center;

    &--pending {
        visibility: hidden;
    }
}

.button__icon:not(:empty) {
    display: flex;
    margin-right: 8px;
}

.button__spinner {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>
