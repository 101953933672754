import { DateTime } from 'luxon';

/**
 * Позволяет производить манипуляции с датой-временем в виде объектов `luxon.DateTime`
 * вместо числового представления
 * @param {Function} handler - функция, принимающая объект(ы) даты-времени
 * и возвращающая объект даты-времени
 * @returns {Function} - обертка над `handler`, принимающая и возвращающая их же в виде чисел
 */
export function handleDateTime(handler) {
    /*
    настройка `timezone="UTC"` в v-calendar значит, что значения даты-время относятся к UTC
    (так с ними удобнее работать; для пользователя смысл другой — локальное время),
    поэтому при преобразовании в объекты важно сохранить часовой пояс
    */
    const timestampToDateTime = (ts) => DateTime.fromMillis(ts, { zone: 'utc' });
    const dateTimeToTimestamp = (dt) => dt.toMillis();

    /**
     * @param {...Number} datetime - epoch timestamp
     * @returns {Number} - epoch timestamp
     */
    return function wrapper(...datetimes) {
        return dateTimeToTimestamp(
            handler.apply(this, datetimes.map(timestampToDateTime)),
        );
    };
}
