<template>
    <div
        v-if="confirmationStatus === 'failure'"
        class="email-confirmation"
    >
        <img
            src="@/images/bad-link.png"
            alt="
                Стилизованное изображение окна браузера со скомканным листом бумаги
                и надписью «Ссылка недействительна»
            "
            class="email-confirmation__pic"
        />

        <link-button
            type="route"
            to="/"
            class="email-confirmation__action"
        >
            Вернуться в Генераптор
        </link-button>

        <link-button
            type="button"
            class="email-confirmation__action"
            @click="openAuthModal"
        >
            Попробовать ещё раз
        </link-button>

        <sign-in
            :is-open="isAuthModalOpen"
            @close="closeAuthModal"
        />
    </div>
</template>

<script>
import Vue from 'vue';
import LinkButton from '@/components/button/LinkButton.vue';
import SignIn from './SignInModal.vue';

export default Vue.extend({
    components: {
        LinkButton,
        SignIn,
    },

    data() {
        return {
            confirmationStatus: null,
            isAuthModalOpen: false,
        };
    },

    created() {
        const isError = 'error' in this.$route.query;
        this.confirmationStatus = isError ? 'failure' : 'success';

        // @todo Редиректить на "мои кейсы" вместо главной
        if (this.confirmationStatus === 'success') {
            this.$router.replace('/');
        }
    },

    methods: {
        openAuthModal() {
            this.isAuthModalOpen = true;
        },
        closeAuthModal() {
            this.isAuthModalOpen = false;
        },
    },
});
</script>

<style lang="scss" scoped>
@use "@/styles/mixins/breakpoint";
@use "@/styles/variables/grid";

.email-confirmation {
    flex-grow: 1;
    margin: grid.$space-2 auto grid.$space-4;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @include breakpoint.desktop {
        width: 600px;
    }
}

.email-confirmation__pic {
    width: 100%;
    margin-bottom: grid.$space-5 + grid.$space-05;
}

.email-confirmation__action {
    border-bottom-style: solid;

    &:not(:last-child) {
        margin-bottom: grid.$space-2;
    }
}
</style>
