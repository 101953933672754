<template>
    <court-explainer
        :headline="content.title"
        :description="content.intro"
        :steps="content.steps"
    />
</template>

<script>
import CourtExplainer from './CourtExplainer.vue';
import { homeContent } from './explainerStepsContent';

export default {
    name: 'ExplainerHRC',
    components: {
        CourtExplainer,
    },
    data() {
        return {
            content: homeContent.HRC,
        };
    },
};
</script>
