/**
 * Выбирает значение из списка случайным образом с учетом весов
 * @param {*} items[].value — значение, которое может быть выбрано
 * @param {Number} items[].weight — весовой коэффициент этого значения;
 *   сумма весов всех значений должна составлять 1
 * @returns {*}
 */
export function weightedChoice(items) {
    const random = Math.random();

    let cumulativeWeight = 0;
    for (let idx = 0; idx < items.length; idx += 1) {
        const item = items[idx];
        cumulativeWeight += item.weight;

        if (random <= cumulativeWeight) {
            return item.value;
        }
    }

    return null;
}

/**
 * Возвращает список ключей `localStorage`, удовлетворяющих критерию
 * @param {Function} iteratee — предикат, вызываемый для проверки каждого ключа
 * @returns {String[]}
 */
export function getStorageKeys(iteratee) {
    const storageKeys = [];
    for (let idx = 0; idx < localStorage.length; idx += 1) {
        const key = localStorage.key(idx);
        if (iteratee(key)) {
            storageKeys.push(key);
        }
    }
    return storageKeys;
}
