var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.type === 'route')?_c('router-link',{staticClass:"button",class:{
        'button--primary': _vm.view === 'primary',
        'button--secondary': _vm.view === 'secondary',
        'button--light': _vm.view === 'light',
        'button--disabled': _vm.disabled,
        'button--full-width': _vm.fullWidth,
    },attrs:{"to":_vm.to},nativeOn:{"click":function($event){return _vm.trackClick($event, _vm.gtmClickEvent || 'internalLinkClick')}}},[_c('span',{staticClass:"button__content"},[_c('span',{staticClass:"button__icon"},[_vm._t("icon")],2),_vm._v(" "),_c('span',[_vm._t("default")],2)])]):(_vm.type === 'link')?_c('a',{ref:"focusable",staticClass:"button",class:{
        'button--primary': _vm.view === 'primary',
        'button--secondary': _vm.view === 'secondary',
        'button--light': _vm.view === 'light',
        'button--disabled': _vm.disabled,
        'button--full-width': _vm.fullWidth,
    },attrs:{"href":_vm.to,"target":"_blank"},on:{"click":function($event){return _vm.trackClick($event, _vm.gtmClickEvent || 'externalLinkClick')}}},[_c('span',{staticClass:"button__content"},[_c('span',{staticClass:"button__icon"},[_vm._t("icon")],2),_vm._v(" "),_c('span',[_vm._t("default")],2)])]):_c('button',_vm._g({ref:"focusable",staticClass:"button",class:{
        'button--primary': _vm.view === 'primary',
        'button--secondary': _vm.view === 'secondary',
        'button--light': _vm.view === 'light',
        'button--pending': _vm.pending,
        'button--full-width': _vm.fullWidth,
    },attrs:{"type":_vm.type,"disabled":_vm.disabled},on:{"click":function($event){return _vm.trackClick($event, _vm.gtmClickEvent || 'buttonClick')}}},_vm.$listeners),[(_vm.pending)?_c('div',{staticClass:"button__spinner"},[_c('loading-spinner')],1):_vm._e(),_vm._v(" "),_c('span',{staticClass:"button__content",class:_vm.pending && 'button__content--pending'},[_c('span',{staticClass:"button__icon"},[_vm._t("icon")],2),_vm._v(" "),_c('span',[_vm._t("default")],2)])])}
var staticRenderFns = []

export { render, staticRenderFns }