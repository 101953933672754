<template>
    <text-field
        v-if="field.fe_type.startsWith('text_')"
        v-model="proxyModel"
        :name="field.name"
        :label="field.fe_label"
        :helper-text="field.fe_hint"
        :placeholder="field.fe_placeholder"
        :is-multiline="field.fe_type === 'text_multiline'"
        :ui-required="field.fe_required || false"
        :is-valid="$attrs['is-valid']"
        :errors="$attrs.errors"
    />
    <checkbox
        v-else-if="field.fe_type === 'yes_no'"
        v-model="proxyModel"
        :name="field.name"
        :label="field.fe_label"
        :helper-text="field.fe_hint"
        :ui-required="field.fe_required || false"
        :is-valid="$attrs['is-valid']"
        :errors="$attrs.errors"
    />
    <checkbox-group
        v-else-if="field.fe_type === 'yes_no_group'"
        v-model="proxyModel"
        :name="field.name"
        :options="field.options"
        :label="field.fe_label"
        :helper-text="field.fe_hint"
        :ui-required="field.fe_required || false"
        :is-valid="$attrs['is-valid']"
        :errors="$attrs.errors"
    />
    <radio-group
        v-else-if="field.fe_type === 'choice'"
        v-model="proxyModel"
        :name="field.name"
        :options="field.options"
        :label="field.fe_label"
        :helper-text="field.fe_hint"
        :ui-required="field.fe_required || false"
        :is-valid="$attrs['is-valid']"
        :errors="$attrs.errors"
    />
    <select-box
        v-else-if="field.fe_type === 'list'"
        v-model="proxyModel"
        :name="field.name"
        :options="field.options"
        :multiple="Boolean(field.multiple_values_allowed)"
        :label="field.fe_label"
        :helper-text="field.fe_hint"
        :ui-required="field.fe_required || false"
        :is-valid="$attrs['is-valid']"
        :errors="$attrs.errors"
    />
    <autocomplete-input
        v-else-if="field.fe_type === 'list_searchable' || field.fe_type === 'search'"
        v-model="proxyModel"
        :name="field.name"
        :options="field.fe_type === 'list_searchable' ? field.options : null"
        :get-options="field.fe_type === 'search' ? getDynamicOptionsCallback() : null"
        :multiple="Boolean(field.multiple_values_allowed)"
        :include-none-listed-option="Boolean(field.mark_if_none_suitable)"
        :label="field.fe_label"
        :helper-text="field.fe_hint"
        :ui-required="field.fe_required || false"
        :is-valid="$attrs['is-valid']"
        :errors="$attrs.errors"
    />
    <date-picker
        v-else-if="field.fe_type === 'date_select' || field.fe_type === 'date-time_select'"
        v-model="proxyModel"
        :name="field.name"
        :mode="field.fe_type === 'date-time_select' ? 'dateTime' : 'date'"
        :min-date="
            field.minDate || (
                'minDateDynamic' in field && mappedStore[minDateDynamic]
                    ? mappedStore[minDateDynamic]
                    : undefined
            )
        "
        :max-date="
            field.maxDate || (
                'maxDateDynamic' in field && mappedStore[maxDateDynamic]
                    ? mappedStore[maxDateDynamic]
                    : undefined
            )
        "
        :label="field.fe_label"
        :ui-required="field.fe_required || false"
        :is-valid="$attrs['is-valid']"
        :errors="$attrs.errors"
    />
    <unsupported
        v-else
        :form-type="formType"
        :field-type="field.fe_type"
    />
</template>

<script>
import Vue from 'vue';
import makeWithVModelMixin from '@shared/mixins/with-v-model';
import { makeMappedStoreMixin } from '@/store/court-filing/utils/vuex-helpers';
import AutocompleteInput from '@/components/autocomplete/AutocompleteInput.vue';
import Checkbox from '@/components/checkbox/BaseCheckbox.vue';
import CheckboxGroup from '@/components/checkbox-group/CheckboxGroup.vue';
import DatePicker from '@/components/datepicker/VDatePicker.vue';
import RadioGroup from '@/components/radio-group/RadioGroup.vue';
import TextField from '@/components/text-field/TextField.vue';
import SelectBox from '@/components/select/SelectBox.vue';
import Unsupported from './UnsupportedField.vue';

export default Vue.extend({
    components: {
        AutocompleteInput,
        Checkbox,
        CheckboxGroup,
        DatePicker,
        RadioGroup,
        TextField,
        SelectBox,
        Unsupported,
    },

    mixins: [
        makeWithVModelMixin({
            prop: 'genericValue',
            event: 'genericInput',
        }),

        makeMappedStoreMixin(({ mapState }) => ({
            computed: {
                ...mapState(['formValues']),
            },
        })),
    ],

    props: {
        formType: {
            type: String,
            required: true,
        },
        field: {
            type: Object,
            required: true,
        },
        genericValue: {
            required: true,
        },
    },

    methods: {
        getDynamicOptionsCallback() {
            return (query) => {
                return this.$store.dispatch(
                    'courtFiling/fetchFormInputOptions',
                    { apiEndpoint: this.field.options_datasource, query },
                );
            };
        },
    },
});
</script>
