/* eslint-disable camelcase */
import { extend } from 'vee-validate';
import {
    email,
    alpha,
    alpha_num,
    alpha_spaces,
    integer,
    max,
    required,
    ext,
    max_value,
} from 'vee-validate/dist/rules';

extend('required', {
    ...required,
    message: 'Обязательное поле',
});

extend('email', {
    ...email,
    message: 'Неверный формат',
});
export const rules = {
    custom: {
        custom: true,
    },
};

extend('alpha', {
    ...alpha,
    message: 'Неверный формат. Могут быть использованы только буквы',
});

extend('alpha_num', {
    ...alpha_num,
    message: 'Неверный формат. Могут быть использованы только буквы и цифры',
});

extend('alpha_spaces', {
    ...alpha_spaces,
    message: 'Неверный формат. Могут быть использованы только буквы и пробелы',
});

extend('integer', {
    ...integer,
    message: 'Неверный формат. Могут быть использованы только целые числа',
});

extend('max', {
    ...max,
    message: 'Неверный формат',
});

extend('ext', {
    ...ext,
    message:
        'Неверный формат. Может быть использован только pdf, jpg, jpeg, png, mp4',
});

extend('unique', {
    params: ['target'],

    message: (fieldName, placeholders) => {
        // eslint-disable-next-line no-underscore-dangle
        return `Файл с именем ${placeholders._value_.name} уже существует`;
    },

    validate(value, { target }) {
        if (value.name === target) {
            return false;
        }

        if (Array.isArray(target)) {
            return !target.includes(value.name);
        }
        return true;
    },
});

extend('maxSize', {
    params: ['target'],
    message: 'Объем файлов не должен превышать 50 МБ',
    validate(value, { target }) {
        const sumOfExisitingFilesSize = Number(target[0]);
        const maxFileSize = Number(target[1]);
        if (
            Number(value.size) > maxFileSize
            || sumOfExisitingFilesSize > maxFileSize
            || Number(value.size) + sumOfExisitingFilesSize > maxFileSize
        ) {
            return false;
        }
        return true;
    },
});

extend('max_value', {
    ...max_value,
    message: 'Объем файлов не должен превышать 50 МБ',
});

extend('defined', {
    validate(value) {
        return value !== undefined;
    },
    message: 'Обязательное поле',
});
