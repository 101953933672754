<template>
    <div
        class="selector-block"
        @click="$emit('selector-click')"
    >
        <base-button
            :class="isActive ? 'button-selector__active' : ''"
            class="button--full-width button-selector"
        >
            <template #icon>
                <img
                    :src="icon"
                    width="60"
                    height="60"
                />
            </template>
            {{ title }}
        </base-button>
    </div>
</template>

<script>
import BaseButton from '@/components/button/BaseButton.vue';

export default {
    name: 'BaseTabsItem',
    components: {
        BaseButton,
    },
    props: {
        title: {
            type: String,
            default: 'Tab',
        },
        icon: {
            type: String,
            default: '',
        },
        isActive: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style lang="scss" scoped>
@use "@/styles/variables/grid";
@use "@/styles/variables/colors";

.selector-block {
    display: block;
    width: 100%;
}

.button-selector {
    cursor: pointer;
    background: colors.$white;
    border: 1px solid colors.$generaptor-brand-medium;
    height: grid.$space-10;
    color: colors.$ovdinfo-brown;
    font-size: grid.$space-3;
    font-weight: 500;
    line-height: grid.$space-4;
    letter-spacing: -0.02em;
    text-align: center;
    align-items: center;
}

.button-selector:hover {
    background: colors.$generaptor-brand-medium;
}

.button-selector__active {
    background: colors.$generaptor-brand-light;
}
</style>
