<template>
    <div class="form-field">
        <div class="form-field__label"></div>
        <div class="form-field__selector-wrapper">
            <label class="form-field__selector checkbox">
                <input
                    v-model="proxyModel"
                    type="checkbox"
                    :name="name"
                    :value="value"
                    :disabled="disabled"
                    class="checkbox__input"
                />
                <span
                    class="form-field__selector-label"
                    :class="hideLabel && 'u-hidden-visually'"
                >{{ label }}</span>
                <span
                    v-if="uiRequired"
                    class="required"
                >*</span>
            </label>
            <div
                v-if="hasHelper"
                class="form-field__helper-wrapper"
            >
                <form-input-helper
                    :link-url="helperLinkUrl"
                    :link-text="helperLinkText"
                >
                    {{ helperText }}
                </form-input-helper>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import makeWithVModelMixin from '@shared/mixins/with-v-model';
import FormInputHelper from '@/components/FormInputHelper.vue';

/* interface State {
    proxyModel: string | boolean;
} */

/**
 * Использование с `v-model` в родительском компоненте:
 *
 * (1) если модель - массив:
 * на чтение: если массив содержит значение, совпадающее с атрибутом `value`,
 * чекбокс становится выбранным, в противном случае - невыбранным
 * на запись: при выборе чекбокса значение его атрибута `value` добавляется в массив,
 * при снятии - удаляется
 * (2) если модель другого типа:
 * на чтение: если модель truthy, чекбокс становится выбранным, в противном случае - невыбранным
 * на запись: при выборе чекбокса в модель записывается значение true, при снятии - false
 *
 * (т.е. интерфейс тот же, что и у нативного <input type="checkbox" />)
 */
export default Vue.extend({
    components: {
        FormInputHelper,
    },

    mixins: [
        makeWithVModelMixin({
            prop: 'checked',
            event: 'change',
        }),
    ],

    props: {
        name: {
            type: String,
        },
        value: {},
        checked: {},
        uiRequired: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        label: {
            type: String,
            default: '',
        },
        hideLabel: {
            type: Boolean,
            default: false,
        },
        helperText: {
            type: String,
            default: '',
        },
        helperLinkUrl: {
            type: String,
            default: '',
        },
        helperLinkText: {
            type: String,
            default: '',
        },
    },

    computed: {
        hasHelper(): boolean {
            return (
                !!this.helperText || (this.helperLinkUrl && this.helperLinkText)
            );
        },
    },
});
</script>

<style lang="scss" scoped>
@use "@/styles/variables/colors";

.checkbox__input {
    position: relative;
    top: 0;
    left: 0;
    width: 32px;
    height: 32px;
    border: 0;
    flex-shrink: 0;
    cursor: pointer;
    appearance: none;
}

.checkbox__input::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 32px;
    height: 32px;
    border: 1px solid colors.$border;
    border-radius: 6px;
}

.checkbox__input:checked::before {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24'%3E%3Cpath fill='currentColor' d='m9.348 17.834.177.177.177-.177v-.001l1.414-1.413L19.6 7.934l.177-.177-.177-.177-1.414-1.414-.177-.177-.177.177-8.307 8.307-3.36-3.357-.177-.176-.177.176-1.414 1.414-.177.177.177.177 4.95 4.95z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: center;
}
</style>
