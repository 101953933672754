<template>
    <p
        class="ui-paragraph"
        :class="{
            'ui-paragraph--pale': pale,
            'ui-paragraph--center': align === 'center',
        }"
    >
        <slot />
    </p>
</template>

<script lang="ts">
import Vue from 'vue';

export default Vue.extend({
    props: {
        pale: {
            type: Boolean,
            default: false,
        },
        align: {
            type: String,
            default: 'left',
        },
    },
});
</script>

<style lang="scss" scoped>
@use "@/styles/mixins/breakpoint" as bp;
@use "@/styles/variables/colors";
@use "@/styles/variables/typography" as tp;

.ui-paragraph {
    color: colors.$secondary-grey;
    font-family: tp.$font-family;
    font-weight: 400;

    @include bp.mobile-md {
        font-size: tp.$font-size-normal;
        line-height: 24px;
    }

    @include bp.desktop-md {
        // TODO: возможно, надо переопределять значение $font-size-normal для десктопа
        font-size: 20px;
        line-height: 28px;
    }

    &.ui-paragraph--pale {
        color: colors.$grey-5;
    }

    &.ui-paragraph--center {
        text-align: center;
    }

    em {
        font-weight: 500;
        font-style: normal;
    }
}
</style>
