/**
 * Для случаев, когда куда-то нужно передать нечто store-like,
 * чтобы там, где оно будет использоваться, не было (неявной) зависимости от неймспейса
 * @param {Object} store - объект с интерфейсом как у Vuex store
 * @param {String} namespace - неймспейс нужного модуля в `store`
 */
export function extractStoreModule(store, namespace) {
    return {
        state: store.state[namespace],

        getters: Object.keys(store.getters)
            .reduce((nsGetters, key) => {
                const [ns, nsKey] = key.split('/');
                /* оставляем только геттеры из нужного модуля, используя ключи без неймспейса */
                if (ns === namespace) {
                    Object.defineProperty(
                        nsGetters,
                        nsKey,
                        Object.getOwnPropertyDescriptor(store.getters, key),
                    );
                }
                return nsGetters;
            }, {}),

        commit: (mutation, payload, options) => {
            return store.commit(`${namespace}/${mutation}`, payload, options);
        },

        dispatch: (action, payload) => {
            return store.dispatch(`${namespace}/${action}`, payload);
        },
    };
}
