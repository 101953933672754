import Vue from 'vue';
import Router from 'vue-router';
import Home from '@/pages/Home.vue';
import Appeal from '@/pages/Appeal.vue';
import CourtFiling from '@/pages/court-filings/CourtFiling.vue';
import CourtMotions from '@/pages/court-filings/CourtMotions.vue';
import FirstCassation from '@/pages/court-filings/FirstCassation.vue';
import EmailConfirmation from '@/components/auth/EmailConfirmation.vue';

Vue.use(Router);
const mainTitle = document.title;

function setTitle(to, from, next) {
    const title = (
        to.meta?.title
        /* @todo Рекурсивно обходить роуты верхнего уровня? */
        || (to.matched.length > 1 ? to.matched[to.matched.length - 2].meta?.title : null)
    );
    if (title) {
        document.title = title;
    }
    next();
}

const router = new Router({
    mode: 'history',

    routes: [
        {
            name: 'home',
            path: '/',
            meta: {
                title: mainTitle,
            },
            component: Home,
        },
        {
            name: 'appeal',
            path: '/filing/appeal-court',
            meta: {
                title: `Апелляция для всех задержанных в ходе протестов | ${mainTitle}`,
            },
            component: Appeal,
        },
        {
            name: 'courtFiling',
            path: '/filing/',
            component: CourtFiling,
            children: [
                {
                    path: 'court-motions',
                    component: CourtMotions,
                },
                {
                    path: 'court-of-cassation',
                    component: FirstCassation,
                },
            ],
            meta: {
                title: `Заполнение анкеты | ${mainTitle}`,
            },
        },
        {
            name: 'emailConfirmation',
            path: '/email_confirmation',
            meta: {
                title: `Вход | ${mainTitle}`,
            },
            component: EmailConfirmation,
        },
    ],

    scrollBehavior() {
        return { x: 0, y: 0 };
    },
});

router.beforeEach(setTitle);

export default router;
