import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import { extractStoreModule } from '@shared/store/helpers';
import config from '@/config';
import { makeStore as makeAuthStore, interceptClient } from './auth';
import { makeStore as makeABTestsStore } from './ab-tests';
import { makeStore as makeTrackingStore } from './tracking';
import { makeStore as makeAppealStore } from './appeal';
import { makeStore as makeCourtFilingStore } from './court-filing';

const client = axios.create({
    baseURL: config.apiUrl,
});

const AUTH_NS = 'auth';

Vue.use(Vuex);

export const store = new Vuex.Store({
    modules: {
        [AUTH_NS]: {
            ...makeAuthStore({ client }),
            namespaced: true,
        },

        abTests: {
            ...makeABTestsStore({ client }),
            namespaced: true,
        },

        tracking: {
            ...makeTrackingStore(),
            namespaced: true,
        },

        appeal: {
            ...makeAppealStore({ client }),
            namespaced: true,
        },
        courtFiling: {
            ...makeCourtFilingStore({ client }),
            namespaced: true,
        },
    },
});

/* см. комментарий о назначении функции в `@shared/store/auth` */
interceptClient({ client, store: extractStoreModule(store, AUTH_NS) });
