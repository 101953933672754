<template>
    <autocomplete
        v-bind="$attrs"
        v-model="proxyModel"
        :errors="validationErrors"
    />
</template>

<script lang="ts">
import Vue from 'vue';
import makeWithVModelMixin from '@shared/mixins/with-v-model';
import { errorMessages } from '@shared/validation/constants';
import validationErrorsMixin from '@shared/validation/mixins/ui-errors';
import Autocomplete from './BaseAutocomplete.vue';

export default Vue.extend({
    components: {
        Autocomplete,
    },

    mixins: [
        makeWithVModelMixin({ event: 'change' }),
        validationErrorsMixin,
    ],

    props: {
        value: {},
        errors: {
            type: Array,
            default: () => [],
        },
    },

    methods: {
        /**
         * @param {ajv.ErrorObject} error
         */
        getValidationErrorMessage(error) {
            /*
            т.к. опции селекта определены заранее,
            любая *выбранная* опция считается заведомо валидной,
            а невалидным может быть только отсутствие значения
            */
            if (
                error.keyword === 'required'
                /* когда `multiple=false` и значение не выбрано */
                || /^#\/\$defs\/nonEmptyString/.test(error.schemaPath)
                /* когда `multiple=true` и ни одно значение не выбрано */
                || /^#\/\$defs\/nonEmptyList/.test(error.schemaPath)
            ) {
                return errorMessages.required();
            }
            return errorMessages.fallback();
        },
    },
});
</script>
