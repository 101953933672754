import Vue from 'vue';
import { isQAenv } from '@shared/utils/is-qa-env';
import hardcodedVocabs from './hardcoded-vocabs';
import dependentRules from './rules';
import { getDefaultFieldValue, resetField } from './utils';

/**
 * Создает модуль Vuex store
 * @param {Object} client - объект с интерфейсом как у Axios client
 * @returns {Object} объект, пригодный для использования в качестве модуля Vuex store
 */
export const makeStore = ({ client }) => ({
    state: {
        fields: {},
        errors: {},
        formIsSending: false,
        formIsSent: false,
        formIsFailed: false,
        formIsLoading: true,
        formLoadingIsFailed: false,
        vocabs: {
            ...hardcodedVocabs,
        },
        states: {},
    },

    getters: {
        validationErrors(state) {
            return state.errors.validation_error;
        },
        hasValidationErrors(state, getters) {
            return getters.validationErrors
                ? getters.validationErrors.length
                : false;
        },
    },

    mutations: {
        setFormIsSending(state, payload) {
            state.formIsSending = payload;
        },

        setFormIsSent(state, payload) {
            state.formIsSent = payload;
        },

        setFormIsFailed(state, payload) {
            state.formIsFailed = payload;
        },

        setFormIsLoading(state, payload) {
            state.formIsLoading = payload;
        },

        setFormLoadingIsFailed(state, payload) {
            state.formLoadingIsFailed = payload;
        },

        setFormValues(state, payload) {
            Vue.set(state.fields, payload.name, payload.value);

            /* сбрасываем зависимые поля при необходимости */
            (dependentRules[payload.name] || []).forEach(({ match, mutate }) => {
                if (match(payload.value, state)) {
                    mutate(state);
                }
            });
        },

        setStoreValues(state, payload) {
            Vue.set(state[payload.storeValue], payload);
        },

        cleanValues(state, name) {
            resetField(state, { fieldName: name });
        },

        setError(state, payload) {
            Vue.set(state.errors, payload.storeValue, payload.error);
        },

        setFormDef(state, schema) {
            state.states = Object.freeze(schema);
        },

        initForm(state) {
            /* предзаполняем форму дефолтными значениями */
            const formData = Object.keys(state.states).reduce(
                (fd, key) => {
                    const field = state.states[key];
                    const defaultValue = getDefaultFieldValue({
                        formDef: state.states, fieldName: key,
                    });
                    if (defaultValue !== undefined) {
                        /* eslint-disable-next-line no-param-reassign */
                        fd[field.name] = defaultValue;
                    }
                    return fd;
                },
                {},
            );
            state.fields = formData;
        },
    },

    actions: {
        async sendData({ state, commit }) {
            commit('setFormIsSending', true);
            commit('setFormIsSent', false);
            commit('setFormIsFailed', false);
            // TODO need refactor headers -> preset it globally
            try {
                await client.post(
                    'application/fill/appeal',
                    { body: state.fields },
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            'Access-Control-Allow-Origin': '*',
                        },
                    },
                );
                commit('setFormIsSent', true);
            } catch (error) {
                commit('setFormIsFailed', true);
                commit('setError', {
                    storeValue: error.response.data.status,
                    error: error.response.data.fields,
                });
                throw error;
            } finally {
                commit('setFormIsSending', false);
            }
        },

        async fetchOptions(context, { query, vocab }) {
            const { data } = await client.get(`vocab/${vocab}`, {
                params: {
                    name__icontains: query,
                },
            });
            return data;
        },

        async fetchFormContent({ state, commit }) {
            if (Object.keys(state.states).length > 0) {
                return;
            }

            commit('setFormIsLoading', true);
            try {
                // const { data } = await client.get('application/list');
                const data = await import(
                    /* webpackChunkName: 'appeal-form-fields' */
                    './form-fields.json'
                );

                commit('setFormDef', Object.freeze(data.default));
                commit('initForm', data);
            } catch (error) {
                commit('setFormLoadingIsFailed', true);
                throw error;
            } finally {
                commit('setFormIsLoading', false);
            }
        },

        bootstrapForm({ dispatch }) {
            return dispatch('fetchFormContent');
        },

        resetForm({ state, commit }) {
            if (state.formIsLoading) {
                return;
            }

            commit('setFormIsSent', false);
            commit('setFormIsFailed', false);
            // commit('initForm');
        },

        prefillFormForQA({ state }) {
            if (!isQAenv()) {
                return;
            }
            /* eslint-disable-next-line no-alert */
            const qaValues = prompt('Вставьте значения для предзаполнения формы');
            if (qaValues) {
                state.fields = { ...state.fields, ...JSON.parse(qaValues) };
            }
        },
    },
});
