<template>
    <div class="hero">
        <div class="container hero__container">
            <div class="hero__content">
                <h1 class="hero__heading">
                    <span class="hero__heading-accent">Генераптор</span>&nbsp;—
                    документы для&nbsp;суда за&nbsp;10&nbsp;минут
                </h1>
                <div class="hero__text">
                    <p>
                        Я&nbsp;— генератор судебных документов, и я помогу вам
                        подготовиться к&nbsp;суду. Моя задача&nbsp;— сделать процесс защиты
                        в&nbsp;суде максимально простым и быстрым.
                    </p>
                    <p>
                        Я могу помочь, если вас преследуют за&nbsp;публикацию/высказывание
                        (пост, репост, комментарий, высказывание) или если вас задержали
                        на&nbsp;акции (митинг, пикет, шествие).
                    </p>
                </div>
                <base-button
                    :full-width="isMobile"
                    :data-gtm="JSON.stringify({
                        buttonAction: 'scrollDown',
                        itemLocation: 'Верхний баннер',
                    })"
                    @click="onCtaClick"
                >
                    Перейти к документам
                </base-button>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { ViewportBreakpoints } from '@/constants';
import BaseButton from '@/components/button/BaseButton.vue';

export default Vue.extend({
    components: {
        BaseButton,
    },
    props: {
    },
    data() {
        return {
            mql: window.matchMedia(`(max-width: ${ViewportBreakpoints.Sm}px)`),
            isMobile: false,
        };
    },
    created() {
        this.isMobile = this.checkMobile();
    },
    mounted() {
        this.mql.addEventListener('change', this.onScreenChange);
    },
    beforeDestroy() {
        this.mql.removeEventListener('change', this.onScreenChange);
    },
    methods: {
        onCtaClick() {
            this.$emit('ctaclick');
        },
        checkMobile() {
            return window.innerWidth <= ViewportBreakpoints.Sm;
        },
        onScreenChange(e) {
            if (e.matches) {
                this.isMobile = true;
            } else {
                this.isMobile = false;
            }
        },
    },
});
</script>

<style lang="scss" scoped>
@use "@/styles/variables/colors";
@use "@/styles/variables/grid";
@use "@/styles/mixins/breakpoint" as *;

.hero {
    display: flex;
    background-color: var(--bg-color);
    min-height: 500px;
    padding-bottom: grid.$space-3;
    background-image: url("@/images/hero.png");
    background-repeat: no-repeat;
    background-position-x: right;
    background-size: auto 100%;

    @include breakpoint(lg) {
        background-position-x: calc(100% + 240px);
    }

    @include breakpoint(md) {
        min-height: 398px;
        background-position-x: calc(100% + 252px);
    }

    @include breakpoint(sm) {
        padding-top: 174px;
        padding-bottom: 30px;
        background-position-x: right;
        background-position-y: top;
        background-size: auto 174px;
    }
}

.hero__container {
    position: relative;
    padding-top: 100px;

    @include breakpoint(md) {
        padding-top: 24px;
    }

    @include breakpoint(sm) {
        padding-top: 12px;
    }
}

.hero__container::before {
    content: "";
    position: absolute;
    bottom: -20px;
    left: calc((280px + 180px) * -1);
    width: 280px;
    height: 300px;
    background-image: url("@/images/hero-decoration.png");
    background-repeat: no-repeat;
    background-size: cover;

    @include breakpoint(lg) {
        display: none;
    }
}

.hero__content {
    max-width: 590px;

    @include breakpoint(md) {
        max-width: 328px;
    }

    @include breakpoint(sm) {
        max-width: 100%;
    }
}

.hero__heading {
    font-size: 48px;
    line-height: 1.2;
    margin-bottom: 16px;
    color: colors.$secondary-grey;

    @include breakpoint(md) {
        margin-bottom: 8px;
        font-size: 36px;
        letter-spacing: -0.02em;
    }

    @include breakpoint(sm) {
        margin-bottom: 12px;
    }
}

.hero__heading-accent {
    color: colors.$generaptor-brand-dark;
}

.hero__text {
    margin-bottom: 56px;
    font-size: 18px;
    line-height: 1.45;
    color: colors.$secondary-grey;

    @include breakpoint(md) {
        margin-bottom: 32px;
    }

    @include breakpoint(sm) {
        margin-bottom: 40px;
    }
}
</style>
