import Cleave from 'cleave.js';

/**
 * Миксин для общего поведения `DatePickerInner` и `TimePickerInner`
 * @param {Object} options.inputMaskSettings - настройки Cleave
 */
export function makeDateTimeUnitMixin({ inputMaskSettings }) {
    return {
        props: {
            value: {
                type: Number,
                // required: true,
            },
            isValueSet: {
                type: Boolean,
                default: false,
            },
            /*
            кроме прямого назначения, используется также для определения,
            полностью ли введено значение в инпут даты/времени
            */
            placeholder: {
                type: String,
                required: true,
            },
            /* @todo? Применяется только к инпуту, к v-calendar нет */
            disabled: {
                type: Boolean,
                required: true,
            },
            /* @todo? Применяется только к инпуту, к v-calendar нет */
            readonly: {
                type: Boolean,
                required: true,
            },
            isValid: {
                type: Boolean,
                default: true,
            },
            errors: {
                type: Array,
                default: () => ([]),
            },
            datePickerSettings: {
                type: Object,
                default: () => ({}),
            },
        },

        data() {
            return {
                /*
                комбинация полей для сохранения недописанного значения в инпуте,
                когда из него уходит факус
                (иначе v-calendar сбрасывает значение до предыдущего)
                */
                rawInputValue: '',
                isUserTyping: false,
            };
        },

        watch: {
            /** Сбрасывает флаг при обновлении модели из родителя */
            value: {
                handler() {
                    this.isUserTyping = false;
                },
                immediate: true,
            },
        },

        methods: {
            /**
             * @param {Function} defaultHandler - обработчик `inputEvents.input`
             *   или `inputEvents.change`
             *   (см. https://vcalendar.io/datepicker/slot-content.html)
             */
            onChange(ev, defaultHandler) {
                this.isUserTyping = true;
                this.rawInputValue = ev.target.value;

                /*
                Cleave отбрасывает возможные невалидные символы, но на момент вызова обработчика
                они еще есть, поэтому откладываем обработку события до следующего тика
                */
                setTimeout(() => {
                    const { value } = ev.target;
                    this.rawInputValue = value;

                    /*
                    v-calendar создает дату-время из *любого* введенного значения,
                    что приводит к неочевидным подстановкам, если печатать недостаточно быстро
                    (например, 2 заменяется на 31.01.2001);
                    поэтому вызываем обработчик библиотеки только при реальной необходимости
                    */
                    if (
                        /* значение стерто */
                        value.length === 0
                        /* значение введено полностью */
                        || value.length === this.placeholder.length
                    ) {
                        defaultHandler(ev);
                    }
                }, 0);
            },
        },

        mounted() {
            /* форматирование даты и времени в инпутах по маске */
            this._inputMask = new Cleave(this.$refs.inputNode, inputMaskSettings);
        },

        beforeDestroy() {
            this._inputMask.destroy();
            delete this._inputMask;
        },
    };
}
