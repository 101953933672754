<template>
    <court-filing :form-type="formType">
        <template #heading>
            Анкета для формирования ходатайств в суды
        </template>

        <template #submit-hint>
            После отправки формы на вашу почту придут автоматически сгенерированные ходатайства.
            Если вам потребуется помощь с редактированием,
            напишите нашим юристам на&nbsp;<email-us />
        </template>

        <template #result-addendum>
            Вы также можете <a href="https://docs.google.com/document/export?format=docx&id=1IGRAsZKzpyJ3RX6lWwCnfmqDFyyLAE-E0vO1UGt8e34">скачать шаблоны ходатайств</a>
            и заполнить их вручную.
        </template>
    </court-filing>
</template>

<script>
import Vue from 'vue';
import { COURT_FILING_TYPES } from '@/store/court-filing/constants';
import CourtFiling from '@/components/forms/CourtFiling.vue';
import EmailUs from '@/components/mail-to/EmailOILegal.vue';

export default Vue.extend({
    components: {
        CourtFiling,
        EmailUs,
    },

    data() {
        return {
            formType: COURT_FILING_TYPES.MOTIONS,
        };
    },
});
</script>
