<template>
    <ValidationProvider
        v-slot="{ errors }"
        :rules="rules"
        slim
    >
        <date-picker
            v-bind="$attrs"
            v-model="proxyModel"
            :ui-required="required || requiredProp"
            :errors="mapErrors(errors)"
            :is-valid="errors.length === 0"
        />
    </ValidationProvider>
</template>

<script lang="ts">
import Vue from 'vue';
import makeWithVModelMixin from '@shared/mixins/with-v-model';
import DatePicker from './DatePicker.vue';

/** @deprecated */
export default Vue.extend({
    components: {
        DatePicker,
    },

    mixins: [
        makeWithVModelMixin({ event: 'change' }),
    ],

    props: {
        value: {
            type: Number,
            // required: true,
        },
        required: {
            type: Boolean,
            default: false,
        },
        rules: {
            type: String,
            default: '',
        },
    },

    computed: {
        requiredProp(): Boolean {
            return this.rules?.includes('required');
        },
    },

    methods: {
        mapErrors(errors) {
            return errors.map((err) => {
                return {
                    message: err,
                    type: err === 'Обязательное поле' ? 'REQUIRED_MISSING' : 'UNKNOWN',
                };
            });
        },
    },
});
</script>
