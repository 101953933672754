<template>
    <div class="explainer">
        <h2 class="h1 explainer__headline">
            {{ headline }}
        </h2>
        <p class="explainer__description">
            {{ description }}
        </p>

        <div v-if="isMobile">
            <div
                v-if="slider"
                class="dots"
            >
                <button
                    v-for="(slide, idx) in dots"
                    :key="idx"
                    :class="{ dot: true, active: current === idx }"
                    @click="slider.moveToSlideRelative(idx)"
                >
                    {{ idx + 1 }}
                </button>
            </div>

            <div class="navigation-wrapper">
                <div
                    ref="slider"
                    class="keen-slider"
                >
                    <div
                        v-for="step in steps"
                        :key="step.descriptor"
                        class="keen-slider__slide"
                    >
                        <div class="explainer__step-illustration">
                            <img
                                :src="step.image"
                                :alt="step.imageAlt"
                            />
                        </div>
                        <p class="explainer__step-title">
                            <span class="explainer__step-title-text">
                                {{ step.title }}
                            </span>
                        </p>
                        <p
                            class="explainer__step-descriptor"
                            v-html="step.descriptor"
                        />
                    </div>
                </div>
            </div>
        </div>
        <div
            v-if="!isMobile"
            class="explainer__steps"
        >
            <div
                v-for="(step, index) in steps"
                :key="step.title"
                class="explainer__step"
            >
                <div class="explainer__step-illustration">
                    <img
                        :src="step.image"
                        :alt="step.imageAlt"
                    />
                </div>
                <p class="explainer__step-title">
                    <span class="explainer__step-number">
                        {{ index + 1 }}
                    </span>
                    <span class="explainer__step-title-text">
                        {{ step.title }}
                    </span>
                </p>
                <p
                    class="explainer__step-descriptor"
                    v-html="step.descriptor"
                />
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import 'keen-slider/keen-slider.min.css';
import KeenSlider from 'keen-slider';
import { ViewportBreakpoints } from '@/constants';

export default Vue.extend({
    props: {
        headline: {
            type: String,
            default: '',
        },
        description: {
            type: String,
            default: '',
        },
        steps: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            mql: window.matchMedia(`(max-width: ${ViewportBreakpoints.Sm}px)`),
            isMobile: false,
            current: 0,
            slider: null,
        };
    },

    computed: {
        dots() {
            return this.slider
                ? [...Array(this.slider.details().size).keys()]
                : [];
        },
    },

    created() {
        this.isMobile = this.checkMobile();
    },
    mounted() {
        this.mql.addEventListener('change', this.onScreenChange);

        if (this.isMobile) {
            this.initCarousel();
        }
    },
    beforeDestroy() {
        this.mql.removeEventListener('change', this.onScreenChange);
        if (this.slider) this.slider.destroy();
    },

    methods: {
        onScreenChange(e) {
            if (e.matches) {
                this.isMobile = true;
                Vue.nextTick().then(() => {
                    this.initCarousel();
                });
            } else {
                this.isMobile = false;
                Vue.nextTick(() => {
                    this.destroyCarousel();
                });
            }
        },
        checkMobile() {
            return window.innerWidth <= ViewportBreakpoints.Sm;
        },
        initCarousel() {
            this.slider = new KeenSlider(this.$refs.slider, {
                initial: this.current,
                spacing: 32,
                slideChanged: (s) => {
                    if (Number.isNaN(s.details().relativeSlide)) {
                        return;
                    }
                    this.current = s.details().relativeSlide;
                },
            });
        },
        destroyCarousel() {
            this.slider.destroy();
            this.current = 0;
        },
    },
});
</script>

<style lang="scss" scoped>
@use "@/styles/mixins/breakpoint" as *;
@use "@/styles/variables/colors";
@use "@/styles/variables/grid";

.explainer__headline {
    margin-bottom: grid.$space-8;

    @include breakpoint(md) {
        margin-bottom: 15px;
    }
}

.explainer__description {
    margin-bottom: grid.$space-4;
}

.explainer__steps {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 64px 90px;

    @include breakpoint(md) {
        grid-gap: 52px;
    }

    @include breakpoint(sm) {
        display: block;
    }
}

.explainer__step {
    @include breakpoint(sm) {
        margin-bottom: grid.$space-8;
    }
}

.explainer__step-illustration {
    margin-bottom: grid.$space-5;
    border: 1px solid var(--primary-color-alpha);
    border-radius: grid.$space-1;
    overflow: hidden;
    box-shadow: 0 9px 15px rgba(178 181 190 / 29%);

    img {
        display: block;
        max-height: 195px;
        max-width: 100%;
        margin: auto;
    }

    @include breakpoint(md) {
        margin-bottom: grid.$space-2;
    }
}

.explainer__step-title {
    position: relative;
    padding-left: 57px;
    margin-bottom: 11px;

    @include breakpoint(md) {
        margin-bottom: 8px;
        padding-left: 0;
    }
}

.explainer__step-number {
    position: absolute;
    left: 0;
    top: calc(-72px / 4);
    width: 47px;
    height: 72px;
    color: colors.$accent;
    font-size: 72px;
    font-weight: 500;
    line-height: 72px;
    text-align: center;
    opacity: 0.2;

    @include breakpoint(md) {
        display: none;
    }
}

.explainer__step-title-text {
    font-size: grid.$space-3;
    color: colors.$accent;
    letter-spacing: -0.03em;
    font-weight: 500;
}

.explainer__step-descriptor {
    padding-left: 57px;
    color: colors.$secondary-grey;
    line-height: 1.5;

    @include breakpoint(md) {
        padding-left: 0;
    }
}

.navigation-wrapper {
    position: relative;
    padding-right: grid.$space-6;
}

.keen-slider {
    overflow: visible;
}

.dots {
    display: flex;
    padding: 10px 0;
}

.dot {
    position: relative;
    padding: 0;
    margin: 0;
    margin-right: 24px;
    width: 22px;
    height: 22px;
    flex-shrink: 0;
    background: colors.$white;
    border-radius: 50%;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    color: colors.$generaptor-brand;
    border: 1px solid colors.$generaptor-brand;
    font-size: 12px;
    appearance: none;
}

.dot:last-child {
    margin: 0;
}

.dot:not(:last-child)::after {
    content: "";
    position: absolute;
    width: 24px;
    height: 2px;
    background-color: rgba(132 165 120 / 6%);
    top: calc(50% - 1px);
    left: calc(100% + 1px);
}

.dot:focus {
    outline: none;
}

.dot.active {
    background: colors.$generaptor-brand;
    color: colors.$white;
}
</style>
