<template>
    <a
        :href="`mailto:${email}`"
        @click="trackClick($event, 'externalLinkClick')"
    >{{ email }}</a>
</template>

<script>
import Vue from 'vue';
import clickTrackingMixin from '@/mixins/click-tracking';

export default Vue.extend({
    mixins: [clickTrackingMixin],

    props: {
        email: {
            type: String,
            required: true,
        },
    },
});
</script>
