import { StoreError } from '@shared/utils/store-error';
import { isQAenv } from '@shared/utils/is-qa-env';
import { castType } from './utils/type-casting';
import { COURT_FILING_TYPES as FILING_TYPES } from './constants';

const LOADING_STATUS = {
    INITIAL: null,
    LOADING: 'LOADING',
    SUCCESS: 'SUCCESS',
    FAILURE: 'FAILURE',
};

const STORE_SLICE_TO_ENDPOINT = {
    [FILING_TYPES.MOTIONS]: 'petitions',
    [FILING_TYPES.CASSATION]: 'first-cassation',
};

const getSchemaEndpoint = (type) => `/templates/${STORE_SLICE_TO_ENDPOINT[type]}/schema`;

const getDataEndpoint = (type) => `/templates/${STORE_SLICE_TO_ENDPOINT[type]}/fill`;

/**
 * Создает пустой фрагмент стейта, соответствующий форме определенного типа
 */
const initStoreSlice = () => {
    return {
        schema: LOADING_STATUS.INITIAL,
        formValues: {},
        formStatus: LOADING_STATUS.INITIAL,
    };
};

/**
 * Возвращает дефолтное значение поля, как оно определено в описании формы
 * @param {Object} formDef — объект, описывающий структуру формы (ключ `generaptor` в схеме)
 * @param {String} field — название поля
 * @returns {*}
 */
const getDefaultFieldValue = ({ formDef, field }) => {
    const fieldDef = formDef.fields[field];
    return ('default' in fieldDef) ? fieldDef.default : null;
};

/**
 * Преобразует тип значения поля согласно описанию формы
 * @param {Object} formDef — объект, описывающий структуру формы (ключ `generaptor` в схеме)
 * @param {String} field — название поля
 * @param {*} value — "сырое" значение поля
 * @returns {*}
 */
const castFieldType = ({ formDef, field, value }) => {
    const fieldDef = formDef.fields[field];
    return ('cast_type' in fieldDef) ? castType(value, fieldDef.cast_type) : value;
};

/**
 * Создает модуль Vuex store
 * @param {Object} client - объект с интерфейсом как у Axios client
 * @returns {Object} объект, пригодный для использования в качестве модуля Vuex store
 */
export const makeStore = ({ client }) => {
    return {
        /*
        стейт состоит из однотипных вложенных объектов,
        каждый из которых соответствует форме/шаблону определенного вида
        */
        state: {
            [FILING_TYPES.MOTIONS]: initStoreSlice(),
            [FILING_TYPES.CASSATION]: initStoreSlice(),
        },

        /*
        все геттеры в этом сторе, которые будут вызываться компонентами
        через `createHelpers`/`makeMappedStoreMixin` (см. helpers.js),
        должны возвращать функцию от `filingType`
        */
        getters: {
            isSchemaPending(state) {
                return (filingType) => {
                    const { schema } = state[filingType];
                    return (
                        schema === LOADING_STATUS.INITIAL
                        || schema === LOADING_STATUS.LOADING
                    );
                };
            },

            isSchemaReady(state) {
                return (filingType) => {
                    const { schema } = state[filingType];
                    return (
                        schema !== LOADING_STATUS.INITIAL
                        && schema !== LOADING_STATUS.LOADING
                        && schema !== LOADING_STATUS.FAILURE
                    );
                };
            },

            isFormPending(state) {
                return (filingType) => {
                    const { formStatus } = state[filingType];
                    return formStatus === LOADING_STATUS.LOADING;
                };
            },

            isFormSubmitted(state) {
                return (filingType) => {
                    const { formStatus } = state[filingType];
                    return formStatus === LOADING_STATUS.SUCCESS;
                };
            },

            isFormFailed(state) {
                return (filingType) => {
                    const { formStatus } = state[filingType];
                    return formStatus === LOADING_STATUS.FAILURE;
                };
            },
        },

        mutations: {
            schema(state, { filingType, schema }) {
                state[filingType].schema = schema;
            },

            formValue(state, { filingType, field, value }) {
                const { generaptor: formDef } = state[filingType].schema;
                state[filingType].formValues[field] = castFieldType({ formDef, field, value });
            },

            formData(state, { filingType, data }) {
                state[filingType].formValues = data;
            },

            schemaStatus(state, { filingType, status }) {
                state[filingType].schema = status;
            },

            formStatus(state, { filingType, status }) {
                state[filingType].formStatus = status;
            },
        },

        actions: {
            /**
             * Заполняет форму дефолтными значениями
             */
            initForm({ state, commit }, { filingType }) {
                const { generaptor: formDef } = state[filingType].schema;
                const formData = Object.keys(formDef.fields).reduce(
                    (fd, field) => {
                        const value = getDefaultFieldValue({ formDef, field });
                        /* eslint-disable-next-line no-param-reassign */
                        fd[field] = value;
                        return fd;
                    },
                    {},
                );
                commit('formStatus', { filingType, status: LOADING_STATUS.INITIAL });
                commit('formData', { filingType, data: formData });
            },

            async loadSchema({ getters, commit, dispatch }, { filingType }) {
                if (getters.isSchemaReady(filingType)) {
                    return;
                }

                commit('schemaStatus', { filingType, status: LOADING_STATUS.LOADING });
                let schema;
                try {
                    ({ data: schema } = await client.get(getSchemaEndpoint(filingType)));
                } catch (error) {
                    commit('schemaStatus', { filingType, status: LOADING_STATUS.FAILURE });
                    throw StoreError.create(
                        `Unable to fetch schema for \`${filingType}\``,
                        error,
                    );
                }

                try {
                    /*
                    `Object.freeze` для того, чтобы Vue не делал объект схемы реактивным
                    почем зря
                    */
                    commit('schema', { filingType, schema: Object.freeze(schema) });
                    dispatch('initForm', { filingType });
                } catch (error) {
                    commit('schemaStatus', { filingType, status: LOADING_STATUS.FAILURE });
                    throw StoreError.create(
                        `Unable to save schema for \`${filingType}\` in store`,
                        error,
                    );
                }
            },

            /**
             * Сбрасывает состояние формы на начальное (оставляя данные)
             */
            resetForm({ getters, commit }, { filingType }) {
                if (getters.isSchemaPending(filingType)) {
                    return;
                }

                commit('formStatus', { filingType, status: LOADING_STATUS.INITIAL });
            },

            async submitForm({ state, commit }, { filingType }) {
                commit('formStatus', { filingType, status: LOADING_STATUS.LOADING });
                try {
                    await client.post(
                        getDataEndpoint(filingType),
                        state[filingType].formValues,
                    );
                    commit('formStatus', { filingType, status: LOADING_STATUS.SUCCESS });
                } catch (error) {
                    commit('formStatus', { filingType, status: LOADING_STATUS.FAILURE });
                    throw StoreError.create(
                        `Unable to submit user data for \`${filingType}\``,
                        error,
                    );
                }
            },

            async fetchFormInputOptions(_, { apiEndpoint, query }) {
                try {
                    const { data } = await client.get(apiEndpoint, {
                        params: {
                            name__icontains: query,
                        },
                    });
                    return data;
                } catch (error) {
                    throw StoreError.create('Unable to fetch dynamic options', error);
                }
            },

            prefillFormForQA({ state, commit }, { filingType }) {
                if (!isQAenv()) {
                    return;
                }
                /* eslint-disable-next-line no-alert */
                const qaValues = prompt('Вставьте значения для предзаполнения формы');
                if (qaValues) {
                    commit('formData', {
                        filingType,
                        data: { ...state[filingType].formValues, ...JSON.parse(qaValues) },
                    });
                }
            },
        },
    };
};
