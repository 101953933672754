<template>
    <div class="formular__result">
        <h1>Ваши документы готовы!</h1>

        <div class="formular__teaser rich-text">
            <p>
                Мы отправили документы на&nbsp;вашу почту<!--
                    эти HTML-комментарии здесь для того, чтобы перед точкой не было пробела,
                    когда email не рендерится!
             --><template v-if="email">
                    (<code class="formular__result__preformatted">{{ email }}</code>)<!--
             --></template>.
                Если они не придут в&nbsp;течение нескольких минут&nbsp;— проверьте, пожалуйста,
                папку со&nbsp;спамом.
            </p>
            <p>
                Внимательно прочтите текст, проверьте, что все обстоятельства вашей ситуации
                отражены верно. При необходимости внесите правки.
            </p>
            <p>
                Если вам нужна помощь юриста для&nbsp;доработки документов&nbsp;—
                напишите нам на&nbsp;<email-us />.
                В&nbsp;письме расскажите про&nbsp;вашу ситуацию и прикрепите имеющиеся документы.
            </p>
            <p>
                Если документы так и не придут вам на&nbsp;почту&nbsp;—
                тоже пишите на&nbsp;<email-us />.
            </p>

            <p>
                <slot name="addendum" />
            </p>
        </div>

        <base-button
            v-if="isUserLoggedIn"
            type="route"
            to="/cases"
            class="formular__result__btn"
        >
            Посмотреть все документы
        </base-button>

        <base-button
            v-else
            type="route"
            to="/"
            view="secondary"
            class="formular__result__btn"
        >
            Перейти на главную страницу
        </base-button>

        <status-pic
            status="success"
            class="formular__result--status-pic"
        />
    </div>
</template>

<script>
import Vue from 'vue';
import { createNamespacedHelpers } from 'vuex';
import BaseButton from '@/components/button/BaseButton.vue';
import EmailUs from '@/components/mail-to/EmailOILegal.vue';
import StatusPic from './StatusPic.vue';

const { mapGetters: mapAuthGetters } = createNamespacedHelpers('auth');

export default Vue.extend({
    components: {
        BaseButton,
        EmailUs,
        StatusPic,
    },

    props: {
        email: {
            type: String,
            default: '',
        },
    },

    computed: {
        ...mapAuthGetters({ isUserLoggedIn: 'isLoggedIn' }),
    },
});
</script>

<style lang="scss" scoped>
@use "@/styles/variables/grid";
@use "@/styles/mixins/breakpoint";
@import "./common";

.formular__result--status-pic {
    margin-top: grid.$space-10 + grid.$space-1;

    @include breakpoint.desktop {
        margin-top: grid.$space-8 + grid.$space-1;
    }
}
</style>
