<template>
    <div
        class="popover"
        :class="`popover--${isOpen ? 'open' : 'closed'}`"
    >
        <div class="popover__anchor">
            <slot name="anchor"></slot>
        </div>

        <overlay
            :is-open="isOpen"
            @close="$emit('close')"
        >
            <slot></slot>
        </overlay>
    </div>
</template>

<script>
import Vue from 'vue';
import Overlay from '@/components/overlay/BaseOverlay.vue';

export default Vue.extend({
    components: {
        Overlay,
    },

    props: {
        isOpen: {
            type: Boolean,
            required: true,
        },
    },
});
</script>

<style lang="scss" scoped>
@use "@/styles/mixins/breakpoint";
@use "@/styles/variables/colors";
@use "@/styles/variables/grid";

.popover {
    $border-width: 1px;
    $border-color: colors.$grey-4;

    position: relative;

    &.popover--open {
        z-index: 2147483647;
    }

    .popover__anchor {
        cursor: pointer;
    }

    &::v-deep .overlay__body-wrapper {
        $tip-width: 12px;
        $tip-height: 10px;
        $tip-shift-left: 22px;

        @include breakpoint.desktop {
            position: absolute;
            left: calc(50% - #{$tip-shift-left + $tip-width / 2});
            margin-top: $tip-height;

            // before - треугольник, after - его обводка
            &::before,
            &::after {
                content: "";
                position: absolute;
                width: 0;
                height: 0;
                border-style: solid;
                border-color: transparent;
            }

            &::before {
                z-index: 0;
                top: -$tip-height;
                left: $tip-shift-left;
                border-left-width: $tip-width / 2;
                border-right-width: $tip-width / 2;
                border-bottom-width: $tip-height;
                border-bottom-color: colors.$white;
                box-shadow: 0 1px 0 0 colors.$white;
            }

            &::after {
                z-index: -1;
                top: -$tip-height - 1px;
                left: $tip-shift-left - 1px;
                border-left-width: $tip-width / 2 + $border-width;
                border-right-width: $tip-width / 2 + $border-width;
                border-bottom-width: $tip-height + $border-width;
                border-bottom-color: $border-color;
            }
        }
    }

    &::v-deep .overlay__body {
        background: colors.$white;

        @include breakpoint.desktop {
            border: $border-width solid $border-color;
            border-radius: grid.$space-1;
            overflow: hidden;
        }
    }

    &::v-deep .overlay__button-close {
        @include breakpoint.desktop {
            display: none;
        }
    }

    &::v-deep .overlay-transition-enter-active,
    &::v-deep .overlay-transition-leave-active {
        transition: opacity .3s;
    }

    &::v-deep .overlay-transition-enter,
    &::v-deep .overlay-transition-leave-to {
        opacity: 0;
    }
}
</style>
