<template>
    <section class="section">
        <div class="container">
            <court-steps-navigation />
            <court-steps-document :gtm-location="gtmLocation" />
            <court-steps-cards :gtm-location="gtmLocation" />
        </div>
    </section>
</template>

<script lang="ts">
import Vue from 'vue';

import CourtStepsNavigation from './court-steps-navigation/CourtStepsNavigation';
import CourtStepsDocument from './court-steps-document/CourtStepsDocument';
import CourtStepsCards from './court-steps-cards/CourtStepsCards';


export default Vue.extend({
    name: 'CourtSteps',
    components: {
        CourtStepsNavigation,
        CourtStepsDocument,
        CourtStepsCards,
    },
    data() {
        return {
            gtmLocation: 'Документы для суда',
        };
    },
});
</script>
