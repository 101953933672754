<template>
    <court-filing :form-type="formType">
        <template #heading>
            Анкета для формирования кассационной жалобы
        </template>

        <template #submit-hint>
            После отправки формы на вашу почту придёт автоматически сгенерированная жалоба.
            Если вам потребуется помощь с редактированием,
            напишите нашим юристам на&nbsp;<email-us />
        </template>

        <template #result-addendum>
            Вы также можете скачать шаблон жалобы и заполнить его вручную,
            внеся свои правки в выделенных цветом местах:
            <ul>
                <li>
                    <a href="https://docs.google.com/document/export?format=docx&id=1P03IWOcZxQHCPx1tFHerBIbbVyL236xsp73OfSDKZLI">ст. 20.3.3 КоАП РФ</a>
                </li>
                <li>
                    <a href="https://docs.google.com/document/export?format=docx&id=1WE9hI3oDESIsFEpmY0sPh5Drto0O90mOjjGcbZdunvI">ст. 19.3, 20.2, 20.2.2, 20.6.1 КоАП РФ</a>
                </li>
            </ul>
        </template>
    </court-filing>
</template>

<script>
import Vue from 'vue';
import { COURT_FILING_TYPES } from '@/store/court-filing/constants';
import CourtFiling from '@/components/forms/CourtFiling.vue';
import EmailUs from '@/components/mail-to/EmailOILegal.vue';

export default Vue.extend({
    components: {
        CourtFiling,
        EmailUs,
    },

    data() {
        return {
            formType: COURT_FILING_TYPES.CASSATION,
        };
    },
});
</script>
