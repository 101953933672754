<template>
    <div
        class="ui-counter"
        :class="{
            'ui-counter--interactive': interactive,
            'ui-counter--bordered': bordered,
        }"
    >
        <slot />
    </div>
</template>

<script lang="ts">
import Vue from 'vue';

export default Vue.extend({
    props: {
        interactive: {
            type: Boolean,
            default: false,
        },
        bordered: {
            type: Boolean,
            default: false,
        },
    },
});
</script>

<style lang="scss" scoped>
@use "@/styles/mixins/breakpoint" as bp;
@use "@/styles/variables/colors";
@use "@/styles/variables/typography" as tp;

.ui-counter {
    --bg-color: #{colors.$white};
    --text-color: #{colors.$primary-green};

    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    width: 58px;
    height: 58px;
    cursor: default;
    user-select: none;
    border-radius: 50%;
    font-family: tp.$font-family;
    font-size: 28px;
    font-weight: 500;
    color: var(--text-color);
    background: var(--bg-color);
    border: 1px solid var(--bg-color);

    &.ui-counter--interactive {
        cursor: pointer;

        &:hover {
            --bg-color: #{colors.$primary-green};
            --text-color: #{colors.$white};
        }
    }

    &.ui-counter--bordered {
        border-color: colors.$primary-green;
    }
}
</style>
