<template>
    <section class="appeal-page-legacy section section--padding-top-1 section--padding-bottom-2">
        <div class="container">
            <h1
                v-if="formStep === 'AWAITING_SCHEMA' || formStep === 'FILLING_FORM'"
            >
                Апелляция для всех задержанных в ходе протестов
            </h1>

            <div class="container__grid">
                <div class="formular">
                    <transition name="loading">
                        <form-skeleton
                            v-if="formStep === 'AWAITING_SCHEMA'"
                            class="appeal-page-legacy__skeleton"
                        />

                        <div
                            v-else-if="formStep === 'FILLING_FORM'"
                            class="appeal-page-legacy__form"
                        >
                            <div class="formular__teaser">
                                <p>
                                    Пройти суд апелляционной инстанции важно всем.
                                    Это нужно не только для того, чтобы попытаться отстоять
                                    свои права в рамках российской судебной системы,&nbsp;—
                                    без этого невозможно обратиться в Европейский суд
                                    по правам человека (ЕСПЧ) и в нем добиться
                                    справедливости и компенсации.
                                    После заполнения анкеты мы составим жалобу, и с вами свяжется
                                    наш юрист по той электронной почте, которую вы указали.
                                </p>
                                <p>
                                    Обратите внимание: обжаловать необходимо в течение 10 дней
                                    после получения решения суда&nbsp;— поэтому поспешите.
                                    Учтите также, что нам нужно время на проверку
                                    и организацию подачи.
                                </p>
                                <p>
                                    Даже если вы не успели в срок, жалобу все равно стоит
                                    подать&nbsp;— вместе с ходатайством о восстановлении срока.
                                    Читайте подробнее об этом
                                    <a :href="`${oiLegalBaseURL}/instruction/help-yourself#9`">
                                        в инструкции
                                    </a>.
                                </p>
                                <p>
                                    Если вы заполняете эту жалобу за родственника или друга, который
                                    сейчас находится под арестом, и не знаете чего-то из того,
                                    что мы спрашиваем&nbsp;— не страшно. Укажите в этих полях
                                    «не знаю» или выставите текущее время, а потом
                                    обязательно укажите на это в последнем поле формы.
                                </p>
                            </div>
                            <form-appeal></form-appeal>
                        </div>

                        <form-result-success
                            v-else-if="formStep === 'FORM_SENT'"
                            :email="fields['заявитель_электронная_почта']"
                        >
                            <template #addendum>
                                <result-addedndum />
                            </template>
                        </form-result-success>

                        <form-result-failure
                            v-else-if="formStep === 'FORM_FAILED'"
                            :start-over="startOver"
                            :email="fields['заявитель_электронная_почта']"
                        >
                            <template #addendum>
                                <result-addedndum />
                            </template>
                        </form-result-failure>
                    </transition>
                </div>
            </div>
        </div>
    </section>
</template>

<script lang="ts">
import Vue from 'vue';
import { createNamespacedHelpers } from 'vuex';
import config from '@/config';
import FormAppeal from '@/components/forms/appeal/FormAppeal.vue';
import ResultAddedndum from '@/components/forms/appeal/ResultAddendum.vue';
import FormSkeleton from '@/components/forms/FormSkeleton.vue';
import FormResultSuccess from '@/components/forms/submission-result/SubmissionSuccess.vue';
import FormResultFailure from '@/components/forms/submission-result/SubmissionFailure.vue';

const { mapState, mapActions } = createNamespacedHelpers('appeal');

export default Vue.extend({
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'Appeal',

    components: {
        FormAppeal,
        ResultAddedndum,
        FormSkeleton,
        FormResultSuccess,
        FormResultFailure,
    },

    data() {
        return {
            isRetry: false,
            oiLegalBaseURL: config.oiLegalBaseURL,
        };
    },

    computed: {
        ...mapState(['formIsLoading', 'formIsSending', 'formIsSent', 'formIsFailed', 'fields']),

        formStep() {
            if (this.formIsLoading) {
                return 'AWAITING_SCHEMA';
            }
            if (this.isRetry) {
                return 'FILLING_FORM';
            }
            if (this.formIsSent) {
                return 'FORM_SENT';
            }
            if (this.formIsFailed) {
                return 'FORM_FAILED';
            }
            return 'FILLING_FORM';
        },
    },

    watch: {
        formIsSending(isPending) {
            if (isPending) {
                this.isRetry = false;
            }
        },
    },

    created() {
        /* `bootstrapForm` — асинхронный экшн, но не всем нужно его дожидаться */
        this.bootstrapForm().then(() => {
            this.prefillFormForQA();
        });
        this.resetForm();
    },

    methods: {
        ...mapActions(['bootstrapForm', 'resetForm', 'prefillFormForQA']),

        startOver() {
            this.isRetry = true;
        },
    },
});
</script>

<style lang="scss" scoped>
$fade-in-out-duration: 300ms;

.formular {
    position: relative;
}

.appeal-page-legacy__skeleton {
    opacity: 1;
    transition: opacity $fade-in-out-duration;

    &.loading-leave-active {
        position: absolute;
        top: 0;
        z-index: 1;
        width: 100%;
        opacity: 0;
    }
}

.appeal-page-legacy__form {
    &.loading-enter-active {
        opacity: 0;
    }

    &.loading-enter-to {
        position: relative;
        z-index: -1;
        transition: opacity $fade-in-out-duration;
        opacity: 1;
    }
}
</style>
