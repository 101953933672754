<template>
    <div class="form-field">
        <label
            class="form-field__label"
            :for="id"
            :class="hideLabel && 'u-hidden-visually'"
        >
            {{ label }}
            <span
                v-if="uiRequired"
                class="required"
            >*</span>
        </label>
        <div class="form-field__wrapper">
            <div class="form-field__control-wrapper">
                <select
                    :id="id"
                    v-model="proxyModel"
                    class="form-field__control select"
                    :class="!isValid && 'form-field__control--invalid select--invalid'"
                    :name="name"
                    :disabled="disabled"
                    :multiple="multiple"
                >
                    <option
                        :value="null"
                        disabled
                    >
                        {{ label }}
                    </option>
                    <option
                        v-for="option in options"
                        :key="option.id"
                        :value="option.id"
                    >
                        {{ option.name }}
                    </option>
                </select>
                <div
                    v-if="!hideErrorMessage && (hasHelper || !isValid)"
                    class="form-field__helper-wrapper"
                >
                    <form-input-helper
                        :link-url="helperLinkUrl"
                        :link-text="helperLinkText"
                        :is-error="!isValid"
                    >
                        {{ errors[0] || helperText }}
                    </form-input-helper>
                </div>
            </div>
            <div class="form-field__icon-invalid-wrapper">
                <div
                    class="form-field__icon-invalid"
                    :class="!isValid && 'is-visible'"
                >
                    <base-icon icon-name="Внимание">
                        <icon-attention />
                    </base-icon>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import makeWithVModelMixin from '@shared/mixins/with-v-model';
import BaseIcon from '@/components/BaseIcon.vue';
import FormInputHelper from '@/components/FormInputHelper.vue';
import IconAttention from '@/icons/IconAttention.vue';

interface Option {
    name: string;
    id: any;
}

/**
 * Использование с `v-model` в родительском компоненте:
 *
 * (1) если `multiple=true` (а модель - массив):
 * на чтение: те опции, чей атрибут `value` (`id` соотвутствующего `option`) содержится в модели,
 * становятся выбранными; остальные - невыбранными
 * на запись: при выборе опции значение её атрибута `value` (`option.id`) добавляется в модель,
 * при снятии - удаляется
 * (2) если `multiple=false`:
 * на чтение: при совпадении значения модели с атрибутом `value` (`option.id`) какой-либо опции
 * она становится выбранной; остальные - невыбранными
 * на запись: при выборе опции в модель записывается значение её атрибута `value` (`option.id`)
 *
 * (т.е. интерфейс тот же, что и у нативного <select />, за исключением способа передачи опций)
 */
export default Vue.extend({
    components: {
        BaseIcon,
        IconAttention,
        FormInputHelper,
    },

    mixins: [
        makeWithVModelMixin({ event: 'change' }),
    ],

    props: {
        id: {
            type: String,
            required: true,
        },
        name: {
            type: String,
            required: true,
        },
        value: {},
        options: {
            type: (Array as PropType<Option[]>) || Array,
            required: true,
        },
        multiple: {
            type: Boolean,
            default: false,
        },
        label: {
            type: String,
            default: '',
        },
        hideLabel: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        uiRequired: {
            type: Boolean,
            default: false,
        },
        isValid: {
            type: Boolean,
            default: true,
        },
        errors: {
            type: Array,
            default: () => ([]),
        },
        helperText: {
            type: String,
            default: '',
        },
        helperLinkUrl: {
            type: String,
            default: '',
        },
        helperLinkText: {
            type: String,
            default: '',
        },
        hideErrorMessage: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        hasHelper(): boolean {
            return (
                this.helperText || (this.helperLinkUrl && this.helperLinkText)
            );
        },
    },
});
</script>

<style lang="scss">
@use "@/styles/variables/typography";
@use "@/styles/variables/colors";

.select {
    appearance: none;
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24'%3E%3Cpath fill='currentColor' d='m12 15.713 6.01-6.01-1.413-1.415-4.597 4.6-4.596-4.6L5.99 9.702 12 15.713z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: right 12px center;

    &:focus,
    &--invalid {
        background-position: right 11px center;
    }
}
</style>
