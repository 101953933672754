<template>
    <div>
        <hero-header />
        <court-steps />
        <espch-escalation />

        <!-- FAQ -->
        <section class="section section--padding-80">
            <div class="container">
                <court-faq :questions="questions" />
            </div>
        </section>
        <!-- More questions? -->
        <section
            class="section section--padding-top-1 section--padding-bottom-2 section--colored"
        >
            <div class="container">
                <post-faq />
            </div>
        </section>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';

import HeroHeader from './hero-header/HeroHeader.vue';
import CourtSteps from './court-steps/CourtSteps.vue';
import EspchEscalation from './espch-escalation/EspchEscalation.vue';
import CourtFaq from '../court-faq/CourtFaq.vue';
import PostFaq from '../PostFaq.vue';

import { questions } from '../court-faq-questions';


export default Vue.extend({
    name: 'PageHomeNew',
    components: {
        HeroHeader,
        CourtSteps,
        EspchEscalation,
        CourtFaq,
        PostFaq,
    },

    data() {
        return {
            questions,
        };
    },
});
</script>
