<template>
    <div class="form-field">
        <label
            class="form-field__label"
            :for="id"
            :class="hideLabel && 'u-hidden-visually'"
        >
            {{ label }}
            <span
                v-if="uiRequired"
                class="required"
            >*</span>
        </label>
        <div class="form-field__wrapper">
            <div class="form-field__control-wrapper">
                <textarea
                    v-if="isMultiline"
                    :id="id"
                    ref="focusable"
                    v-model="proxyModel"
                    class="form-field__control form-field__control--textarea"
                    :class="!isValid && 'form-field__control--invalid'"
                    :name="name"
                    :placeholder="placeholder"
                    :maxlength="maxLength"
                    :minlength="minLength"
                    :disabled="disabled"
                    :readonly="readonly"
                    :style="textareaHeightStyle"
                    v-bind="$attrs"
                ></textarea>
                <input
                    v-else
                    :id="id"
                    ref="focusable"
                    v-model="proxyModel"
                    class="form-field__control"
                    :class="!isValid && 'form-field__control--invalid'"
                    :type="type"
                    :name="name"
                    :placeholder="placeholder"
                    :maxlength="maxLength"
                    :minlength="minLength"
                    :max="max"
                    :min="min"
                    :disabled="disabled"
                    :readonly="readonly"
                    v-bind="$attrs"
                />
                <div
                    v-if="hasHelper || !isValid"
                    class="form-field__helper-wrapper"
                >
                    <form-input-helper
                        :link-url="helperLinkUrl"
                        :link-text="helperLinkText"
                        :is-error="!isValid"
                    >
                        {{ errors[0] || helperText }}
                    </form-input-helper>
                </div>
            </div>

            <div class="form-field__icon-invalid-wrapper">
                <div
                    class="form-field__icon-invalid"
                    :class="!isValid && 'is-visible'"
                >
                    <base-icon icon-name="Внимание">
                        <icon-attention />
                    </base-icon>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import focusedElementMixin from '@shared/mixins/focused-element';
import makeWithVModelMixin from '@shared/mixins/with-v-model';
import BaseIcon from '@/components/BaseIcon.vue';
import FormInputHelper from '@/components/FormInputHelper.vue';
import IconAttention from '@/icons/IconAttention.vue';

/**
 * Использование с `v-model` в родительском компоненте:
 *
 * на чтение: значение модели записывается в поле
 * на запись: текст, введенный в поле, записывается в модель
 *
 * (т.е. интерфейс тот же, что и у нативного <input type="text" />)
 */
export default Vue.extend({
    components: {
        BaseIcon,
        IconAttention,
        FormInputHelper,
    },

    mixins: [
        focusedElementMixin,
        makeWithVModelMixin(),
    ],

    props: {
        label: {
            type: String,
            default: '',
        },
        hideLabel: {
            type: Boolean,
            default: false,
        },
        id: {
            type: String,
        },
        type: {
            type: String as PropType<
                'text' | 'email' | 'tel' | 'password' | 'number'
            >,
            default: 'text',
        },
        name: {
            type: String,
        },
        placeholder: {
            type: String,
            default: '',
        },
        maxLength: {
            type: Number,
            default: 500,
        },
        minLength: {
            type: Number,
            default: 0,
        },
        max: {
            type: Number,
            default: Infinity,
        },
        min: {
            type: Number,
            default: -Infinity,
        },
        disabled: {
            type: Boolean,
        },
        uiRequired: {
            type: Boolean,
            default: false,
        },
        readonly: {
            type: Boolean,
        },
        value: {
            type: String,
        },
        isValid: {
            type: Boolean,
            default: true,
        },
        errors: {
            type: Array,
            default: () => ([]),
        },
        helperText: {
            type: String,
            default: '',
        },
        helperLinkUrl: {
            type: String,
            default: '',
        },
        helperLinkText: {
            type: String,
            default: '',
        },
        isMultiline: {
            type: Boolean,
        },
        rows: {
            type: Number,
            default: 3,
        },
    },

    computed: {
        hasHelper(): boolean {
            return (
                this.helperText || (this.helperLinkUrl && this.helperLinkText)
            );
        },

        textareaHeightStyle(): object {
            return {
                height: `${this.rows * 21 + 28}px`,
            };
        },
    },
});
</script>
