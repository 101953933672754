import { sum } from 'lodash';
import { weightedChoice, getStorageKeys } from './utils';


export const NONE_VARIANT = null;
export const NOT_READY = Symbol('Store is not ready');

/*
Поле `weight` определяет вероятность выпадения соответствующей A/B-группы;
сумма весов всех вариантов теста должна быть не больше 1
(может быть меньше, в таком случае пользователь может быть не отнесен ни к какой группе,
что должно быть предусмотрено кодом компонента, поведение которого тестируется)
*/
const AB_CONFIG = [
    {
        name: 'landing',
        variants: [
            {
                name: 'new',
                weight: 1,
            },
            {
                name: 'control',
                weight: 0,
            },
        ],
    },
];

const NONE_VARIANT_STORED = 'NOT_BUCKETED';
const STORAGE_PREFIX = 'abtest_';


/**
 * Распределяет пользователя в группу A/B-теста случайным образом
 * в соответствии с весами в `AB_CONFIG`;
 * если сумма весовых коэффициентов в конфиге теста меньше 1,
 * функция может вернуть специальное значение null (`NONE_VARIANT`)
 * @param {Object[]} variants — список вариантов A/B-теста
 * @returns {String|null} — выбранный вариант
 */
function chooseBucket(variants) {
    const items = variants.map(({ name, weight }) => ({ value: name, weight }));
    const totalWeight = sum(items.map(({ weight }) => weight));
    return weightedChoice([...items, { value: NONE_VARIANT, weight: 1 - totalWeight }]);
}

/**
 * Если A/B-тест и/или пользователь новый: распределяет пользователя в группу
 * и сохраняет ее в `localStorage`;
 * если группа уже была выбрана в одно из прошлых посещений,
 * используется сохраненное значение из `localStorage`
 * @param {Object} testConfig — конфиг A/B-теста
 * @returns {String|null} — выбранный (впервые или ранее) вариант
 */
function resolveBucket(testConfig) {
    const storageKey = `${STORAGE_PREFIX}${testConfig.name}`;
    const storedBucket = localStorage.getItem(storageKey);
    if (storedBucket) {
        return storedBucket !== NONE_VARIANT_STORED ? storedBucket : NONE_VARIANT;
    }

    const bucket = chooseBucket(testConfig.variants);
    localStorage.setItem(storageKey, bucket !== NONE_VARIANT ? bucket : NONE_VARIANT_STORED);
    return bucket;
}

/**
 * Удаляет из `localStorage` все тесты, которых нет в `AB_CONFIG`
 */
function clearFinishedTests() {
    const abTests = new Set(AB_CONFIG.map(({ name }) => name));
    const storedTests = getStorageKeys((key) => key.startsWith(STORAGE_PREFIX));
    const prefixLength = STORAGE_PREFIX.length;
    storedTests.forEach((key) => {
        const testName = key.substring(prefixLength);
        if (!abTests.has(testName)) {
            localStorage.removeItem(key);
        }
    });
}


/**
 * Создает модуль Vuex store
 * @param {Object} client - объект с интерфейсом как у Axios client
 * @returns {Object} объект, пригодный для использования в качестве модуля Vuex store
 */
export const makeStore = () => {
    return {
        state: {
            isReady: false,
            /*
            объект из пар, где ключ — название теста в `AB_CONFIG`,
            значение — название группы, в которую попал пользователь в этом тесте
            */
            abTests: {},
        },

        getters: {
            getBucket(state) {
                return (testName) => {
                    return state.isReady ? state.abTests[testName] : NOT_READY;
                };
            },
        },

        actions: {
            /**
             * Сейчас экшн фактически не делает ничего асинхронного, но может таковым стать
             */
            async init({ state }) {
                clearFinishedTests();

                state.abTests = AB_CONFIG.reduce(
                    (abTests, testConfig) => {
                        return {
                            ...abTests,
                            [testConfig.name]: resolveBucket(testConfig),
                        };
                    },
                    {},
                );

                state.isReady = true;
            },
        },
    };
};
