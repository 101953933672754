<template>
    <v-calendar-date-picker
        v-model="valueProxy"
        mode="time"
        v-bind="datePickerSettings"
    >
        <template #default="{ inputValue, inputEvents, togglePopover }">
            <div class="date-time-picker__input-wrapper">
                <input
                    ref="inputNode"
                    class="
                        form-field__control
                        date-time-picker__input
                        date-time-picker__input--time
                    "
                    :class="!isValid
                        ? `
                            form-field__control--invalid
                            date-time-picker__input--invalid
                        ` : ''
                    "
                    type="text"
                    autocomplete="off"
                    inputmode="numeric"
                    :value="isUserTyping ? rawInputValue : inputValue"
                    :placeholder="placeholder"
                    :disabled="disabled"
                    :readonly="readonly"
                    @input="onChange($event, inputEvents.input)"
                    @change="onChange($event, inputEvents.change)"
                />
                <button
                    type="button"
                    class="date-time-picker__toggle-popover"
                    @click="togglePopover"
                >
                    <img src="@/assets/clock.svg" />
                </button>
            </div>
            <div
                v-if="!isValid"
                class="form-field__helper-wrapper"
            >
                <form-input-helper :is-error="true">
                    {{ errors[0] }}
                </form-input-helper>
            </div>
        </template>
    </v-calendar-date-picker>
</template>

<script lang="ts">
import Vue from 'vue';
import { DatePicker as VCalendarDatePicker } from 'v-calendar';
import FormInputHelper from '@/components/FormInputHelper.vue';
import { handleDateTime } from './helpers';
import { makeDateTimeUnitMixin } from './mixins';

export default Vue.extend({
    components: {
        VCalendarDatePicker,
        FormInputHelper,
    },

    model: { event: 'change' },

    mixins: [
        makeDateTimeUnitMixin({
            inputMaskSettings: {
                time: true,
                timePattern: ['h', 'm'],
            },
        }),
    ],

    computed: {
        valueProxy: {
            get() {
                /*
                пока время не задано явно (пользователем или родительским компонентом),
                возможное имеющееся значение модели не должно отображаться в интерфейсе
                */
                return this.isValueSet ? this.value : null;
            },
            /**
             * @param {Number} value — одно из:
             * - установленное/обновленное пользователем время и установленная ранее дата
             * - установленное пользователем время и *сегодняшняя дата*
             * если компонента времени не была задана ранее (стандартное поведение v-calendar,
             * когда значение модели не задано — см. геттер выше)
             * - NaN, если время сброшено в интерфейсе
             */
            set(value) {
                let datetime = value;
                /* случай, когда значение компоненты времени устанавливается впервые */
                if (!this.isValueSet && !Number.isNaN(datetime)) {
                    if (this.value) {
                        /* берем компоненту даты из ранее установленного значения */
                        datetime = handleDateTime((dt, dtPrev) => {
                            return dt.set({
                                year: dtPrev.year,
                                month: dtPrev.month,
                                day: dtPrev.day,
                            });
                        })(datetime, this.value);
                    }
                }

                /*
                если изменить значение модели синхронно, v-calendar почему-то игнорирует обновление
                */
                this.$nextTick(() => {
                    const datetimeNormalized = datetime || null;
                    if (!this.isValueSet || datetimeNormalized !== this.value) {
                        this.$emit('change', datetimeNormalized);
                    }
                });
            },
        },
    },
});
</script>
