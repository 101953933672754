// CSS
import 'normalize.css';
import './main.scss';

import Vue from 'vue';
import * as Sentry from '@sentry/vue';
import { BrowserTracing as SentryBrowserTracing } from '@sentry/tracing';
import { Fragment } from 'vue-frag';
import * as VeeValidate from 'vee-validate';
import VueYandexMetrika from 'vue-yandex-metrika';

import App from './App.vue';
import { store } from './store';
import router from './router';
import config from './config';

import BaseButton from './components/button/BaseButton.vue';
import BaseCheckbox from './components/checkbox/BaseCheckbox.vue';
import BaseIcon from './components/BaseIcon.vue';
import BaseModal from './components/modal/BaseModal.vue';
import BaseRadio from './components/radio/BaseRadio.vue';
import BaseSelect from './components/select/BaseSelect.vue';

// кастомные правила валидации для vee-validate
import './validation';

// Install components globally
Vue.component('Fragment', Fragment); // eslint-disable-line vue/multi-word-component-names
Vue.component('ValidationObserver', VeeValidate.ValidationObserver);
Vue.component('ValidationProvider', VeeValidate.ValidationProvider);
Vue.component('BaseButton', BaseButton);
Vue.component('BaseCheckbox', BaseCheckbox);
Vue.component('BaseIcon', BaseIcon);
Vue.component('BaseModal', BaseModal);
Vue.component('BaseRadio', BaseRadio);
Vue.component('BaseSelect', BaseSelect);

Vue.use(VeeValidate, {
    events: 'blur',
});

Vue.use(VueYandexMetrika, {
    id: config.yMetrikaID,
    router,
    env: config.env,
});

function initApp() {
    if (!(document.readyState === 'interactive' || document.readyState === 'complete')) {
        document.addEventListener('readystatechange', initApp);
        return;
    }
    document.removeEventListener('readystatechange', initApp);

    new Vue({
        router,
        store,
        render: (h) => h(App),
    }).$mount('#app');
}
initApp();

if (config.env !== 'development') {
    Sentry.init({
        Vue,
        dsn: config.sentryDSN,
        integrations: [
            new SentryBrowserTracing({
                routingInstrumentation: Sentry.vueRouterInstrumentation(router),
            }),
        ],
        tracesSampleRate: 0.1,
        logErrors: true,
    });
}
