const DEFAULT_PROP = 'value';
const DEFAULT_EVENT = 'input';

/**
 * Миксин, порождаемый фабрикой, упрощает кастомным элементам форм поддержку директивы `v-model`
 * @param {Object} [model] - по смыслу и форме аналогичен аргументу `model` конструктора компонента
 * см. https://v2.vuejs.org/v2/api/#model
 * @returns {Object} - объект миксина (для передачи в конструктор компонента
 * в составе массива `mixins`)
 * @example
 * Vue.component('custom-checkbox', {
 *   template: `<div>
 *     <label>
 *       {{ label }}
 *       <input type="checkbox" v-model="proxyModel" />
 *     </label>
 *   </div>`,
 *   props: ['label', 'checked'],
 *   mixins: [makeWithVModelMixin({ prop: 'checked', event: 'change' })],
 * });
 */
export default function makeWithVModelMixin(model = {}) {
    return {
        model,

        computed: {
            proxyModel: {
                get() {
                    return this[model.prop || DEFAULT_PROP];
                },
                set(value) {
                    this.$emit(model.event || DEFAULT_EVENT, value);
                },
            },
        },
    };
}
