<template>
    <validation-provider
        v-if="mappedStore.isSchemaReady"
        ref="validationProvider"
        :schema="mappedStore.schema"
    >
        <form @submit.prevent="handleSubmit">
            <formular-section
                v-for="section in sections"
                :key="section.title"
                :headline="section.title"
            >
                <transition-group
                    tag="ul"
                    name="conditional"
                >
                    <!-- eslint-disable vue/no-use-v-if-with-v-for -->
                    <li
                        v-for="(field, idx) in section.fields"
                        v-if="isVisible(field.name)"
                        :key="field.name"
                        :ref="`field_${field.name}`"
                        class="form-field-wrapper"
                    >
                        <validator
                            v-slot="{ isValid, errors }"
                            v-model="formState[field.name]"
                            :key-name="field.name"
                        >
                            <input-selector
                                v-model="formState[field.name]"
                                :form-type="formType"
                                :field="field"
                                :is-valid="isValid"
                                :errors="errors"
                                :class="{ 'form-field--first': idx === 0 }"
                            />
                        </validator>
                    </li>
                    <!-- eslint-enable vue/no-use-v-if-with-v-for -->
                </transition-group>
            </formular-section>

            <slot name="submit" />
        </form>
    </validation-provider>
</template>

<script>
import Ajv from 'ajv';
import Vue from 'vue';
import ValidationProvider from '@shared/validation/components/ValidationProvider.vue';
import Validator from '@shared/validation/components/Validator.vue';
import { makeMappedStoreMixin } from '@/store/court-filing/utils/vuex-helpers';
import FormularSection from '@/components/FormularSection.vue';
import InputSelector from './FormInputSelector.vue';

/**
 * Компонент, который генерирует формы на основе схем формата JSONSchema
 * @todo Описать формат кастомного поля `generaptor` и приложить здесь ссылку
 */
export default Vue.extend({
    components: {
        ValidationProvider,
        Validator,
        InputSelector,
        FormularSection,
    },

    mixins: [
        makeMappedStoreMixin(({
            mapState, mapGetters, mapMutations, mapActions,
        }) => ({
            computed: {
                ...mapState(['schema', 'formValues']),
                ...mapGetters(['isSchemaReady']),
            },
            methods: {
                ...mapMutations({ setFormValue: 'formValue' }),
                ...mapActions(['submitForm']),
            },
        })),
    ],

    props: {
        /*
        соответствует ключам в стейте стора `court-filing`;
        предполагается, что значение не будет изменяться (если будет, это будет проигнорировано)
        */
        formType: {
            type: String,
            required: true,
        },
    },

    computed: {
        sections() {
            const formDef = this.mappedStore.schema.generaptor;
            return formDef.sections.map((section) => {
                return {
                    ...section,

                    fields: section.fields.map((field) => {
                        return {
                            ...formDef.fields[field],
                            // или в шаблоне использовать `fe_id`?
                            name: field,
                        };
                    }),
                };
            });
        },

        fieldsFlattened() {
            return this.sections.reduce(
                (fields, section) => [...fields, ...section.fields],
                [],
            );
        },

        /**
         * Обертка для части стора, хранящей значения полей формы:
         * чтение осуществляется напрямую из стора, запись — через мутации
         */
        formState() {
            const formState = this.mappedStore.formValues;

            return new Proxy(formState, {
                set: (_, prop, value) => {
                    this.mappedStore.setFormValue({ field: prop, value });
                    return true;
                },
            });
        },
    },

    created() {
        const ajv = new Ajv({ messages: false });
        const { fields } = this.mappedStore.schema.generaptor;
        const schemaMeta = {
            $schema: this.mappedStore.schema.$schema,
            $defs: this.mappedStore.schema.$defs,
        };

        /*
        для каждого поля формы, в описании которого есть специальная схема
        под названием `visibilitySchema`, создает функцию, которая будет использоваться
        для проверки, нужно ли его рендерить (см. метод `isVisible`)
        */
        this.fieldVisibilityCheckers = Object.keys(fields)
            .filter((field) => ('visibilitySchema' in fields[field]))
            .map((field) => [
                field,
                ajv.compile({ ...schemaMeta, ...fields[field].visibilitySchema }),
            ])
            .reduce(
                (checkers, [field, validate]) => ({ ...checkers, [field]: validate }),
                {},
            );
    },

    methods: {
        /**
         * Определяет, нужно ли рендерить поле формы
         * @param {String} field - название поля, как оно указано в общей схеме
         * @returns {Boolean}
         */
        isVisible(field) {
            /* по умолчанию нужно */
            if (!(field in this.fieldVisibilityCheckers)) {
                return true;
            }
            /*
            когда в описании поля есть специальная схема под названием `visibilitySchema`,
            валидируем по ней весь стор:
            если валидация проходит — то показывать поле, иначе не показывать
            */
            const validate = this.fieldVisibilityCheckers[field];
            return validate(this.mappedStore.formValues);
        },

        handleSubmit() {
            const [isValid, errors] = (
                this.$refs.validationProvider.validate(this.mappedStore.formValues)
            );

            /* прокрутка к первому невалидному полю */
            if (!isValid) {
                const firstInvalid = this.fieldsFlattened
                    .find((field) => !errors.isKeyValid(field.name));
                if (!firstInvalid) {
                    console.error(
                        'Couldn\'t match one ore more errors to any form field in form'
                        + `"${this.formType}". Errors: ${JSON.stringify(errors.errors)}`,
                    );
                    return;
                }
                window.scrollTo({
                    top: this.$refs[`field_${firstInvalid.name}`][0].offsetTop,
                    behavior: 'smooth',
                });

                return;
            }

            this.mappedStore.submitForm();
        },
    },
});
</script>

<style lang="scss" scoped>
@use "@/styles/variables/grid";

.formular__fields {
    .form-field {
        &:not(.form-field--first) {
            margin-top: grid.$space-3;
        }
    }
}

.form-field-wrapper {
    $fade-in-out-duration: 300ms;

    transition: opacity $fade-in-out-duration;

    &.conditional-enter-active {
        opacity: 0;
    }

    &.conditional-enter-to {
        opacity: 1;
    }

    &.conditional-leave-active {
        opacity: 0;
    }
}
</style>
