<template>
    <div class="court-steps-cards">
        <ul class="court-steps-card-list">
            <li class="court-steps-card">
                <ui-card
                    id="court-steps-first-court"
                    full-height
                >
                    <div class="court-steps-card__container">
                        <div>
                            <ui-counter>1</ui-counter>
                            <div class="court-steps-card__content">
                                <ui-h2>
                                    Получил протокол и&nbsp;готовлюсь к&nbsp;суду
                                </ui-h2>
                                <div class="court-steps-card__text-group">
                                    <ui-paragraph>
                                        До&nbsp;судебного заседания тебе
                                        <em>надо успеть подготовить ходатайства
                                            и&nbsp;объяснения</em>.
                                    </ui-paragraph>
                                    <ui-paragraph>
                                        Ходатайства можно подать заранее, а&nbsp;можно взять
                                        с&nbsp;собой в&nbsp;суд и&nbsp;подать
                                        во&nbsp;время заседания.
                                    </ui-paragraph>
                                </div>
                            </div>
                        </div>
                        <base-button
                            type="route"
                            to="/filing/court-motions"
                            full-width
                            :data-gtm="JSON.stringify({itemLocation: gtmLocation})"
                        >
                            Сгенерировать ходатайства
                        </base-button>
                    </div>
                </ui-card>
            </li>

            <li class="court-steps-card">
                <ui-card
                    id="court-steps-appeal-court"
                    full-height
                >
                    <div class="court-steps-card__container">
                        <div>
                            <ui-counter>2</ui-counter>
                            <div class="court-steps-card__content">
                                <ui-h2>
                                    Хочу обжаловать
                                </ui-h2>
                                <ui-paragraph>
                                    Не&nbsp;спеши платить. У&nbsp;тебя есть
                                    10&nbsp;дней с&nbsp;момента получения постановления
                                    на&nbsp;то, чтобы обжаловать его.
                                </ui-paragraph>
                                <div class="court-steps-card__text-group">
                                    <ui-paragraph pale>
                                        Большинство дел прекращений и&nbsp;возвратов случаются
                                        на&nbsp;этапе апелляции.
                                        <em>НЕ&nbsp;СДАВАЙСЯ!</em>
                                    </ui-paragraph>
                                    <ui-paragraph pale>
                                        Инструкцию по&nbsp;подаче жалобы
                                        ты&nbsp;получишь в&nbsp;письме с&nbsp;документом,
                                        которое придёт в&nbsp;течение 10&nbsp;минут
                                        после заполнения анкеты.
                                    </ui-paragraph>
                                </div>
                            </div>
                        </div>
                        <base-button
                            type="route"
                            to="/filing/appeal-court"
                            full-width
                            :data-gtm="JSON.stringify({itemLocation: gtmLocation})"
                        >
                            Сгенерировать жалобу
                        </base-button>
                    </div>
                </ui-card>
            </li>

            <li class="court-steps-card">
                <ui-card
                    id="court-steps-court-of-cassation"
                    full-height
                >
                    <div class="court-steps-card__container">
                        <div>
                            <ui-counter>3</ui-counter>
                            <div class="court-steps-card__content">
                                <ui-h2>
                                    Получил решение и&nbsp;хочу дальше бороться
                                </ui-h2>
                                <div class="court-steps-card__text-group">
                                    <ui-paragraph>
                                        <em>
                                            Ты&nbsp;прошел большую часть пути! Не&nbsp;сдавайся!
                                        </em>
                                    </ui-paragraph>
                                    <ui-paragraph>
                                        Снова идти в&nbsp;суд не&nbsp;нужно, только
                                        отправить жалобу по&nbsp;почте
                                        в&nbsp;<a
                                            href="https://sudrf.ru/index.php?id=300&searchtype=fs"
                                            target="_blank"
                                            :data-gtm="JSON.stringify({itemLocation: gtmLocation})"
                                            @click="trackClick($event, 'externalLinkClick')"
                                        >кассационный суд</a>.
                                    </ui-paragraph>
                                    <ui-paragraph>
                                        Помимо самой жалобы, тебе нужны две
                                        заверенные копии решения.
                                    </ui-paragraph>
                                </div>
                            </div>
                        </div>
                        <base-button
                            type="route"
                            to="/filing/court-of-cassation"
                            full-width
                            :data-gtm="JSON.stringify({itemLocation: gtmLocation})"
                        >
                            Анкета на подготовку жалобы
                        </base-button>
                    </div>
                </ui-card>
            </li>

            <li class="court-steps-card">
                <ui-card
                    id="court-steps-supreme-court"
                    full-height
                >
                    <div class="court-steps-card__container">
                        <div>
                            <ui-counter>4</ui-counter>
                            <div class="court-steps-card__content">
                                <ui-h2>
                                    Подаю жалобу в&nbsp;Верховный суд
                                </ui-h2>
                                <div class="court-steps-card__text-group">
                                    <ui-paragraph>
                                        Заверенная копия решения у&nbsp;тебя уже есть.
                                        Жалобу отправь
                                        в&nbsp;<a
                                            href="https://vsrf.ru/contacts/"
                                            target="_blank"
                                            :data-gtm="JSON.stringify({itemLocation: gtmLocation})"
                                            @click="trackClick($event, 'externalLinkClick')"
                                        >Верховный суд</a>.
                                    </ui-paragraph>
                                </div>
                            </div>
                        </div>
                    </div>
                </ui-card>
            </li>
        </ul>

        <div class="court-steps__help">
            <div class="court-steps__help-link">
                <base-button
                    type="link"
                    :to="tgBotURL"
                    view="secondary"
                    full-width
                    :data-gtm="JSON.stringify({itemLocation: gtmLocation})"
                >
                    Я не понял
                </base-button>
            </div>
            <p class="court-steps__help-text">
                нажав кнопку, ты&nbsp;перейдешь в&nbsp;чат с&nbsp;юристом
            </p>
        </div>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import config from '@/config';
import clickTrackingMixin from '@/mixins/click-tracking';
import BaseButton from '../../../../button/BaseButton.vue';
import UiCard from '../../UiCard.vue';
import UiCounter from '../../UiCounter.vue';
import UiH2 from '../../UiH2.vue';
import UiParagraph from '../../UiParagraph.vue';


export default Vue.extend({
    name: 'CourtStepsCards',

    components: {
        BaseButton,
        UiCard,
        UiCounter,
        UiH2,
        UiParagraph,
    },

    mixins: [clickTrackingMixin],

    props: {
        gtmLocation: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            tgBotURL: config.tgBotURL,
        };
    },
});
</script>

<style lang="scss" scoped>
@use "@/styles/mixins/breakpoint" as bp;
@use "@/styles/variables/colors";
@use "@/styles/variables/typography" as tp;


.court-steps-cards {
    --horizontal-indent: 20px;

    @include bp.mobile-md {
        --vertical-indent: 20px;
    }

    @include bp.desktop-md {
        --vertical-indent: 24px;
    }

    @include bp.mobile-md {
        margin: auto;
        max-width: 414px;
    }
}

.court-steps-card-list {
    margin-top: var(--vertical-indent);

    @include bp.mobile-md {
        display: flex;
        flex-direction: column;
        gap: var(--vertical-indent);
    }

    @include bp.desktop-md {
        display: flex;
        flex-direction: row;
        /* stylelint-disable-next-line */
        flex-wrap: wrap;
        align-items: stretch;
        gap: var(--vertical-indent) var(--horizontal-indent);
    }
}

.court-steps-card {
    @include bp.desktop-md {
        width: calc(50% - (var(--horizontal-indent) / 2))
    }
}

.court-steps-card__container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 32px;
    height: 100%;
}

.court-steps-card__content {
    display: flex;
    flex-direction: column;
    gap: 20px;

    @include bp.mobile-md {
        margin-top: var(--vertical-indent);
    }

    @include bp.desktop-md {
        margin-top: 32px;
    }
}

.court-steps-card__text-group {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.court-steps__help {
    @include bp.mobile-md {
        padding-top: var(--vertical-indent);
        padding-bottom: 40px;
    }

    @include bp.desktop-md {
        padding-top: 40px;
        padding-bottom: 40px;
    }
}

.court-steps__help-link {
    @include bp.desktop-md {
        width: 336px;
        margin: auto;
    }
}

.court-steps__help-text {
    color: colors.$grey-4;
    font-family: tp.$font-family;
    font-weight: 400;
    text-align: center;
    margin-top: 8px;

    @include bp.mobile-md {
        font-size: tp.$font-size-small;
        line-height: 130%;
        letter-spacing: 0.12px;
    }

    @include bp.desktop-md {
        font-size: tp.$font-size-smaller;
        line-height: 114%;
    }
}
</style>
