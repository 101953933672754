<template>
    <div class="form-field">
        <label
            class="form-field__label"
            :class="hideLabel && 'u-hidden-visually'"
        >
            {{ label }}
            <span
                v-if="uiRequired"
                class="required"
            >*</span>
        </label>

        <div class="form-field__wrapper">
            <div class="form-field__control-wrapper">
                <div
                    class="date-time-picker"
                    :class="hasTime && 'date-time-picker--has-time'"
                >
                    <date-picker-inner
                        :value="innerValue"
                        :is-value-set="isDateSet"
                        :reset-time="!hasTime || !isTimeSet"
                        :is-valid="isDateValid"
                        :errors="dateErrors"
                        :max-date="maxDate"
                        :min-date="minDate"
                        :disabled="disabled"
                        :readonly="readonly"
                        :placeholder="placeholderDate"
                        :date-picker-settings="datePickerSettings"
                        class="date-time date-time-picker__date-picker"
                        @change="setDate"
                    >
                    </date-picker-inner>

                    <time-picker-inner
                        v-if="hasTime"
                        :value="innerValue"
                        :is-value-set="isTimeSet"
                        :is-valid="isTimeValid"
                        :errors="timeErrors"
                        :disabled="disabled"
                        :readonly="readonly"
                        :placeholder="placeholderTime"
                        :date-picker-settings="datePickerSettings"
                        class="date-time date-time-picker__time-picker"
                        @change="setTime"
                    >
                    </time-picker-inner>
                </div>

                <div class="form-field__icon-invalid-wrapper">
                    <div
                        class="form-field__icon-invalid"
                        :class="!isValid && 'is-visible'"
                    >
                        <base-icon icon-name="Внимание">
                            <icon-attention />
                        </base-icon>
                    </div>
                </div>

                <div
                    v-if="hasHelper"
                    class="form-field__helper-wrapper"
                >
                    <form-input-helper
                        :link-url="helperLinkUrl"
                        :link-text="helperLinkText"
                    >
                        {{ helperText }}
                    </form-input-helper>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { DateTime } from 'luxon';
import BaseIcon from '@/components/BaseIcon.vue';
import FormInputHelper from '@/components/FormInputHelper.vue';
import IconAttention from '@/icons/IconAttention.vue';
import DatePickerInner from './DatePickerInner.vue';
import TimePickerInner from './TimePickerInner.vue';

/* как `new Date()`, если бы системной таймзоной был UTC (т.к. в v-calendar ожидает UTC) */
const utcNow = () => DateTime.now().setZone('utc', { keepLocalTime: true });

export default Vue.extend({
    components: {
        DatePickerInner,
        TimePickerInner,
        IconAttention,
        BaseIcon,
        FormInputHelper,
    },

    model: { event: 'change' },

    props: {
        value: {
            type: Number,
            // required: true,
        },
        mode: {
            type: String,
            validator: (value) => ['date', 'dateTime'].includes(value),
            default: 'date',
        },
        label: {
            type: String,
            default: '',
        },
        hideLabel: {
            type: Boolean,
            default: false,
        },
        placeholderDate: {
            type: String,
            default: 'дд.мм.гггг',
        },
        placeholderTime: {
            type: String,
            default: 'чч:мм',
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        uiRequired: {
            type: Boolean,
            default: false,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        isValid: {
            type: Boolean,
            default: true,
        },
        errors: {
            type: Array,
            default: () => ([]),
        },
        helperText: {
            type: String,
            default: '',
        },
        helperLinkUrl: {
            type: String,
            default: '',
        },
        helperLinkText: {
            type: String,
            default: '',
        },
        maxDate: {
            type: Date,
            default: () => utcNow().toJSDate(),
        },
        minDate: {
            type: Date,
            default: () => utcNow().minus({ years: 150 }).toJSDate(),
        },
    },

    data() {
        return {
            /*
            значение хранится во внутреннеем поле и синхронизируется с моделью (`v-model`) вручную,
            чтобы управлять тем, когда это будет происходить, т.к. нужно избежать записи в модель,
            пока не установлены все нужные составляющие (дата + время или только дата),
            а также сброса значения модели, пока не сброшены все составляющие
            */
            innerValue: null,

            /*
            флаги, сообщающие, установлена ли каждая из составляющих
            (пользователем в интерфейсе или родительским компонентом)
            */
            isDateSet: false,
            isTimeSet: false,

            datePickerSettings: Object.freeze({
                locale: 'ru',
                timezone: 'UTC',
                modelConfig: { type: 'number' },
                is24hr: true,
                popover: { visibility: 'click' },
                inputDebounce: 1000,
            }),
        };
    },

    computed: {
        /* техническое поле для того, чтобы watch-ить набор полей */
        innerValueTracker() {
            return [this.innerValue, this.isDateSet, this.isTimeSet];
        },

        dateErrors() {
            return this.getValidationState('date');
        },
        isDateValid() {
            return this.dateErrors.length === 0;
        },

        timeErrors() {
            return this.getValidationState('time');
        },
        isTimeValid() {
            return this.timeErrors.length === 0;
        },

        hasTime() {
            return this.mode === 'dateTime';
        },

        hasHelper(): boolean {
            return (
                !!this.helperText || (this.helperLinkUrl && this.helperLinkText)
            );
        },
    },

    watch: {
        /**
         * Синхронизируем модель (`v-model`) с внутренним значением
         */
        innerValueTracker([datetime, isDateSet, isTimeSet]) {
            /* если установлены все нужные составляющие (дата + время или только дата) */
            if (datetime !== this.value && isDateSet && (isTimeSet || !this.hasTime)) {
                this.$emit('change', datetime);
                return;
            }
            /*
            если сброшены все составляющие, обнуляем модель,
            но только если у нее уже было задано значение
            (иначе преждевременно затриггерится валидация, если начальным значением был не null)
            */
            if (this.value && !isDateSet && !isTimeSet) {
                this.$emit('change', null);
            }
        },

        /**
         * Синхронизируем внутреннее значение с моделью при изменении модели
         * родительским компонентом (или реактивном изменении в ответ на запись в модель
         * на предыдущем тике, см. вотчер выше)
         */
        value: {
            handler(datetime) {
                /*
                не войдет в бесконечный цикл с предыдущим вотчером,
                потому что и старое, и новое значение — примитивы
                */
                this.innerValue = datetime;
                if (!datetime) {
                    this.isDateSet = false;
                    this.isTimeSet = false;
                } else {
                    this.isDateSet = true;
                    this.isTimeSet = this.hasTime;
                }
            },
            immediate: true,
        },
    },

    methods: {
        setDate(datetime) {
            this.isDateSet = Boolean(datetime);
            if (
                /* если дата-время устанавливается */
                datetime
                /* или дата сбрасывается, а время при этом не задано */
                || (!this.isTimeSet || !this.hasTime)
            ) {
                this.innerValue = datetime || null;
            }
        },

        setTime(datetime) {
            this.isTimeSet = Boolean(datetime);
            if (
                /* если дата-время устанавливается */
                datetime
                /* или время сбрасывается, а дата при этом не задана */
                || !this.isDateSet
            ) {
                this.innerValue = datetime || null;
            }
        },

        /**
         * Т.к. модель/значение одно, но в интерфейсе компоненты выбора даты и времени
         * визуально разделены и имеют каждый свое состояние валидации, в обычном массиве ошибок
         * не хватает информации, чтобы понять, к какой составляющей какая ошибка относится,
         и для этого приходится также обращаться ко внутреннему состоянию.
         * Сейчас поддерживает только один вид ошибок — когда дата и/или время не заполнена
         * @todo Если появятся ошибки других типов, нужна будет соответствующая логика.
         * А лучше поменять дизайн!
         * @param {('date'|'time')} unit
         * @returns {String[]} массив сообщений об ошибках
         */
        getValidationState(unit) {
            const errors = this.errors
                .filter((error) => {
                    if (error.type === 'REQUIRED_MISSING') {
                        return unit === 'date' ? !this.isDateSet : !this.isTimeSet;
                    }
                    /*
                    если фильтр(ы) выше не отбросил(и) ошибку,
                    она будет отображаться для обеих составляющих (и даты, и времени)
                    */
                    return true;
                })
                .map((error) => error.message);

            return errors;
        },
    },
});
</script>

<style lang="scss" scoped>
@use "@/styles/mixins/centering" as *;
@use "@/styles/mixins/reset" as *;
@use "@/styles/variables/grid";

.date-time-picker {
    display: block;

    &--has-time {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        grid-gap: grid.$space-2;
    }

    &::v-deep .date-time-picker__input-wrapper {
        position: relative;
    }

    &::v-deep .date-time-picker__toggle-popover {
        @extend %vertically-centered;
        @extend %button-reset;

        position: absolute;
        right: 0;
        padding: grid.$space-1 + grid.$space-05;

        img {
            display: block;
        }
    }

    &::v-deep .vc-date {
        display: none !important;
    }
}

.date-time-picker__date-picker {
    grid-column: span 3;
}

.date-time-picker__time-picker {
    grid-column: span 2;
}
</style>
