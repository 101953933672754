<template>
    <header class="header">
        <div class="container">
            <div class="header__title">
                <a
                    :href="oiLegalBaseURL"
                    class="header__title-logo"
                    :data-gtm="JSON.stringify({itemLocation: 'Шапка'})"
                    @click="trackClick($event, 'externalLinkClick')"
                >
                    <img
                        src="@/images/ovdlegal-logo.svg"
                        alt="Логотип ОВД-Инфо"
                        width="166"
                        height="32"
                    />
                </a>
                <p class="header__title-text">
                    Генератор судебных документов
                </p>
            </div>
            <template v-if="false">
                <auth-widget></auth-widget>
            </template>
        </div>
    </header>
</template>

<script>
import Vue from 'vue';
import clickTrackingMixin from '@/mixins/click-tracking';
import config from '@/config';
import AuthWidget from '@/components/auth/AuthWidget.vue';

export default Vue.extend({
    components: {
        AuthWidget,
    },

    mixins: [clickTrackingMixin],

    data() {
        return {
            oiLegalBaseURL: config.oiLegalBaseURL,
        };
    },
});
</script>

<style lang="scss">
@use "@/styles/variables/colors";
@use "@/styles/variables/layout";
@use "@/styles/mixins/breakpoint" as *;

.header {
    height: layout.$header-height;
    background-color: colors.$dark;

    .container {
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}

.header__title {
    display: flex;
    align-items: center;
}

.header__title-logo {
    display: flex;
    margin-right: 16px;
}

.header__title-text {
    color: colors.$white;
    font-size: 16px;
    margin-top: 4px;

    @include breakpoint(sm) {
        // Visually hidden
        position: absolute !important;
        width: 1px !important;
        height: 1px !important;
        padding: 0 !important;
        overflow: hidden !important;
        clip: rect(1px, 1px, 1px, 1px) !important;
        border: 0 !important;
    }
}
</style>
