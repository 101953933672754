<template>
    <div class="helper">
        <p
            class="helper__text"
            :class="isError && 'helper__text--error'"
        >
            <slot />
        </p>
        <!-- TODO: Make a router link -->
        <a
            v-if="hasLink"
            class="helper__link"
            :href="linkUrl"
        >
            {{ linkText }}
        </a>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';

export default Vue.extend({
    props: {
        linkUrl: {
            type: String,
            default: '',
        },
        linkText: {
            type: String,
            default: '',
        },
        isError: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        hasLink(): boolean {
            return this.linkUrl && this.linkText;
        },
    },
});
</script>

<style lang="scss">
@use "@/styles/variables/colors";

.helper {
    font-size: 14px;
    line-height: 1.4;
}

.helper__text {
    color: colors.$placeholder;
    margin-top: 5px;

    &--error {
        color: colors.$danger;
    }
}

.helper__link {
    display: inline-block;
    padding-top: 8px;
    text-decoration: none;
}
</style>
