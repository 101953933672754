<template>
    <div class="form-field checkbox-group">
        <label
            class="form-field__label checkbox-group__label"
            :class="hideLabel && 'u-hidden-visually'"
        >
            {{ label }}
        </label>
        <div
            class="checkbox-group__group"
            :class="isHorizontal && 'checkbox-group__group--horizontal'"
        >
            <label
                v-for="option in options"
                :key="option.id"
                class="form-field__selector checkbox checkbox-group__checkbox"
            >
                <input
                    v-model="proxyModel"
                    class="checkbox__input"
                    type="checkbox"
                    :value="option.id"
                />
                <span class="form-field__selector-label">{{
                    option.name
                }}</span>
            </label>
        </div>
        <div
            v-if="hasHelper"
            class="form-field__helper-wrapper"
        >
            <form-input-helper
                :link-url="helperLinkUrl"
                :link-text="helperLinkText"
            >
                {{ helperText }}
            </form-input-helper>
        </div>
    </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import makeWithVModelMixin from '@shared/mixins/with-v-model';
import FormInputHelper from '@/components/FormInputHelper.vue';

interface Option {
    name: string;
    id: any;
}

/**
 * Использование с `v-model` в родительском компоненте:
 *
 * на чтение: те чекбоксы из группы, чей атрибут `value` (`id` соответствующего `option`) содержится
 * в модели, становятся выбранными; остальные - невыбранными
 * на запись: при выборе чекбокса из группы значение его атрибута `value` (`option.id`) добавляется
 * в модель, при снятии - удаляется
 *
 */
export default Vue.extend({
    components: {
        FormInputHelper,
    },

    mixins: [
        makeWithVModelMixin({
            prop: 'checked',
            event: 'change',
        }),
    ],

    props: {
        name: {
            type: String,
            required: true,
        },
        checked: {
            type: Array,
            default: () => [],
        },
        options: {
            type: Array as PropType<Option[]>,
            required: true,
        },
        label: {
            type: String,
            default: '',
        },
        hideLabel: {
            type: Boolean,
            default: false,
        },
        isHorizontal: {
            type: Boolean,
            default: false,
        },
        helperText: {
            type: String,
            default: '',
        },
        helperLinkUrl: {
            type: String,
            default: '',
        },
        helperLinkText: {
            type: String,
            default: '',
        },
    },

    computed: {
        hasHelper() {
            return (
                this.helperText || (this.helperLinkUrl && this.helperLinkText)
            );
        },
    },
});
</script>

<style lang="scss">
.checkbox-group {
    padding-right: 32px;
}

.checkbox-group__label {
    margin-bottom: 16px;
}

.checkbox-group__checkbox:not(:last-child) {
    margin-bottom: 12px;
}

.checkbox-group__group {
    &--horizontal {
        display: flex;
    }

    &--horizontal > .form-field {
        margin-bottom: 0;
    }

    &--horizontal > .form-field:not(:last-of-type) {
        margin-right: 36px;
    }
}
</style>
