<template>
    <div class="court-steps-navigation">
        <ul class="court-steps-navigation-items">
            <li class="court-steps-navigation-item">
                <a
                    href="#court-steps-first-court"
                    class="court-steps-navigation-item__counter"
                >
                    <ui-counter
                        interactive
                        bordered
                    >
                        1
                    </ui-counter>
                </a>
                <div class="court-steps-navigation-item__text">
                    <ui-paragraph align="center">
                        Получил протокол
                    </ui-paragraph>
                </div>
            </li>
            <li class="court-steps-navigation-item">
                <a
                    href="#court-steps-appeal-court"
                    class="court-steps-navigation-item__counter"
                >
                    <ui-counter
                        interactive
                        bordered
                    >
                        2
                    </ui-counter>
                </a>
                <div class="court-steps-navigation-item__text">
                    <ui-paragraph align="center">
                        Хочу&nbsp;обжаловать
                    </ui-paragraph>
                </div>
            </li>
            <li class="court-steps-navigation-item">
                <a
                    href="#court-steps-court-of-cassation"
                    class="court-steps-navigation-item__counter"
                >
                    <ui-counter
                        interactive
                        bordered
                    >
                        3
                    </ui-counter>
                </a>
                <div class="court-steps-navigation-item__text">
                    <ui-paragraph align="center">
                        Получил&nbsp;решение
                        хочу&nbsp;обжаловать
                    </ui-paragraph>
                </div>
            </li>
            <li class="court-steps-navigation-item">
                <a
                    href="#court-steps-supreme-court"
                    class="court-steps-navigation-item__counter"
                >
                    <ui-counter
                        interactive
                        bordered
                    >
                        4
                    </ui-counter>
                </a>
                <div class="court-steps-navigation-item__text">
                    <ui-paragraph align="center">
                        Подаю&nbsp;жалобу
                        в&nbsp;Верховный&nbsp;суд
                    </ui-paragraph>
                </div>
            </li>
        </ul>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';

import UiCounter from '../../UiCounter.vue';
import UiParagraph from '../../UiParagraph.vue';


export default Vue.extend({
    name: 'CourtStepsNavigation',
    components: {
        UiCounter,
        UiParagraph,
    },
});
</script>

<style lang="scss" scoped>
@use "@/styles/mixins/breakpoint" as bp;
@use "@/styles/variables/colors";
@use "@/styles/variables/typography" as tp;
@import "@/styles/container";


.court-steps-navigation {
    margin-top: 80px;

    @include bp.mobile-md {
        display: flex;
        overflow-x: scroll;
        overflow-y: hidden;

        &::-webkit-scrollbar {
            display: none;
        }
    }
}

.court-steps-navigation-items {
    display: flex;
    flex-direction: row;

    @include bp.mobile-md {
        margin: 0 auto;
        padding-right: $container-padding-sm-horizontal;
    }

    @include bp.desktop-md {
        justify-content: center;
    }
}


/* Steps grid */
.court-steps-navigation-item {
    @include bp.mobile-md {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 6px;
    }

    @include bp.desktop-md {
        display: grid;
        grid-template-rows: 1fr 1fr 1fr;
        justify-items: center;
        gap: 6px;
    }

    .court-steps-navigation-item__counter {
        color: inherit;
        text-decoration: none;

        @include bp.desktop-md {
            grid-row: 2 / 2;
        }
    }

    &:nth-child(odd) .court-steps-navigation-item__text {
        @include bp.desktop-md {
            grid-row: 3 / 3;
        }
    }

    &:nth-child(even) .court-steps-navigation-item__text {
        @include bp.desktop-md {
            align-self: end;
        }
    }
}


/* Steps sizes and images */

/*
    Размеры вертикальных колонок, размеры и положение картинок со следами
    захардкожены по макетам
*/
.court-steps-navigation-item { /* stylelint-disable-line */
    position: relative;

    &::after {
        content: "";
        position: absolute;
        background-position: center;
        background-size: contain;

        @include bp.desktop-md {
            top: 50%;
            transform: translateY(-50%);
        }
    }

    &:nth-child(1) {
        @include bp.mobile-md {
            width: 89px;
            margin-right: 50px;

            &::after {
                left: 97%;
                top: 17px;
                width: 66px;
                height: 27px;
                background-image: url("./img/steps-mobile-1.svg");
            }
        }

        @include bp.desktop-md {
            width: 166px;
            margin-right: 84px;

            &::after {
                left: 82%;
                width: 138px;
                height: 33px;
                background-image: url("./img/steps-desktop-1.svg");
            }
        }
    }

    &:nth-child(2) {
        @include bp.mobile-md {
            width: 125px;
            margin-right: 55px;

            &::after {
                left: 89%;
                top: 11px;
                width: 81px;
                height: 32px;
                background-image: url("./img/steps-mobile-2.svg");
            }
        }

        @include bp.desktop-md {
            width: 155px;
            margin-right: 61px;

            &::after {
                left: 85%;
                width: 113px;
                height: 33px;
                background-image: url("./img/steps-desktop-2.svg");
            }
        }
    }

    &:nth-child(3) {
        @include bp.mobile-md {
            width: 131px;
            margin-right: 62px;

            &::after {
                left: 83%;
                top: 11px;
                width: 100px;
                height: 32px;
                background-image: url("./img/steps-mobile-3.svg");
            }
        }

        @include bp.desktop-md {
            width: 163px;
            margin-right: 52px;

            &::after {
                left: 76%;
                width: 112px;
                height: 33px;
                background-image: url("./img/steps-desktop-3.svg");
            }
        }
    }

    &:nth-child(4) {
        @include bp.mobile-md {
            width: 120px;
        }

        @include bp.desktop-md {
            width: 150px;
        }
    }
}
</style>
