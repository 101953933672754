<template>
    <div class="document-selector">
        <base-select
            id="document-selector-dropdown"
            v-model="selectedArticle"
            :options="articles"
            hide-label
            hide-error-message
            name="articles"
            label="Выберите статью"
            class="document-selector__select"
        />
        <base-button
            type="link"
            :to="getDocUrlById(selectedArticle)"
            :disabled="!selectedArticle"
            view="secondary"
            gtm-click-event="downloadClick"
            :data-gtm="JSON.stringify({itemLocation: gtmLocation})"
        >
            <template #icon>
                <base-icon>
                    <icon-download />
                </base-icon>
            </template>
            {{ buttonText }}
        </base-button>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';

import BaseButton from '@/components/button/BaseButton.vue';
import BaseSelect from '@/components/select/BaseSelect.vue';
import BaseIcon from '@/components/BaseIcon.vue';
import IconDownload from '@/icons/IconDownload.vue';

export default Vue.extend({
    name: 'DocumentSelector',

    components: {
        BaseButton,
        BaseSelect,
        BaseIcon,
        IconDownload,
    },

    props: {
        articles: {
            type: Array,
            default: () => [],
        },
        buttonText: {
            type: String,
            default: '',
        },
        gtmLocation: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            selectedArticle: null,
        };
    },

    methods: {
        getDocUrlById(id) {
            const article = this.articles.find((item) => item.id === id);
            return article
                ? `https://docs.google.com/document/export?format=docx&id=${article.doc}`
                : null;
        },
    },
});
</script>

<style lang="scss">
@use "@/styles/variables/colors";
@use "@/styles/variables/grid";

.document-selector {
    max-width: 297px;
}

.document-selector__select {
    margin-bottom: grid.$space-3;
}
</style>
