<template>
    <div
        class="ui-card"
        :class="{
            'ui-card--full-height': fullHeight,
        }"
    >
        <slot />
    </div>
</template>

<script lang="ts">
import Vue from 'vue';

export default Vue.extend({
    props: {
        fullHeight: {
            type: Boolean,
            default: false,
        },
    },
});
</script>

<style lang="scss" scoped>
@use "@/styles/mixins/breakpoint" as bp;
@use "@/styles/variables/colors";

.ui-card {
    box-sizing: border-box;
    background-color: colors.$grey-1;
    border-radius: 20px;
    overflow: hidden;

    @include bp.mobile-md {
        max-width: 414px;
        padding: 20px;
    }

    @include bp.desktop-md {
        padding: 40px;
    }

    &.ui-card--full-height {
        height: 100%;
    }
}
</style>
