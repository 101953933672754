import { resetField } from './utils';

/* eslint-disable quote-props */
export default {
    'пострадавший_корреспонденция_по_адресу_регистрации': [{
        match: (value) => Boolean(value),
        mutate: (state) => {
            resetField(state, { fieldName: 'пострадавший_адрес_для_корреспонденции' });
        },
    }],

    'акция_заявитель_не_участвовал': [
        {
            match: (value) => !value,
            mutate: (state) => {
                resetField(state, { fieldName: 'акция_подтверждение_что_не_участвовал' });
            },
        },
        {
            match: (value) => Boolean(value),
            mutate: (state) => {
                resetField(state, { fieldName: 'задержание_отказ_от_слов_про_неучастие_в_акции' });
            },
        },
    ],

    'задержание_ОВД': [{
        match: (value) => Boolean(value),
        mutate: (state) => {
            resetField(state, { fieldName: 'задержание_ОВД_не_из_базы' });
        },
    }],

    'задержание_недопуск_защитника_в_ОВД': [{
        match: (value) => !value,
        mutate: (state) => {
            resetField(state, { fieldName: 'задержание_защитник_ФИО' });
            resetField(state, { fieldName: 'задержание_недопуск_защитника_в_ОВД_крепость' });
        },
    }],

    'задержание_изъяли_телефон_в_ОВД': [{
        match: (value) => !value,
        mutate: (state) => {
            resetField(state, { fieldName: 'задержание_описание_телефона' });
        },
    }],

    'суд1_наименование': [{
        match: (value) => Boolean(value),
        mutate: (state) => {
            resetField(state, { fieldName: 'суд1_наименование_не_из_базы' });
        },
    }],

    'суд1_уведомление_заявителя': [
        {
            match: (value) => !value,
            mutate: (state) => {
                resetField(state, { fieldName: 'суд1_уведомление_не_соблюден_срок' });
            },
        },
        {
            match: (value, state) => (
                Boolean(value) && !state.fields['суд1_уведомление_не_соблюден_срок']
            ),
            mutate: (state) => {
                resetField(state, { fieldName: 'суд1_явка_заявителя' });
            },
        },
    ],

    'суд1_уведомление_не_соблюден_срок': [{
        match: (value, state) => !value && state.fields['суд1_уведомление_заявителя'],
        mutate: (state) => {
            resetField(state, { fieldName: 'суд1_явка_заявителя' });
        },
    }],

    'суд1_явка_заявителя': [{
        match: (value) => !value,
        mutate: (state) => {
            resetField(state, { fieldName: 'суд1_ходатайство_об_отложении' });
        },
    }],

    'дело_наказание': [
        {
            match: (value) => value !== 'Арест',
            mutate: (state) => {
                resetField(state, { fieldName: 'дело_дата_и_время_начала_ареста' });
                resetField(state, { fieldName: 'дело_присужденные_сутки_ареста' });
                resetField(state, { fieldName: 'дело_суд_неверно_рассчитал_срок_ареста' });
            },
        },
        {
            match: (value) => value !== 'Штраф',
            mutate: (state) => {
                resetField(state, { fieldName: 'дело_размер_штрафа' });
                resetField(state, { fieldName: 'дело_основания_чрезмерности_штрафа' });
            },
        },
        {
            match: (value) => value !== 'Обязательные работы',
            mutate: (state) => {
                resetField(state, { fieldName: 'дело_работы_часы' });
            },
        },
    ],

    'дело_основания_чрезмерности_штрафа': [
        {
            match: (value) => !value.includes('имею на иждивении несовершеннолетних детей'),
            mutate: (state) => {
                resetField(state, { fieldName: 'дело_количество_иждивенцев' });
            },
        },
        {
            match: (value) => !value.includes('являюсь инвалидом группы'),
            mutate: (state) => {
                resetField(state, { fieldName: 'дело_группа_инвалидности' });
            },
        },
    ],

    'дело_фото_видео_задержания': [{
        match: (value) => !value,
        mutate: (state) => {
            resetField(state, { fieldName: 'дело_фото_видео_что_запечатлено' });
        },
    }],

    'суд1_посредством_ВКС': [{
        match: (value) => !value,
        mutate: (state) => {
            resetField(state, { fieldName: 'суд1_согласие_на_ВКС' });
            resetField(state, { fieldName: 'суд1_тип_ВКС' });
        },
    }],

    'суд2_наименование': [{
        match: (value) => Boolean(value),
        mutate: (state) => {
            resetField(state, { fieldName: 'суд2_наименование_не_из_базы' });
        },
    }],

    'суд2_вызов_свидетелей_задержания': [{
        match: (value) => !value,
        mutate: (state) => {
            resetField(state, { fieldName: 'суд2_данные_свидетелей_задержания' });
        },
    }],
};
/* eslint-enable quote-props */
