<template>
    <div
        class="form-end-screen-pic"
        :class="[
            `form-end-screen-pic--${status}`,
            desktopWideLayout ? 'form-end-screen-pic--desktop-wide' : null
        ]"
        role="presentation"
    ></div>
</template>

<script>
import Vue from 'vue';

/** Декоративный компонент */
export default Vue.extend({
    props: {
        status: {
            type: String,
            default: 'success',
        },
        desktopWideLayout: {
            type: Boolean,
            default: true,
        },
    },
});
</script>

<style lang="scss" scoped>
@use "@/styles/mixins/breakpoint";

%pseudo-pic {
    content: "";
    display: block;
    width: var(--width);
    padding-top: calc(var(--ratio) * var(--width));
    background-size: contain;
    background-repeat: no-repeat;
}

.form-end-screen-pic {
    position: relative;

    &.form-end-screen-pic--success {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;

        &::before,
        &::after {
            @extend %pseudo-pic;
        }

        &::before {
            --ratio: #{(172 / 320)};
            --width: 28%;

            background-image: url("@/images/paper-plane-2-small.png");
            background-position: top left;
        }

        &::after {
            --ratio: #{(436 / 842)};
            --width: 72%;

            background-image: url("@/images/paper-plane-1.png");
            background-position: top right;
        }

        &.form-end-screen-pic--desktop-wide {
            &::before {
                @include breakpoint.desktop {
                    --width: 160px;

                    background-image: url("@/images/paper-plane-2.png");
                }
            }

            &::after {
                @include breakpoint.desktop {
                    --width: 421px;

                    transform: translateX(40%);
                }
            }
        }
    }

    &.form-end-screen-pic--failure {
        &::before {
            @extend %pseudo-pic;

            --ratio: #{(472 / 594)};
            --width: 47%;

            margin: 0 auto;
            background-image: url("@/images/paper-plane-bin-small.png");
            background-position: center;
        }

        &.form-end-screen-pic--desktop-wide {
            &::before {
                --width: 297px;

                @include breakpoint.desktop {;
                    background-image: url("@/images/paper-plane-bin.png");
                }
            }
        }
    }
}
</style>
