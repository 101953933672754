<template>
    <modal-overlay
        :is-open="isOpen"
        @close="$emit('close')"
    >
        <sign-in @cancel="$emit('close')" />
    </modal-overlay>
</template>

<script>
import Vue from 'vue';
import { createNamespacedHelpers } from 'vuex';
import ModalOverlay from '@/components/modal/ModalOverlay.vue';
import SignIn from './SignIn.vue';

const { mapActions } = createNamespacedHelpers('auth');

export default Vue.extend({
    components: {
        ModalOverlay,
        SignIn,
    },

    props: {
        isOpen: {
            type: Boolean,
            required: true,
        },
    },

    watch: {
        isOpen: {
            handler(isOpen) {
                if (isOpen) {
                    this.startFillingForm();
                } else {
                    this.completeFillingForm();
                }
            },
            immediate: true,
        },
    },

    methods: {
        ...mapActions([
            'startFillingForm',
            'completeFillingForm',
        ]),
    },
});
</script>
