<template>
    <div
        class="docs-group"
        :class="{ 'docs-group--active': open }"
    >
        <div
            class="docs-group__header"
            :class="{ 'docs-group__header--active': open }"
        >
            <div class="docs-group__header-text">
                <h3 class="docs-group__title">
                    {{ title }}
                </h3>
                <p class="docs-group__descriptor">
                    <slot name="description" />
                </p>
                <p
                    v-if="deadline"
                    class="docs-group__hint"
                >
                    <span class="docs-group__hint-accent">Срок подачи:</span>
                    <span v-html="deadline"></span>
                </p>
            </div>
            <div class="u-display-none-mobile">
                <expand-button
                    cover
                    :collapse="open"
                    :inverted="open"
                    @click="() => (open = !open)"
                ></expand-button>
            </div>
            <div class="u-display-none u-display-block-mobile">
                <expand-button
                    outlined
                    :collapse="open"
                    :inverted="open"
                    @click="() => (open = !open)"
                ></expand-button>
            </div>
        </div>
        <div
            v-if="open"
            class="docs-group__body"
        >
            <div class="doc-group__body-inner">
                <slot></slot>
            </div>
            <div class="docs-group__dinotivator">
                <div class="docs-group__message">
                    <span>{{ dinoMessage }}</span>
                </div>
                <div class="docs-group__links">
                    <icon-button
                        link
                        :to="`${oiLegalBaseURL}/instruction/help-yourself`"
                        class="docs-group__link"
                        :data-gtm="JSON.stringify({itemLocation: gtmLocation})"
                    >
                        <template #icon>
                            <base-icon><icon-file /></base-icon>
                        </template>
                        Инструкция по защите в суде
                    </icon-button>
                    <icon-button
                        link
                        to="https://t.me/OvdInfoBot"
                        class="docs-group__link"
                        :data-gtm="JSON.stringify({itemLocation: gtmLocation})"
                    >
                        <template #icon>
                            <base-icon><icon-telegram /></base-icon>
                        </template>
                        Чат-бот
                    </icon-button>
                    <icon-button
                        link
                        to="mailto:legal@ovdinfo.org"
                        class="docs-group__link"
                        :data-gtm="JSON.stringify({itemLocation: gtmLocation})"
                    >
                        <template #icon>
                            <base-icon><icon-mail /></base-icon>
                        </template>
                        legal@ovdinfo.org
                    </icon-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import config from '@/config';
import BaseIcon from '@/components/BaseIcon.vue';
import IconButton from '@/components/button/IconButton.vue';
import ExpandButton from '@/components/button/ExpandButton.vue';
import IconTelegram from '@/icons/IconTelegram.vue';
import IconFile from '@/icons/IconFile.vue';
import IconMail from '@/icons/IconMail.vue';

export default Vue.extend({
    components: {
        BaseIcon,
        IconButton,
        IconFile,
        IconTelegram,
        IconMail,
        ExpandButton,
    },
    props: {
        isOpen: {
            type: Boolean,
            default: false,
        },
        title: {
            type: String,
            default: '',
        },
        deadline: {
            type: String,
            default: '',
        },
        dinoMessage: {
            type: String,
            default: '',
        },
        gtmLocation: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            oiLegalBaseURL: config.oiLegalBaseURL,
            open: this.isOpen,
        };
    },
});
</script>

<style lang="scss" scoped>
@use "@/styles/mixins/breakpoint" as *;
@use "@/styles/variables/colors";
@use "@/styles/variables/grid";

.docs-group {
    --docs-group-shadow: 0px 9px 15px rgba(178 181 190 / 29%);

    @include breakpoint(sm) {
        --docs-group-shadow: 0px 9px 15px rgba(132 165 120 / 15%);
    }

    margin-bottom: grid.$space-3;
    border: 1px solid var(--primary-color-alpha);
    border-radius: grid.$space-1;
    overflow: hidden;
    box-shadow: var(--docs-group-shadow);
    transition: box-shadow 0.15s ease;

    @include breakpoint(sm) {
        margin-bottom: grid.$space-1;
    }

    &:hover {
        box-shadow: none;

        @media (hover: none) {
            box-shadow: var(--docs-group-shadow);
        }
    }
}

.docs-group--active {
    &:hover {
        box-shadow: var(--docs-group-shadow);
    }
}

.docs-group__hint {
    color: colors.$grey;
    line-height: 1.5;
}

.docs-group__hint-accent {
    color: colors.$generaptor-brand-dark;
    font-weight: 500;
}

.docs-group__descriptor {
    color: colors.$grey;
    line-height: 1.5;

    @include breakpoint(sm) {
        line-height: 1.4;
    }

    &:not(:last-child) {
        margin-bottom: 8px;
    }
}

.docs-group__title {
    margin-bottom: 0;
    font-size: 24px;
    color: colors.$generaptor-brand-dark;
    letter-spacing: -0.03em;

    @include breakpoint(sm) {
        margin-bottom: 8px;
    }
}

.docs-group__header {
    position: relative;
    display: block;
    padding: grid.$space-4 grid.$space-6;
    cursor: pointer;

    @include breakpoint(sm) {
        padding: grid.$space-2;
    }

    &--active {
        background-color: colors.$generaptor-brand;

        .docs-group__title {
            color: colors.$white;
        }

        .docs-group__descriptor {
            color: colors.$white;
        }

        .docs-group__hint {
            color: colors.$white;
        }

        .docs-group__hint-accent {
            color: colors.$white;
        }

        .docs-group__header-icon {
            transform: rotate(180deg);
            color: colors.$white;
        }
    }
}

.docs-group__header-text {
    max-width: 852px;
    margin-bottom: 24px;
}

.doc-group__body-inner {
    padding: grid.$space-4 grid.$space-6 grid.$space-8;
    color: colors.$secondary-grey;

    @include breakpoint(sm) {
        padding: grid.$space-3 grid.$space-2 grid.$space-3 + grid.$space-1;
    }
}

.docs-group__dinotivator {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-gap: 16px;
    padding: 32px 48px;
    position: relative;
    min-height: 160px;
    background-color: colors.$generaptor-brand-bg;
    background-image: url("@/images/dino-head.png");
    background-repeat: no-repeat;
    background-size: 207px 160px;
    background-position-x: left;
    background-position-y: bottom;

    @include breakpoint(md) {
        display: block;
        background-image: none;
    }

    @include breakpoint(sm) {
        padding: 32px 16px;
    }
}

.docs-group__message {
    position: absolute;
    top: 24px;
    left: 207px;
    width: 400px;
    height: 76.5px;
    padding-top: 18px;
    padding-bottom: 26px;
    background-image: url("@/images/balloon.svg");
    background-size: contain;
    background-repeat: no-repeat;
    text-align: center;
    font-weight: 500;
    font-size: 24px;
    line-height: 1.35;
    letter-spacing: -0.03em;
    color: colors.$secondary-grey;

    @include breakpoint(md) {
        display: none;
    }
}

.docs-group__links {
    grid-column: 10 / 12;

    @include breakpoint(md) {
        display: flex;
        flex-direction: column;
    }
}

.docs-group__link:not(:last-child) {
    margin-bottom: 12px;
}
</style>
