/**
 * Миксин для сбора кастомной аналитики по кликам
 *
 * @example
 * Vue.component('Foo', {
 *   mixins: [clickTrackingMixin],
 *   template: `
 *     <a
 *       :data-gtm="JSON.stringify({itemLocation: 'foo'})"
 *       @click="trackClick($event, 'fooClick')"
 *     >Click me</a>
 *   `,
 * });
 */
export default {
    methods: {
        /**
         * Обработчик DOM-события (обычно клика)
         * @param {UIEvent} ev – браузерное событие
         * @param {String} trackingEvent – название события в аналитике
         */
        trackClick(ev, trackingEvent) {
            const targetEl = ev.currentTarget;
            const linkHref = targetEl.href;
            const trackingData = JSON.parse(targetEl.dataset.gtm || null) || {};
            trackingData.event = trackingEvent;
            if ([
                'internalLinkClick',
                'externalLinkClick',
                'downloadClick',
            ].includes(trackingEvent)) {
                trackingData.linkTarget = linkHref;
            }
            this.$store.dispatch('tracking/track', trackingData);
        },
    },
};
