<template>
    <div class="cs-document">
        <p class="cs-document__title">
            Как&nbsp;получить нужный документ
        </p>
        <ui-card>
            <div class="cs-document__card-content">
                <img
                    class="cs-document__generaptor"
                    src="./generaptor-on-the-steps.png"
                    alt="
                        ОВД-Инфо Генераптор динозаврик документы в суд, ходатайства, жалобы картинка
                    "
                />
                <ui-h2>
                    Для создания документа в&nbsp;суд, ходатайства, жалобы тебе нужно:
                </ui-h2>
                <ul class="cs-document__generation-steps">
                    <li class="cs-document__generation-step">
                        <ui-paragraph>
                            <em>1. Заполнить анкету</em>
                        </ui-paragraph>
                    </li>
                    <li class="cs-document__generation-step">
                        <ui-paragraph>
                            <em>2. Проверить документ</em>
                            <br />
                            Ты&nbsp;можешь поправить его сам. Если не&nbsp;получается,
                            <a
                                :href="tgBotURL"
                                _target="blank"
                                :data-gtm="JSON.stringify({itemLocation: gtmLocation})"
                                @click="trackClick($event, 'externalLinkClick')"
                            >позови юриста</a>.
                        </ui-paragraph>
                    </li>
                    <li class="cs-document__generation-step">
                        <ui-paragraph>
                            <em>3. Распечатать документ</em>
                        </ui-paragraph>
                    </li>
                    <li class="cs-document__generation-step">
                        <ui-paragraph>
                            <em>
                                4. Подписать документ на&nbsp;последней страничке
                                и&nbsp;ходтайства (если ты&nbsp;их&nbsp;генерировал)
                            </em>
                        </ui-paragraph>
                    </li>
                    <li class="cs-document__generation-step">
                        <ui-paragraph>
                            <em>5. Финишная прямая. Документы у&nbsp;тебя в&nbsp;руках!</em>
                        </ui-paragraph>
                    </li>
                    <li class="cs-document__generation-step">
                        <ui-paragraph>
                            Подать их можно лично в&nbsp;канцелярию суда, а&nbsp;можно
                            отправить в&nbsp;суд почтой. Лучше отправлять
                            ценным письмом с&nbsp;описью вложенных в&nbsp;конверт
                            документов, а&nbsp;чек после отправки сохранить.
                        </ui-paragraph>
                    </li>
                </ul>
                <div class="cs-document__action">
                    <base-button
                        type="route"
                        to="/filing/court-motions"
                        full-width
                        :data-gtm="JSON.stringify({itemLocation: gtmLocation})"
                    >
                        Сгенерировать документ
                    </base-button>
                </div>
            </div>
        </ui-card>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';

import config from '@/config';
import clickTrackingMixin from '@/mixins/click-tracking';
import BaseButton from '../../../../button/BaseButton.vue';
import UiCard from '../../UiCard.vue';
import UiParagraph from '../../UiParagraph.vue';
import UiH2 from '../../UiH2.vue';


export default Vue.extend({
    name: 'CourtStepsDocument',

    components: {
        UiCard,
        UiParagraph,
        UiH2,
        BaseButton,
    },

    mixins: [clickTrackingMixin],

    props: {
        gtmLocation: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            tgBotURL: config.tgBotURL,
        };
    },
});
</script>

<style lang="scss" scoped>
@use "@/styles/mixins/breakpoint" as bp;
@use "@/styles/variables/colors";
@use "@/styles/variables/typography" as tp;

.cs-document {
    @include bp.mobile-md {
        margin: 80px auto 0;
        max-width: 414px;
    }

    @include bp.desktop-md {
        margin-top: 52px;
    }
}

.cs-document__title {
    color: colors.$secondary-grey;
    font-family: tp.$font-family;
    font-weight: 500;

    @include bp.mobile-md {
        margin-bottom: 20px;
        font-size: 28px;
        line-height: 130%;
        letter-spacing: -0.56px;
    }

    @include bp.desktop-md {
        margin-bottom: 40px;
        font-size: 36px;
        line-height: 140%;
        letter-spacing: -0.72px;
    }
}

.cs-document__card-content {
    @include bp.desktop-md {
        position: relative;
        max-width: 560px;
    }
}

.cs-document__generaptor {
    @include bp.mobile-md {
        display: none;
    }

    @include bp.desktop-md {
        position: absolute;
        left: 106%;
        top: -40px;
    }
}

.cs-document__generation-steps {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-top: 20px;
}

.cs-document__action {
    margin-top: 32px;

    @include bp.desktop-md {
        width: 300px;
    }
}
</style>
