<template>
    <base-icon
        :width="10"
        :height="10"
        :vb-width="10"
        :vb-height="10"
    >
        <icon-close />
    </base-icon>
</template>

<script lang="ts">
import Vue from 'vue';
import BaseIcon from '@/components/BaseIcon.vue';
import IconClose from '@/icons/IconClose.vue';

export default Vue.extend({
    components: {
        BaseIcon,
        IconClose,
    },
});
</script>
