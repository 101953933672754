<template>
    <div
        class="form-field radio"
        :class="!isValid && 'radio--invalid'"
    >
        <div class="form-field__selector-wrapper">
            <label class="form-field__selector">
                <input
                    v-model="proxyModel"
                    type="radio"
                    :name="name"
                    :value="value"
                    :disabled="disabled"
                    class="radio__input"
                />
                <span
                    class="form-field__selector-label"
                    :class="hideLabel && 'u-hidden-visually'"
                >{{ label }}</span>
            </label>
        </div>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import makeWithVModelMixin from '@shared/mixins/with-v-model';

/**
 * Использование с `v-model` в родительском компоненте:
 *
 * на чтение: при совпадении значения модели с атрибутом `value` переключатель
 * становится выбранным, в противном случае - невыбранным
 * на запись: при выборе переключателя значение его атрибута `value` записывается в модель
 *
 * (т.е. интерфейс тот же, что и у нативного <input type="radio" />)
 */
export default Vue.extend({
    mixins: [
        makeWithVModelMixin({
            prop: 'checked',
            event: 'change',
        }),
    ],

    props: {
        name: {
            type: String,
            required: true,
        },
        value: {},
        checked: {},
        disabled: {
            type: Boolean,
            default: false,
        },
        label: {
            type: String,
            required: false,
            default: '',
        },
        hideLabel: {
            type: Boolean,
            default: false,
        },
        isValid: {
            type: Boolean,
            default: true,
        },
    },
});
</script>

<style lang="scss" scoped>
@use "@/styles/variables/colors";
@use "@/styles/form-controls" as *;

.radio__input {
    position: relative;
    top: 0;
    left: 0;
    width: 28px;
    height: 28px;
    flex-shrink: 0;
    border: 0;
    cursor: pointer;
    appearance: none;
}

.radio__input::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 28px;
    height: 28px;
    border: 1px solid colors.$border;
    border-radius: 50%;

    .radio--invalid & {
        @extend %form-field-control-invalid-styles;
    }
}

.radio__input:checked::after {
    content: "";
    position: absolute;
    top: calc(50% - 5px);
    left: calc(50% - 5px);
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: colors.$text;
}
</style>
