export default {
    apiUrl: process.env.API,
    env: process.env.APP_ENV,
    generaptorDomain: process.env.GENERAPTOR_DOMAIN,
    generaptorBaseURL: `https://${process.env.GENERAPTOR_DOMAIN}`,
    oiLegalDomain: process.env.OI_LEGAL_DOMAIN,
    oiLegalBaseURL: `https://${process.env.OI_LEGAL_DOMAIN}`,
    tgBotURL: 'https://t.me/OvdInfoBot',
    sentryDSN: `https://445351f31f974d5ebcad9ec464efc02a@sntr.${process.env.OI_LEGAL_DOMAIN}/8`,
    yMetrikaID: 27284147,
};
