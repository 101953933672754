export const explanationByArticle = [
    {
        name: 'Ст. 19.3 КоАП РФ',
        id: 'option1',
        doc: '1bSfFYqmKEJ1rpl-06aRICWbTeKDI7HYpSuIq-ychjp4',
    },
    {
        name: 'Ч. 1 ст. 20.6.1 КоАП РФ',
        id: 'option2',
        doc: '1FOlAe1z-Ffc0NRVCNNmnGo7AvCiYOGXKRkLbC3YGxz8',
    },
    {
        name: 'Ч. 2 ст. 20.2 КоАП РФ',
        id: 'option3',
        doc: '1e24m9dYiZx-T2UWFj4zcokToi9OEx8vdipJhK0lDBS8',
    },
    {
        name: 'Ч. 5 ст. 20.2 КоАП РФ',
        id: 'option4',
        doc: '1A0cOnBvJWxYxxwgc6-hL4mFFSy88x2Crgcwg7paICCY',
    },
    {
        name: 'Ч. 6.1 ст. 20.2 КоАП РФ',
        id: 'option5',
        doc: '1Tv2hbn_fIWcur7XHzhu_UXFPHsICbC2lcSpOsHfFLBA',
    },
    {
        name: 'Ч. 8 ст. 20.2 КоАП РФ',
        id: 'option6',
        doc: '1CINl27Pcff0dhHUyZEHUaRkPZlD_NZ2b9KsOh_RK1Ns',
    },
    {
        name: 'Ч. 1 ст. 20.2.2 КоАП РФ',
        id: 'option7',
        doc: '1bNb2u7mX2sx9KpgB755K5GN1THEjPXhwYb6WLHZknMc',
    },
    {
        name: 'Ч. 1 ст. 20.3.3 КоАП РФ (выход на акцию)',
        id: 'option8',
        doc: '1XtNKRQ0rf97mUSypGGLxPHFqGuuLnYDHDlWsl7mjA8Y',
    },
    {
        name: 'Ч. 2 ст. 20.3.3 КоАП РФ',
        id: 'option9',
        doc: '1FOOObjNxNyPb_wQ3Ij3wMFZDC_LaIHM8aZmPDGmhc3M',
    },
];
