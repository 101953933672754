<template>
    <div class="app app--generaptor">
        <div class="app__header">
            <the-header />
        </div>
        <div class="app__content">
            <router-view />
        </div>
        <div class="app__footer">
            <the-footer />
        </div>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';

import config from '@/config';
import TheHeader from './components/TheHeader.vue';
import TheFooter from './components/TheFooter.vue';

export default Vue.extend({
    name: 'App',

    components: {
        TheHeader,
        TheFooter,
    },

    created() {
        if (this.$route.name === 'emailConfirmation') {
            /*
            на этой странице токена в параметре может не быть в некоторых сценариях
            (например, в случае перехода по истекшей ссылке);
            если при этом есть текущая сессия, то чтобы не обрывать ее, просто не делаем ничего
            */
            if (this.$route.query.access_token) {
                this.$store.commit('auth/token', this.$route.query.access_token);
            }
        } else {
            // this.$store.dispatch('auth/refreshTokens');
            this.$store.dispatch('abTests/init');
            this.$store.dispatch('tracking/track', { env: config.env });
        }
    },
});
</script>

<style lang="scss">
@use "./styles/variables/colors";

.app {
    display: flex;
    flex-direction: column;
    min-height: 100%;

    // Themed css vars

    &--generaptor {
        --bg-color: #{colors.$generaptor-brand-bg};
        --primary-color: #{colors.$generaptor-brand};
        --primary-color-alpha: #{colors.$generaptor-brand-alpha};
        --title-color: #{colors.$generaptor-brand-dark};
    }
}

.app__header {
    position: sticky;
    top: 0;
    z-index: 999;
}

.app__content {
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
}

.app__footer {
    flex-shrink: 0;
}
</style>
