import { errorMessages } from '../constants';

/**
 * Миксин, с помощью которого компоненты могут создавать человекочитаемые представления
 * ошибок валидации в формате ajv
 */
export default {
    computed: {
        validationErrorsExtended() {
            return this.errors.map((error) => {
                const errorData = this.getValidationErrorMessage(error);
                if (errorData) {
                    return (
                        typeof errorData === 'string'
                            ? { message: errorData }
                            : errorData
                    );
                }
                console.warn('Unknown validation error', error);
                return {
                    message: errorMessages.fallback(),
                    type: 'UNKNOWN',
                };
            });
        },

        validationErrors() {
            return this.validationErrorsExtended.map(({ message }) => message);
        },
    },

    methods: {
        /**
         * @param {ajv.ErrorObject} error
         * @returns {(String|Object)} строковое сообщение об ошибке
         * или объект, содержащий строковое сообщение об ошибке (в поле `message`)
         * и код типа ошибки (в поле `type`)
         */
        getValidationErrorMessage(/* error */) {
            throw new Error('Method should be implemented by the component');
        },
    },
};
