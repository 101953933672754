<template>
    <validation-observer
        ref="observer"
        slim
    >
        <form
            novalidate
            @submit.prevent="onSubmit()"
            @keydown.enter.prevent.self
        >
            <formular-section headline="Контактные данные">
                <!-- Фамилия Имя Отчество -->
                <!-- Фамилия -->
                <text-field
                    v-bind="states.surname"
                    :value="fields[states.surname.name]"
                    @input="setValue({ name: states.surname.name, value: $event })"
                />
                <!-- Имя -->
                <text-field
                    v-bind="states.firstName"
                    :value="fields[states.firstName.name]"
                    @input="setValue({ name: states.firstName.name, value: $event })"
                />
                <!-- Отчество -->
                <text-field
                    v-bind="states.thirdName"
                    :value="fields[states.thirdName.name]"
                    @input="setValue({ name: states.thirdName.name, value: $event })"
                />

                <!-- Ваш пол -->
                <radio-group
                    v-bind="states.gender"
                    :options="vocabs.genderOptions"
                    is-horizontal
                    :checked="fields[states.gender.name]"
                    :ui-required="states.gender.rules.includes('required')"
                    :rules="states.gender.rules"
                    @change="setValue({ name: states.gender.name, value: $event })"
                />
                <!-- Дата рождения -->
                <date-picker
                    v-bind="states.dateOfBirth"
                    :value="fields[states.dateOfBirth.name]"
                    :max-date="maxBirthdayDate"
                    @change="setValue({ name: states.dateOfBirth.name, value: $event })"
                />
                <!-- Город, в котором вас задержали -->
                <autocomplete
                    v-bind="states.city"
                    :get-options="searchCity"
                    :value="fields[states.city.name]"
                    @change="setValue({ name: states.city.name, value: $event })"
                />
                <!-- Телефон -->
                <text-field
                    v-bind="states.phoneNumber"
                    :value="fields[states.phoneNumber.name]"
                    @input="setValue({ name: states.phoneNumber.name, value: $event })"
                />
                <!-- Электронная почта -->
                <text-field
                    v-bind="states.mail"
                    :value="fields[states.mail.name]"
                    @input="setValue({ name: states.mail.name, value: $event })"
                />
                <!-- Адрес регистрации -->
                <text-field
                    v-bind="states.registrationAddress"
                    :is-textarea="true"
                    :value="fields[states.registrationAddress.name]"
                    @input="setValue({ name: states.registrationAddress.name, value: $event })"
                />
                <!-- Отметьте, если вы проверяете почту по адресу регистрации -->
                <base-checkbox
                    v-bind="states.registrationPostAddress"
                    :checked="fields[states.registrationPostAddress.name]"
                    @change="setValue({ name: states.registrationPostAddress.name, value: $event })"
                />
                <!-- Адрес, по которому вы сможете принять почту -->
                <transition name="slide-fade">
                    <text-field
                        v-if="!fields[states.registrationPostAddress.name]"
                        v-bind="states.postAddress"
                        :is-textarea="true"
                        :value="fields[states.postAddress.name]"
                        @input="setValue({ name: states.postAddress.name, value: $event })"
                    />
                </transition>
            </formular-section>
            <formular-section headline="Что вменили">
                <!-- Статья из протокола -->
                <autocomplete
                    v-bind="states.accusation"
                    :options="vocabs.accusationOptions"
                    :value="fields[states.accusation.name]"
                    @change="setValue({ name: states.accusation.name, value: $event })"
                />
                <!-- Место задержания -->
                <text-field
                    v-bind="states.offenseAddress"
                    :value="fields[states.offenseAddress.name]"
                    @input="setValue({ name: states.offenseAddress.name, value: $event })"
                />
                <!-- Отметьте, если вас задержали не на самой акции,
                а после - при помощи системы распознавания лиц -->
                <transition name="slide-fade">
                    <base-checkbox
                        v-if="isChargedWithStreetProtest"
                        v-bind="states.faceRecognition"
                        :checked="fields[states.faceRecognition.name]"
                        @change="setValue({ name: states.faceRecognition.name, value: $event })"
                    />
                </transition>
                <!-- Дата и время задержания -->
                <date-picker
                    v-bind="states.arrestTime"
                    :value="fields[states.arrestTime.name]"
                    mode="dateTime"
                    :min-date="minArrestDate"
                    @change="setValue({ name: states.arrestTime.name, value: $event })"
                />
                <!-- Тема акции -->
                <transition name="slide-fade">
                    <text-field
                        v-if="isChargedWithStreetProtest"
                        v-bind="states.actionTopic"
                        :value="fields[states.actionTopic.name]"
                        :rules="!fields[states.notParticipation.name] ? 'required' : ''"
                        @input="setValue({ name: states.actionTopic.name, value: $event })"
                    />
                </transition>
                <!-- Дата и время акции -->
                <transition name="slide-fade">
                    <date-picker
                        v-if="isChargedWithStreetProtest"
                        v-bind="states.dateOfAction"
                        :value="fields[states.dateOfAction.name]"
                        @change="setValue({ name: states.dateOfAction.name, value: $event })"
                    />
                </transition>
                <!-- Место проведения акции -->
                <transition name="slide-fade">
                    <text-field
                        v-if="isChargedWithStreetProtest"
                        v-bind="states.actionPlace"
                        :value="fields[states.actionPlace.name]"
                        @input="setValue({ name: states.actionPlace.name, value: $event })"
                    />
                </transition>
                <!-- Не участвовал в акции -->
                <transition name="slide-fade">
                    <base-checkbox
                        v-if="isChargedWithStreetProtest"
                        v-bind="states.notParticipation"
                        :checked="fields[states.notParticipation.name]"
                        @change="setValue({ name: states.notParticipation.name, value: $event })"
                    />
                </transition>
                <!-- Почему оказался в месте проведения акции -->
                <transition name="slide-fade">
                    <text-field
                        v-if="fields[states.notParticipation.name]"
                        v-bind="states.whatWasDoingIfNotParticipation"
                        :value="fields[states.whatWasDoingIfNotParticipation.name]"
                        @input="setValue({
                            name: states.whatWasDoingIfNotParticipation.name, value: $event
                        })"
                    />
                </transition>
                <!-- Подтверждение факта, что не участвовал в акции -->
                <transition name="slide-fade">
                    <text-field
                        v-if="fields[states.notParticipation.name]"
                        v-bind="states.proofOfNotParticipation"
                        :value="fields[states.proofOfNotParticipation.name][0]"
                        @input="setValue({
                            name: states.proofOfNotParticipation.name, value: [$event]
                        })"
                    />
                </transition>
                <!-- Отдел полиции -->
                <autocomplete
                    v-bind="states.policeStation"
                    include-none-listed-option
                    :get-options="searchPoliceStation"
                    :value="fields[states.policeStation.name]"
                    :rules="!fields[states.policeStationDiff.name] ? 'required' : ''"
                    @change="setValue({ name: states.policeStation.name, value: $event })"
                />
                <!-- Отдел полиции, если не нашли в базе -->
                <transition name="slide-fade">
                    <text-field
                        v-if="fields[states.policeStation.name] === ''"
                        v-bind="states.policeStationDiff"
                        :value="fields[states.policeStationDiff.name]"
                        @input="setValue({ name: states.policeStationDiff.name, value: $event })"
                    />
                </transition>
                <!-- Время доставления в отдел полиции (фактическое) -->
                <date-picker
                    v-bind="states.arrivalTime"
                    :value="fields[states.arrivalTime.name]"
                    mode="dateTime"
                    :min-date="minArrivalTime"
                    @change="setValue({ name: states.arrivalTime.name, value: $event })"
                />
                <!-- Недопуск защитника в отдел полиции -->
                <base-checkbox
                    v-bind="states.noDefender"
                    :checked="fields[states.noDefender.name]"
                    @change="setValue({ name: states.noDefender.name, value: $event })"
                />
                <!-- ФИО защитника -->
                <transition name="slide-fade">
                    <text-field
                        v-if="fields[states.noDefender.name]"
                        v-bind="states.lawyer"
                        :value="fields[states.lawyer.name]"
                        @input="setValue({ name: states.lawyer.name, value: $event })"
                    />
                </transition>
                <!-- Был ли недопуск защитника обоснован планом "Крепость"? -->
                <transition name="slide-fade">
                    <base-checkbox
                        v-if="fields[states.noDefender.name]"
                        v-bind="states.noDefenderFortress"
                        :checked="fields[states.noDefenderFortress.name]"
                        @change="setValue({ name: states.noDefenderFortress.name, value: $event })"
                    />
                </transition>
                <!-- Отпечатки пальцев -->
                <base-checkbox
                    v-bind="states.fingerprints"
                    :checked="fields[states.fingerprints.name]"
                    @change="setValue({ name: states.fingerprints.name, value: $event })"
                />
                <!-- Фотографировали в отделе полиции  -->
                <base-checkbox
                    v-bind="states.foto"
                    :checked="fields[states.foto.name]"
                    @change="setValue({ name: states.foto.name, value: $event })"
                />
                <!-- Брали образцы ДНК -->
                <base-checkbox
                    v-bind="states.dna"
                    :checked="fields[states.dna.name]"
                    @change="setValue({ name: states.dna.name, value: $event })"
                />
                <!-- Изъяли телефон -->
                <base-checkbox
                    v-bind="states.phoneWasTakenAway"
                    :checked="fields[states.phoneWasTakenAway.name]"
                    @change="setValue({ name: states.phoneWasTakenAway.name, value: $event })"
                />
                <!-- Описание вашего телефона (модель, цвет и т.д.) -->
                <transition name="slide-fade">
                    <text-field
                        v-if="fields[states.phoneWasTakenAway.name]"
                        v-bind="states.phoneDescription"
                        :value="fields[states.phoneDescription.name]"
                        @input="setValue({ name: states.phoneDescription.name, value: $event })"
                    />
                </transition>
                <!-- Дата и время выхода из отдела полиции -->
                <date-picker
                    v-bind="states.dateOfLeavingPoliceStation"
                    :value="fields[states.dateOfLeavingPoliceStation.name]"
                    mode="dateTime"
                    :min-date="minDateOfLeavingPoliceStation"
                    @change="
                        setValue({ name: states.dateOfLeavingPoliceStation.name, value: $event })
                    "
                />
            </formular-section>
            <formular-section headline="Суд первой инстанции">
                <!-- Доставили в суд из отдела -->
                <base-checkbox
                    v-bind="states.arrivedToCourt"
                    :checked="fields[states.arrivedToCourt.name]"
                    @change="setValue({ name: states.arrivedToCourt.name, value: $event })"
                />
                <!-- Суд первой инстанции -->
                <autocomplete
                    v-bind="states.firstInstancewCourt"
                    include-none-listed-option
                    :get-options="searchCourt"
                    :value="fields[states.firstInstancewCourt.name]"
                    :rules="!fields[states.firstInstancewCourtDiff.name] ? 'required' : ''"
                    @change="setValue({ name: states.firstInstancewCourt.name, value: $event })"
                />
                <!-- Суд первой инстанции, если не нашли в базе -->
                <transition name="slide-fade">
                    <text-field
                        v-if="fields[states.firstInstancewCourt.name] === ''"
                        v-bind="states.firstInstancewCourtDiff"
                        :value="fields[states.firstInstancewCourtDiff.name]"
                        @input="
                            setValue({ name: states.firstInstancewCourtDiff.name, value: $event })
                        "
                    />
                </transition>
                <!-- Номер дела -->
                <text-field
                    v-bind="states.caseNumber"
                    :value="fields[states.caseNumber.name]"
                    @input="setValue({ name: states.caseNumber.name, value: $event })"
                />
                <!-- Получили ли вы уведомление о дате и времени
                рассмотрения вашего дела в суде? -->
                <base-checkbox
                    v-bind="states.notification"
                    :checked="fields[states.notification.name]"
                    @change="setValue({ name: states.notification.name, value: $event })"
                />
                <!-- Было ли уведомление получено за три и менее дней до суда? -->
                <transition name="slide-fade">
                    <base-checkbox
                        v-if="fields[states.notification.name]"
                        v-bind="states.missedDeadline"
                        @change="setValue({ name: states.missedDeadline.name, value: $event })"
                    />
                </transition>
                <!-- Явились ли вы в суд на рассмотрение дела? -->
                <transition name="slide-fade">
                    <base-checkbox
                        v-bind="states.appearance"
                        :checked="fields[states.appearance.name]"
                        @change="setValue({ name: states.appearance.name, value: $event })"
                    />
                </transition>
                <!-- Подавали ли вы ходатайство об отложении дела? -->
                <transition name="slide-fade">
                    <base-checkbox
                        v-if="(
                            fields[states.appearance.name]
                            && (
                                !fields[states.notification.name]
                                || fields[states.missedDeadline.name]
                            )
                        )"
                        v-bind="states.postponement"
                        :checked="fields[states.postponement.name]"
                        @change="setValue({ name: states.postponement.name, value: $event })"
                    />
                </transition>
                <!-- Дата и время вынесения постановления суда  -->
                <date-picker
                    v-bind="states.courtOrderDate"
                    :value="fields[states.courtOrderDate.name]"
                    mode="dateTime"
                    :min-date="minCourtOrderDate"
                    @change="setValue({ name: states.courtOrderDate.name, value: $event })"
                />
                <!-- Наказание -->
                <radio-group
                    v-bind="states.punishment"
                    :options="vocabs.punishmentOptions"
                    is-horizontal
                    :checked="fields[states.punishment.name]"
                    @change="setValue({ name: states.punishment.name, value: $event })"
                />
                <!-- С какого времени исчислялся срок административного ареста в вашем деле? -->
                <transition name="slide-fade">
                    <date-picker
                        v-if="fields[states.punishment.name] === 'Арест'"
                        v-bind="states.administrativeArrestStart"
                        :value="fields[states.administrativeArrestStart.name]"
                        mode="dateTime"
                        @change="
                            setValue({ name: states.administrativeArrestStart.name, value: $event })
                        "
                    />
                </transition>
                <!-- Число присужденных суток ареста -->
                <transition name="slide-fade">
                    <text-field
                        v-if="fields[states.punishment.name] === 'Арест'"
                        v-bind="states.arrestDays"
                        type="number"
                        :value="fields[states.arrestDays.name]"
                        @input="setValue({ name: states.arrestDays.name, value: $event })"
                    />
                </transition>
                <!-- Размер штрафа -->
                <transition name="slide-fade">
                    <text-field
                        v-if="fields[states.punishment.name] === 'Штраф'"
                        v-bind="states.penalty"
                        type="number"
                        :value="fields[states.penalty.name]"
                        @input="setValue({ name: states.penalty.name, value: $event })"
                    />
                </transition>
                <!-- Основания -->
                <transition name="slide-fade">
                    <autocomplete
                        v-if="fields[states.punishment.name] === 'Штраф'"
                        v-bind="states.penaltyReason"
                        :options="vocabs.penaltyReasonOptions"
                        multiple
                        :value="fields[states.penaltyReason.name]"
                        @change="setValue({ name: states.penaltyReason.name, value: $event })"
                    />
                </transition>
                <!-- Количество иждивенцев -->
                <transition name="slide-fade">
                    <text-field
                        v-if="(
                            fields[states.penaltyReason.name]
                            && fields[states.penaltyReason.name].includes(
                                'имею на иждивении несовершеннолетних детей'
                            )
                        )"
                        v-bind="states.childrenQuantity"
                        type="number"
                        :value="fields[states.childrenQuantity.name]"
                        @input="setValue({ name: states.childrenQuantity.name, value: $event })"
                    />
                </transition>
                <!-- Группа инвалидности -->
                <transition name="slide-fade">
                    <base-select
                        v-if="(
                            fields[states.penaltyReason.name]
                            && fields[states.penaltyReason.name].includes(
                                'являюсь инвалидом группы'
                            )
                        )"
                        v-bind="states.disabilityGroupNumber"
                        :options="vocabs.disabilityGroupOptions"
                        :value="fields[states.disabilityGroupNumber.name]"
                        @change="
                            setValue({ name: states.disabilityGroupNumber.name, value: $event })
                        "
                    />
                </transition>
                <!-- Сколько часов работ вам назначили? -->
                <transition name="slide-fade">
                    <text-field
                        v-if="fields[states.punishment.name] === 'Обязательные работы'"
                        v-bind="states.workHours"
                        type="number"
                        :value="fields[states.workHours.name]"
                        @input="setValue({ name: states.workHours.name, value: $event })"
                    />
                </transition>
                <!-- Суд неверно рассчитал период задержания, который надо зачесть в срок ареста -->
                <transition name="slide-fade">
                    <base-checkbox
                        v-if="fields[states.punishment.name] === 'Арест'"
                        v-bind="states.wrongArrestPeriod"
                        :checked="fields[states.wrongArrestPeriod.name]"
                        @change="setValue({ name: states.wrongArrestPeriod.name, value: $event })"
                    />
                </transition>
                <!-- Отказ в приобщении фото/видео в суде первой инстанции  -->
                <base-checkbox
                    v-bind="states.docsForCourtTypes"
                    :checked="fields[states.docsForCourtTypes.name]"
                    @change="setValue({ name: states.docsForCourtTypes.name, value: $event })"
                />
                <!-- Что запечатлено -->
                <transition name="slide-fade">
                    <text-field
                        v-if="fields[states.docsForCourtTypes.name]"
                        v-bind="states.docsForCourtDescription"
                        :is-textarea="true"
                        :value="fields[states.docsForCourtDescription.name]"
                        @input="
                            setValue({ name: states.docsForCourtDescription.name, value: $event })
                        "
                    />
                </transition>
                <!-- Отметьте, если заседание в суде первой инстанции
                проходило посредством видеоконференцсвязи -->
                <base-checkbox
                    v-bind="states.videoConference"
                    :checked="fields[states.videoConference.name]"
                    @change="setValue({ name: states.videoConference.name, value: $event })"
                />
                <!-- Давали ли вы письменное согласие на суд по видеоконференцсвязи? -->
                <transition name="slide-fade">
                    <base-checkbox
                        v-if="fields[states.videoConference.name]"
                        v-bind="states.videoConferenceAgreement"
                        :checked="fields[states.videoConferenceAgreement.name]"
                        @change="
                            setValue({ name: states.videoConferenceAgreement.name, value: $event })
                        "
                    />
                </transition>
                <!-- Тип видеоконференцсвязи -->
                <transition name="slide-fade">
                    <base-select
                        v-if="fields[states.videoConference.name]"
                        v-bind="states.videoConferenceType"
                        :options="vocabs.videoConferenceTypeOptions"
                        :value="fields[states.videoConferenceType.name]"
                        @change="setValue({ name: states.videoConferenceType.name, value: $event })"
                    />
                </transition>
                <!-- Ходатайствовали ли вы о вызове свидетелей защиты в суде первой инстанции? -->
                <base-checkbox
                    v-bind="states.witnessPetition"
                    :checked="fields[states.witnessPetition.name]"
                    @change="setValue({ name: states.witnessPetition.name, value: $event })"
                />
                <!-- Отметьте, если вам известны данные только одного полицейского из тех,
                кто производил задержание и составлял рапорт -->
                <base-checkbox
                    v-bind="states.policemanOneData"
                    :checked="fields[states.policemanOneData.name]"
                    @change="setValue({ name: states.policemanOneData.name, value: $event })"
                />
                <!-- Укажите ФИО сотрудников полиции, составлявших рапорты -->
                <text-field
                    v-bind="states.policemanData"
                    :is-textarea="true"
                    :value="fields[states.policemanData.name]"
                    @input="setValue({ name: states.policemanData.name, value: $event })"
                />
            </formular-section>
            <formular-section headline="Апелляционный суд">
                <!-- Суд апелляционной инстанции -->
                <autocomplete
                    v-bind="states.appelation"
                    include-none-listed-option
                    :get-options="searchCourt"
                    :value="fields[states.appelation.name]"
                    :rules="!fields[states.appelationDiff.name] ? 'required' : ''"
                    @change="setValue({ name: states.appelation.name, value: $event })"
                />
                <!-- Суд апелляционной инстанции, если не нашли в базе -->
                <transition name="slide-fade">
                    <text-field
                        v-if="fields[states.appelation.name] === ''"
                        v-bind="states.appelationDiff"
                        :value="fields[states.appelationDiff.name]"
                        @input="setValue({ name: states.appelationDiff.name, value: $event })"
                    />
                </transition>
                <!-- Отказ от слов про неучастие в акции -->
                <transition name="slide-fade">
                    <base-checkbox
                        v-if="fields[states.notParticipation.name]"
                        v-bind="states.retraction"
                        :checked="fields[states.retraction.name]"
                        @change="setValue({ name: states.retraction.name, value: $event })"
                    />
                </transition>
            </formular-section>
            <formular-section
                headline="Другое"
                borderless
            >
                <!-- Контакт родственника или друга -->
                <text-field
                    v-bind="states.frindsContact"
                    :is-textarea="true"
                    :value="fields[states.frindsContact.name]"
                    @input="setValue({ name: states.frindsContact.name, value: $event })"
                />
                <!-- Комментарий -->
                <text-field
                    v-bind="states.freeComment"
                    :is-textarea="true"
                    :value="fields[states.freeComment.name]"
                    @input="setValue({ name: states.freeComment.name, value: $event })"
                />
            </formular-section>

            <formular-section
                borderless
                clean
            >
                <error-block
                    v-if="hasValidationErrors()"
                    :errors="mappedErrors()"
                ></error-block>

                <button-tip-wrapper>
                    <template #hint>
                        После отправки формы на вашу почту придёт
                        автоматически сгенерированная жалоба.
                        Если вам потребуется помощь с редактированием,
                        напишите нашим юристам на&nbsp;<email-us />
                    </template>

                    <template #button>
                        <base-button
                            type="submit"
                            view="primary"
                            :pending="formIsSending"
                            :data-gtm="JSON.stringify({buttonAction: 'formSubmit'})"
                        >
                            Отправить
                        </base-button>
                    </template>
                </button-tip-wrapper>
            </formular-section>
        </form>
    </validation-observer>
</template>

<script src="https://mc.yandex.ru/metrika/watch.js"></script>

<script lang="ts">
import Vue, { PropType } from 'vue';
import { createNamespacedHelpers } from 'vuex';
import { DateTime, Interval } from 'luxon';
import BaseButton from '@/components/button/BaseButton.vue';
import ButtonTipWrapper from '@/components/button/ButtonTipWrapper.vue';
import BaseCheckbox from '@/components/checkbox/BaseCheckbox.vue';
import BaseSelect from '@/components/select/VeeSelect.vue';
import TextField from '@/components/text-field/VeeTextField.vue';
import DatePicker from '@/components/datepicker/VeeDatePicker.vue';
import Autocomplete from '@/components/autocomplete/VeeAutocomplete.vue';
import ErrorBlock from '@/components/ErrorBlock.vue';
import FormularSection from '@/components/FormularSection.vue';
import CheckboxGroup from '@/components/checkbox-group/CheckboxGroup.vue';
import RadioGroup from '@/components/radio-group/VeeRadioGroup.vue';
import EmailUs from '@/components/mail-to/EmailOILegal.vue';

const { mapState, mapActions, mapMutations, mapGetters } = createNamespacedHelpers('appeal');
const { mapGetters: mapAuthGetters } = createNamespacedHelpers('auth');

export default Vue.extend({
    name: 'FormAppeal',
    components: {
        BaseButton,
        ButtonTipWrapper,
        BaseCheckbox,
        BaseSelect,
        TextField,
        DatePicker,
        Autocomplete,
        ErrorBlock,
        FormularSection,
        CheckboxGroup,
        RadioGroup,
        EmailUs,
    },

    computed: {
        ...mapState([
            'fields',
            'formIsSending',
            'vocabs',
            'states',
        ]),
        ...mapAuthGetters(['user']),

        maxBirthdayDate() {
            return DateTime.now().minus({ years: 1 }).toJSDate();
        },
        minArrestDate() {
            return this.getMinDate('dateOfAction');
        },
        minArrivalTime() {
            return this.getMinDate('arrestTime');
        },
        minDateOfLeavingPoliceStation() {
            return this.getMinDate('arrivalTime');
        },
        minCourtOrderDate() {
            return this.getMinDate('dateOfLeavingPoliceStation');
        },

        isChargedWithStreetProtest() {
            const charges = this.fields[this.states.accusation.name];
            return (
                charges.length > 0
                && !charges.includes('ст. 20.3.3 ч.1 КоАП РФ (пост в соцсетях)')
            );
        },
    },

    created() {
        this.invalidAttemptTimestamp = null;
        this.submissionAttempts = 0;

        /* если пользователь залогинен, берем почту из аккаунта */
        if (this.user) {
            this.setFormValues({ name: 'заявитель_электронная_почта', value: this.user.email });
        }
    },

    methods: {
        ...mapMutations(['setFormValues', 'cleanValues']),
        ...mapActions(['sendData', 'fetchOptions']),
        ...mapGetters(['validationErrors', 'hasValidationErrors']),

        getMinDate(relativeFieldId: string): Date | null {
            const relativeDate = this.fields[this.states[relativeFieldId].name];
            return relativeDate
                ? DateTime.fromMillis(relativeDate).toJSDate()
                : null;
        },

        getTimeSpentFixing() {
            if (!this.invalidAttemptTimestamp) {
                return 0;
            }
            return Math.round(
                Interval
                    .fromDateTimes(this.invalidAttemptTimestamp, new Date())
                    .length('seconds')
            );
        },

        async onSubmit() {
            const { observer } = this.$refs;
            const { isValid, errors } = await observer.validateWithInfo();
            if (!isValid) {
                this.invalidAttemptTimestamp = new Date();
                this.submissionAttempts += 1;

                this.scrollToInvalidField(errors);

                this.trackInvalidAttempt({ errorCount: Object.entries(errors).length });

                return;
            }

            this.sendData();

            this.trackSuccess({
                timeSpentFixing: this.getTimeSpentFixing(),
                submissionAttempts: this.submissionAttempts,
            });

            // reset counters
            this.submissionAttempts = 0;
        },

        setValue(value) {
            // eslint-disable-next-line no-prototype-builtins
            if (
                value.value === null
                || (
                    typeof value.value === 'object'
                    && 'length' in value.value
                    && value.value.length === 0
                )
            ) {
                this.cleanValue(value);
            } else {
                this.setFormValues(value);
            }
        },

        cleanValue({ name }) {
            this.cleanValues(name);
        },

        mappedErrors() {
            if (this.hasValidationErrors()) {
                return this.validationErrors().map((error) => {
                    return this.mapErrorName(this.states, error);
                });
            }
            return 0;
        },

        mapErrorName(states, name) {
            let error;
            Object.values(states).forEach((v) => {
                if (v.name === name) {
                    error = v.label;
                }
            });
            return error;
        },

        searchCity(query: string) {
            return this.fetchOptions({ query, vocab: 'city' });
        },
        searchPoliceStation(query: string) {
            return this.fetchOptions({ query, vocab: 'police_station' });
        },
        searchCourt(query: string) {
            return this.fetchOptions({ query, vocab: 'court' });
        },

        scrollToInvalidField(errors) {
            const { observer } = this.$refs;
            const erroredFields = Object.entries(errors).filter(
                (x: any) => x[1].length
            );
            const firstInvalidFieldInput =
                observer.refs[erroredFields[0][0]].$el;

            const firstInvalidField = firstInvalidFieldInput.className.includes(
                'date-time'
            )
                // TODO: так не должно быть 😢
                ? firstInvalidFieldInput.parentElement.parentElement
                      .parentElement.parentElement
                : firstInvalidFieldInput.parentElement.parentElement;
            const HEADER_HEIGHT = 64;

            window.scrollTo({
                top: firstInvalidField.offsetTop - HEADER_HEIGHT,
                behavior: 'smooth',
            });
        },

        trackSuccess({ timeSpentFixing, submissionAttempts }) {
            // 1. validation success
            this.$metrika.reachGoal('generaptor-validation', 'success');

            // 2. time-spent
            this.$metrika.reachGoal('generaptor-time-spent', timeSpentFixing || 0);

            // 3. submission-attempt-number
            this.$metrika.reachGoal(
                'generaptor-submission-attempt-number', submissionAttempts || 0
            );
        },
        trackInvalidAttempt({ errorCount }) {
            // ???
            this.$metrika.reachGoal();

            // 1. error length Object.entries(errors).length
            this.$metrika.reachGoal('generaptor-errors-number', errorCount || 0);

            // 2. validation fail
            this.$metrika.reachGoal('generaptor-validation', 'fail');

            // 3. form name
            this.$metrika.reachGoal('generaptor-form-name', 'appeal');

            // 4. error fields - is not yet impelemented
        },
    },
});
</script>

<style lang="scss">
@use "@/styles/variables/grid";

.formular__fields {
    .form-field {
        &:not(:last-child) {
            margin-bottom: grid.$space-3;
        }
    }
}

.slide-fade-enter-active,
.slide-fade-leave-active {
    transition: all 0.4s ease;
}

.slide-fade-enter,
.slide-fade-leave-to {
    transform: translateY(10px);
    opacity: 0;
}
</style>
