/**
 * Миксин для программной установки фокуса на элемент.
 * Кроме использования миксина, на нужный элемент также должен быть навешен ref
 * под названием `focusable`
 */
export default {
    props: {
        hasFocus: {
            type: Boolean,
            default: false,
        },
    },
    watch: {
        hasFocus: {
            handler(hasFocus) {
                if (!hasFocus) {
                    return;
                }

                /* см. https://developer.mozilla.org/en-US/docs/Learn/Tools_and_testing/Client-side_JavaScript_frameworks/Vue_refs_focus_management#vues_nexttick_method */
                this.$nextTick(() => {
                    const elRef = this.$refs.focusable;
                    if (elRef) {
                        elRef.focus();
                    }
                });
            },
            immediate: true,
        },
    },
};
