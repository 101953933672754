<template>
    <ValidationProvider
        v-slot="{ errors }"
        :rules="rules"
        slim
    >
        <text-field
            v-bind="$attrs"
            v-model="proxyModel"
            :ui-required="required || requiredProp"
            :errors="errors"
            :is-valid="errors.length === 0"
        />
    </ValidationProvider>
</template>

<script lang="ts">
import Vue from 'vue';
import makeWithVModelMixin from '@shared/mixins/with-v-model';
import TextField from './BaseTextField.vue';

/** @deprecated */
export default Vue.extend({
    components: {
        TextField,
    },

    mixins: [
        makeWithVModelMixin(),
    ],

    props: {
        value: {
            type: String,
            // required: true,
        },
        required: {
            type: Boolean,
            default: false,
        },
        rules: {
            type: String,
            default: '',
        },
    },

    computed: {
        requiredProp(): Boolean {
            return this.rules?.includes('required');
        },
    },
});
</script>
