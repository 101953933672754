<template>
    <ValidationProvider
        v-slot="{ errors }"
        :rules="rules"
        slim
    >
        <radio-group
            v-bind="$attrs"
            v-model="proxyModel"
            :ui-required="required || requiredProp"
            :errors="errors"
            :is-valid="errors.length === 0"
        />
    </ValidationProvider>
</template>

<script lang="ts">
import makeWithVModelMixin from '@shared/mixins/with-v-model';
import RadioGroup from './RadioGroup.vue';

/** @deprecated */
export default {
    components: {
        RadioGroup,
    },

    mixins: [
        makeWithVModelMixin({
            prop: 'checked',
            event: 'change',
        }),
    ],

    props: {
        checked: {},
        required: {
            type: Boolean,
            default: false,
        },
        rules: {
            type: String,
            default: '',
        },
    },

    computed: {
        requiredProp(): Boolean {
            return this.rules?.includes('required');
        },
    },
};
</script>
