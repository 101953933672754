import { SHARED_VARS } from './constants';


/**
 * index.html
 * https://developers.google.com/tag-platform/tag-manager/datalayer
 * Создает модуль Vuex store
 * @returns {Object} объект, пригодный для использования в качестве модуля Vuex store
 */
export const makeStore = () => {
    return {
        state: {
        },

        getters: {
        },

        actions: {
            track(_, payload) {
                /* т.к. переменные устанавливаются глобально, а не только для конкретного события,
                нужно сбросить переменные, которые могли остаться от прошлых аналогичных событий */
                const trackingData = {
                    ...SHARED_VARS.reduce((vars, v) => ({ ...vars, [v]: null }), {}),
                    ...payload,
                };
                window.dataLayer.push(trackingData);
            },
        },
    };
};
