<template>
    <div
        class="auth-widget"
        :class="`${isFillingForm ? 'auth-widget--login-pending': ''}`"
    >
        <base-button
            v-if="!isPending && !isLoggedIn"
            type="button"
            view="secondary"
            class="auth-widget__signin-btn"
            @click="openModal"
        >
            Войти
        </base-button>

        <user-menu v-else-if="isLoggedIn" />

        <sign-in
            :is-open="isModalOpen"
            @close="closeModal"
        />
    </div>
</template>

<script>
import Vue from 'vue';
import { createNamespacedHelpers } from 'vuex';
import UserMenu from '@/components/user-menu/UserMenu.vue';
import SignIn from './SignInModal.vue';

const { mapGetters, mapState } = createNamespacedHelpers('auth');

export default Vue.extend({
    components: {
        SignIn,
        UserMenu,
    },

    data() {
        return {
            isModalOpen: false,
        };
    },

    computed: {
        ...mapState(['isFillingForm']),
        ...mapGetters(['isPending', 'isLoggedIn']),
    },

    methods: {
        openModal() {
            this.isModalOpen = true;
        },
        closeModal() {
            this.isModalOpen = false;
        },
    },
});
</script>

<style lang="scss" scoped>
@use "@/styles/mixins/breakpoint";
@use "@/styles/variables/colors";

.auth-widget__signin-btn {
    @include breakpoint.mobile {
        .auth-widget--login-pending & {
            display: none;
        }
    }

    &.auth-widget__signin-btn {
        --text-color: #{colors.$white};
        --text-color-active: #{colors.$text};

        @include breakpoint.mobile {
            --border-color: transparent;
        }

        @include breakpoint.desktop {
            --border-color: #{colors.$white};
        }
    }
}
</style>
