<template>
    <fragment>
        <slot />
    </fragment>
</template>

<script>
import Vue from 'vue';
import Ajv from 'ajv';

/**
 * Определяет путь по объекту с данными, к которому относится определенная ошибка
 * @param {ajv.ErrorObject} error
 * @returns {String}
 */
const getErrorPath = (error) => {
    /* `instancePath` в этом случае пустой */
    if (error.keyword === 'required') {
        return `/${error.params.missingProperty}`;
    }
    return error.instancePath;
};

/**
 * Находит все пути по объекту с данными, которые не прошли валидацию
 * @param {ajv.ErrorObject[]} errors
 * @returns {String[]} - множество уникальных путей
 */
const getErrorPaths = (errors) => {
    return new Set(errors.map((error) => getErrorPath(error)));
};

/**
 * Находит подмножество ошибок, которые относятся к определенному пути по объекту с данными
 * @param {ajv.ErrorObject[]} errors
 * @param {String} path
 * @returns {ajv.ErrorObject[]}
 */
const getErrorsForPath = (errors, path) => {
    /*
    сам путь и все вложенные, если он является массивом
    (т.е. ошибки группируются: предполагается, что
    массив — это значение одного поля формы с множественным выбором,
    а не набор отдельных значений, относящихся каждый к своему полю)
    */
    const pathTester = RegExp(`^/${path}($|/\\d+)`);
    return errors.filter((error) => pathTester.test(getErrorPath(error)));
};

/**
 * Определяет, есть ли среди ошибок те, что относятся к определенному пути по объекту с данными
 * @param {ajv.ErrorObject[]} errors
 * @param {String} path
 * @returns {Boolean}
 */
const isPathValid = (errors, path) => {
    return getErrorsForPath(errors, path).length === 0;
};


/**
 * Компонент, валидирующий форму по запросу.
 * Для этого родительский компонент оборачивает саму форму в `ValidationProvider`,
 * а каждый её редактируемый элемент в `Validator`
 * и в нужный момент(ы) инициирует валидацию вызывом метода `validate` на провайдере
 * @todo? Работает только с плоскими данными/схемами
 */
export default Vue.extend({
    provide() {
        return {
            /* см. компонент `Validator`, который это всё использует */
            validation: {
                isKeyValid: (key) => isPathValid(this.errors, key),
                getErrorsForKey: (key) => getErrorsForPath(this.errors, key),
            },
        };
    },

    props: {
        /*
        объект схемы;
        предполагается, что схема не будет изменяться (если будет, это будет проигнорировано)
        */
        schema: {
            type: Object,
            required: true,
        },
    },

    data() {
        return {
            errors: [],
        };
    },

    created() {
        const ajv = new Ajv({
            allErrors: true,
            /*
            т.к. в наших схемах есть нестандартное поле `generaptor` (описывающее структуру формы)
            */
            strictSchema: false,
            messages: false,
        });
        this._validateSchema = ajv.compile(this.schema);
    },

    destroyed() {
        delete this._validateSchema;
    },

    methods: {
        /**
         * Валидирует форму; вызывается родительским компонентом напрямую
         * @param {Object} data - данные для валидации
         * @returns {[Boolean, (Object|null)]} - пара, где
         * первый элемент представляет собой статус валидации,
         * а второй содержит (когда актуально) список ошибок и
         * вспомогательные функции для работы с ними
         */
        validate(data) {
            const isValid = this._validateSchema(data);
            const errors = this._validateSchema.errors || [];
            this.errors = errors;

            if (isValid) {
                return [true, null];
            }
            return [
                false,
                {
                    errors,
                    getErrorKeys: () => getErrorPaths(errors),
                    getErrorsForKey: (key) => getErrorsForPath(errors, key),
                    isKeyValid: (key) => isPathValid(errors, key),
                },
            ];
        },

        /** Сбрасывает состояние валидации; вызывается родительским компонентом напрямую */
        reset() {
            this.errors = [];
        },
    },
});
</script>
