import { createNamespacedHelpers as createVuexNamespacedHelpers } from 'vuex';

const storeNS = 'courtFiling';

/* eslint-disable */
/**
 * (Скопипащено из Vuex, т.к. они не экспортируют эту функцию:
 * см. https://github.com/vuejs/vuex/blob/a295412e2b54731b1047e1b3411d9df87aff5b1f/src/helpers.js)
 *
 * Validate whether given map is valid or not
 * @param {*} map
 * @return {Boolean}
 */
function isValidMap (map) {
  return map && (Array.isArray(map) || typeof map === 'object')
}
/* eslint-enable */

/* eslint-disable */
/**
 * (Скопипащено из Vuex, т.к. они не экспортируют эту функцию:
 * см. https://github.com/vuejs/vuex/blob/a295412e2b54731b1047e1b3411d9df87aff5b1f/src/helpers.js)
 *
 * Normalize the map
 * normalizeMap([1, 2, 3]) => [ { key: 1, val: 1 }, { key: 2, val: 2 }, { key: 3, val: 3 } ]
 * normalizeMap({a: 1, b: 2, c: 3}) => [ { key: 'a', val: 1 }, { key: 'b', val: 2 }, { key: 'c', val: 3 } ]
 * @param {Array|Object} map
 * @return {Object}
 */
function normalizeMap (map) {
  if (!isValidMap(map)) {
    return []
  }
  return Array.isArray(map)
    ? map.map(key => ({ key, val: key }))
    : Object.keys(map).map(key => ({ key, val: map[key] }))
}
/* eslint-enable */

/**
 * Оборачивает методы объекта
 * @param {Object} mapped - объект, значениями ключей которого являются функции
 * (например, результат вызова Vuex `mapGetters`)
 * @param {Function} wrapHandler - функция, принимающая функцию и создающая обертку для неё
 */
function wrapMapped(mapped, wrapHandler) {
    return Object.keys(mapped).reduce(
        (wrapped, name) => {
            const handler = mapped[name];
            /* eslint-disable-next-line no-param-reassign */
            wrapped[name] = wrapHandler(handler);
            return wrapped;
        },
        {},
    );
}

/**
 * Аналог стандартного Vuex-ового `createNamespacedHelpers`,
 * но лучше приспособленный к структуре этого стора и тому, как его используют компоненты:
 * стейт стора состоит из однотипных вложенных объектов,
 * каждый из которых соответствует форме определенного вида;
 * компонент, использующий стор, работает с формой только какого-либо одного вида
 * @param {String} filingType - тип формы (один из ключей в стейте стора)
 * @returns {Object} функции `mapState`, `mapGetters`, `mapMutations` и `mapActions` в объекте,
 * имеющие такой же смысл и интерфейс, как и соответсвующие функции Vuex,
 * с той разницей, что они привязаны к указанному виду формы
 * @example
 * const {
 *     mapState, mapGetters, mapMutations, mapActions,
 * } = createHelpers(COURT_FILING_TYPES.MOTIONS);
 *
 * export default Vue.extend({
 *     computed: {
 *         ...mapState(['formValues']),
 *         ...mapGetters(['isSchemaReady']),
 *     },
 *     methods: {
 *         ...mapMutations({ setFormValue: 'formValue' }),
 *         ...mapActions(['submitForm']),
 *         setFoo(value) {
 *             this.setFormValue({ field: 'foo', value });
 *         },
 *     },
 *     watch: {
 *         'formValues.foo': function() {
 *             // ...
 *         },
 *     },
 *     // ...
 * });
 */
export function createHelpers(filingType) {
    const {
        mapState: mapVuexState,
        mapGetters: mapVuexGetters,
        mapMutations: mapVuexMutations,
        mapActions: mapVuexActions,
    } = createVuexNamespacedHelpers(storeNS);

    const mapState = (map) => {
        const mappedState = mapVuexState([filingType]);
        return normalizeMap(map).reduce(
            (getters, { key: alias, val: key }) => {
                /* eslint-disable-next-line no-param-reassign */
                getters[alias] = function getState() {
                    return mappedState[filingType].call(this)[key];
                };
                return getters;
            },
            {},
        );
    };

    const mapGetters = (map) => {
        const mappedGetters = mapVuexGetters(map);
        return wrapMapped(mappedGetters, (getter) => {
            return function callGetter() {
                return getter.call(this)(filingType);
            };
        });
    };

    const mapMutations = (map) => {
        const mappedMutations = mapVuexMutations(map);
        return wrapMapped(mappedMutations, (mutation) => {
            return function callMutation(payload) {
                return mutation.call(this, { filingType, ...payload });
            };
        });
    };

    const mapActions = (map) => {
        const mappedActions = mapVuexActions(map);
        return wrapMapped(mappedActions, (action) => {
            return function callAction(payload) {
                return action.call(this, { filingType, ...payload });
            };
        });
    };

    return {
        mapState, mapGetters, mapMutations, mapActions,
    };
}

/**
 * Фабрика миксинов.
 * Миксин позволяет использовать `mapState`, `mapGetters`, `mapMutations`, `mapActions` (см. выше)
 * в случаях, когда нужный тип формы неизвестен на момент создания конструктора компонента.
 * Создает на компоненте computed свойство `mappedStore`, на котором определены
 * computed свойства и методы, созданные соответствующими мапперами;
 * тип формы, к которому будут привязаны мапперы, берется из свойства `formType`
 * инстанса компонента (его значения на момент первого обращения к `mappedStore`)
 * @param {Function} applyMappers - функция, принимающая мапперы и создающая с их помощью
 * объекты `computed` и `methods` такого же вида, как эти же свойства в конструкторах компонентов
 * @returns {Object} объект миксина
 * @example
 * export default Vue.extend({
 *     mixins: [
 *         makeMappedStoreMixin(({ mapState, mapGetters, mapMutations, mapActions }) => {
 *             return {
 *                 computed: {
 *                     ...mapState(['formValues']),
 *                     ...mapGetters(['isSchemaReady']),
 *                 },
 *                 methods: {
 *                     ...mapMutations({ setFormValue: formValue }),
 *                     ...mapActions(['submitForm']),
 *                 },
 *             };
 *         }),
 *     ],
 *     props: ['formType'],
 *     methods: {
 *         setFoo(value) {
 *             this.mappedStore.setFormValue({ field: 'foo', value });
 *         },
 *     },
 *     watch: {
 *         'mappedStore.formValues.foo': function() {
 *             // ...
 *         },
 *     },
 *     // ...
 * });
 */
export function makeMappedStoreMixin(applyMappers) {
    function mapStore(component) {
        const {
            mapState, mapGetters, mapMutations, mapActions,
        } = createHelpers(component.formType);
        const { computed, methods } = applyMappers.call(
            component,
            {
                mapState, mapGetters, mapMutations, mapActions,
            },
        );

        /*
        свойства определяются на отдельном объекте `mappedStore`, а не на самом компоненте,
        иначе Vue не сделает их реактивными
        */
        const mappedStore = {};

        Object.keys(computed).forEach((prop) => {
            Object.defineProperty(
                mappedStore,
                prop,
                {
                    get() {
                        return computed[prop].apply(component, arguments);
                    },
                    enumerable: true,
                },
            );
        });

        Object.keys(methods).forEach((method) => {
            mappedStore[method] = function callMethod() {
                return methods[method].apply(component, arguments);
            };
        });

        return mappedStore;
    }

    return {
        computed: {
            mappedStore() {
                if (!this._mappedStore) {
                    this._mappedStore = mapStore(this);
                }

                return this._mappedStore;
            },
        },
    };
}
